import { Box, Button, HStack, Text } from '@chakra-ui/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useAppContext } from '../contexts/app-context';
import { getNotifications } from '../data/client/endpoints/user.endpoints';
import { useTranslate } from '../hooks/langs-hooks';
import { IMessage } from '../types';
import ServerError from './Error';
import Loader from './loader/Loader';

interface NotificationsProps {}

const Notifications: React.FC<NotificationsProps> = ({}) => {
  const { user } = useAppContext();
  const t = useTranslate();

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isLoading,
    error,
    isError,
    isFetching,
  } = useInfiniteQuery({
    queryKey: ['notifications'],
    queryFn: ({ pageParam = 1 }) =>
      getNotifications(user?.userId ?? 0, pageParam),
    getNextPageParam: (lastPage, allPages) => {
      if (allPages.length < lastPage.numberOfPages) {
        return allPages.length + 1;
      }
      return undefined;
    },
    getPreviousPageParam: (firstPage, allPages) => {
      if (allPages.length > 1) {
        return allPages.length - 1;
      }
      return undefined;
    },
  });

  if (isLoading) return <Loader size='md' />;
  if (isError) return <ServerError error={error} />;

  return (
    <>
      {data?.pages.map((page, index) => (
        <Fragment key={index}>
          {page.notifications.map((msg, msgIndex) => (
            <NotificationCard key={index + msgIndex} {...msg} />
          ))}
        </Fragment>
      ))}
      {hasNextPage && (
        <Button
          size='xs'
          display={'block'}
          mx='auto'
          colorScheme='telegram'
          isLoading={isFetching}
          onClick={() => fetchNextPage()}
        >
          {t('text-load-more')}
        </Button>
      )}
    </>
  );
};

interface NotificationCardProps extends IMessage {}

const NotificationCard: React.FC<NotificationCardProps> = ({
  messageBody,
  messageTime,
  messsageTitle,
  objectId,
  objectName,
}) => {
  let url = '';
  if (objectId) {
    url =
      objectName === 'Event'
        ? 'agenda'
        : objectName === 'Projects'
        ? `/projects/${objectId}`
        : objectName === 'Tasks'
        ? `/tasks/${objectId}`
        : '/';
  }

  const date = new Date(messageTime);
  return (
    <Box
      bg='#F8F8F8'
      p={3}
      mb={1}
      dir='rtl'
      borderBottom={'0.5px solid rgba(179, 179, 183, 0.5)'}
    >
      <Text color='#2D5BFF' fontSize={'lg'} fontWeight={600}>
        {messsageTitle}
      </Text>
      <Text color='#8A8A8A' fontSize={'md'} fontWeight={300}>
        {messageBody}
      </Text>
      <HStack justifyContent={'space-between'}>
        <Text>{date.toString().split('GMT')[0]}</Text>
        {url && (
          <Text
            as={Link}
            to={url}
            fontWeight={'bold'}
            _hover={{
              textDecoration: 'underline',
            }}
          >
            الذهاب
          </Text>
        )}
      </HStack>
    </Box>
  );
};

export default Notifications;
