export const BASE_URL =  'https://matrix.althuwaybi.com';//process.env.REACT_APP_BASE_URL as string;
//'https://matrix14.info'https://localhost:44392  'https://matrix.althuwaybi.com'
export const ITEMS_PER_PAGE = 8;

export const PAGES = [1, 2, 4, 8, 16, 32, 64, 128];

export const FACEBOOK_BASE_URL = 'https://facebook.com/';
export const LINKEDIN_BASE_URL = 'https://linkedin.com/';
export const TELEGRAM_BASE_URL = 'https://t.me/';
export const TWITTER_BASE_URL = 'https://twitter.com/';
export const TIKTOK_BASE_URL = 'https://www.tiktok.com/';
export const REDDIT_BASE_URL = 'https://www.reddit.com/';
export const INSTAGRAM_BASE_URL = 'https://www.instagram.com/';
export const WHATSAPP_BASE_URL = 'https://wa.me/';
