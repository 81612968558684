import {
  Icon,
  IconProps,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputProps,
} from '@chakra-ui/react';
import { agendaIcon } from '../assests/icons';
import FlushedInput from './FlushedInput';
import { RiSearchLine } from 'react-icons/ri';

interface SearchInputProps extends InputProps {
  icon?: IconProps;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  pos,
  top,
  left,
  size,
  icon = {},
  maxW,
  ...props
}) => {
  return (
    <InputGroup size={size} pos={pos} top={top} left={left} maxW={maxW}>
      <InputLeftElement>
        <Icon as={RiSearchLine} boxSize={'1.5rem'} color='#A6B2BE' {...icon} />
      </InputLeftElement>
      <FlushedInput {...props} ps='5' />
    </InputGroup>
  );
};

export const TimeInput: React.FC<InputProps> = (props) => {
  return (
    <InputGroup size='md'>
      <InputLeftElement>
        <Image src={agendaIcon} />
      </InputLeftElement>
      <FlushedInput ps='5' {...props} />
    </InputGroup>
  );
};

interface InputWithAddonProps extends InputProps {
  addon: string;
 
}

export const InputWithAddon: React.FC<InputWithAddonProps> = ({
  addon,
  ...props
}) => {
  return (
    <InputGroup>
      <InputLeftAddon children={addon} />
      <Input type='text' {...props} />
    </InputGroup>
  );
};
