export const formateDate = (d: Date) => {
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');

  const formatedDate = `${year}-${month}-${day}`;

  return formatedDate;
};
 
const currentDate = new Date();
const year = currentDate.getFullYear();
const month1 = new Date(1).getMonth(); //currentDate.getMonth();
const month =   currentDate.getMonth();
const firstDayInMonth = new Date(year, month, 1);
export const firstDayTaskInMonth = new Date(2021, month1, 1);

const lastDayInMonth = new Date(year, month + 1, 0);

export const filterDateStart = formateDate(firstDayInMonth),
filterDateEnd = formateDate(lastDayInMonth);

export const filterDateStartTask = formateDate(firstDayTaskInMonth),
filterDateTaskEnd = formateDate(lastDayInMonth);


export const formatDate = (date: Date) => {
  const year = date.toLocaleString('default', { year: 'numeric' });
  const month = date.toLocaleString('default', { month: '2-digit' });
  const day = date.toLocaleString('default', { day: '2-digit' });

  return [year, month, day].join('-');
};
export const formatDatetime = (date: Date) => {
 
  const hour = date.toLocaleString('default', { hour: '2-digit' });
  const minutes = date.toLocaleString('default', { minute: '2-digit' });

  const seconds = date.toLocaleString('default', { second: '2-digit' });



  return [hour, minutes, seconds].join(':');
};
export const getNumberOfDays = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Add 1 to get the current month (0-indexed)

  // Use the Date object to get the number of days in the current month
  const numDays = new Date(year, month, 0).getDate();

  return numDays;
};

export const isDateIntervalOk = (start: string, end: string) => {
  return new Date(start) <= new Date(end);
};

export const displayDate = (d: string) => new Date(d).toDateString();

export const numberOfDays = (date: Date) => {
  const milliseconds = date.getTime(); // get the number of milliseconds since Jan 1, 1970
  const seconds = Math.floor(milliseconds / 1000); // convert milliseconds to seconds
  const minutes = Math.floor(seconds / 60); // convert seconds to minutes
  const hours = Math.floor(minutes / 60); // convert minutes to hours
  const days = Math.floor(hours / 24); // convert hours to days

  return days;
};

export const diffInDays = (
  startDate: Date | string,
  finishDate: Date | string
) =>
  Math.max(
    numberOfDays(new Date(finishDate)) - numberOfDays(new Date(startDate)),
    0
  );

export const toLocalDate = (d: string) => {
  try {
    return new Date(d).toLocaleDateString();
  } catch (error) {
    console.error(error);
    return d;
  }
};
