import {
  IFeature,
    IFilterState,
     IJobDescription,
     IPaginatedResult,IRolesJobDescription
  } from '../../../types';
  import { formatePaginatedResult } from '../../../utils';
  import { BASE_URL } from '../../../utils/constants';
  import { getAuthToken } from '../../../utils/storage';
  import api from '../http-client';
  
 
  
  export const getAllFeatures = async ({ page, count }: IFilterState) => {
    const { data } = await api.get<IPaginatedResult<IFeature>>(
      BASE_URL+  `/api/Roles/FeaturesList/${page}/${count}`
      , {
        headers: {
        Authorization: getAuthToken(),
      }
    });
    return formatePaginatedResult(data);
  };
  
  export const addFeature = async (data: IFeature) => {
    const res = await api.post<string>(
      BASE_URL+ `/api/Roles/AddFeatures`,
      data
      , {
        headers: {
        Authorization: getAuthToken(),
      }
    });
    return 'text-create-success';
  };
  
  export const editFeature = async (type: IFeature) => {
    const data = Object.keys(type).map((key) => {
      return {
        path: `/${key}`,
        operation: 'replace',
        value: type[key as 'featureId'],
      };
    });
    const res = await api.patch<string>(
      BASE_URL+`/api/Roles/UpdateFeatures/${type.featureId}`,
      data
      , {
        headers: {
        Authorization: getAuthToken(),
      }
    });
    return 'text-upt-success';
  };
  
  export const deleteFeature = async (id: number | string) => {
    const res = await api.delete<string>(
      BASE_URL+  `/api/Roles/DeleteFeature/${id}`
      , {
        headers: {
        Authorization: getAuthToken(),
      }
    });
    return 'text-del-success';
  };
  

  export const getAllJobs = async ({ page, count }: IFilterState) => {
    const { data } = await api.get<IPaginatedResult<IJobDescription>>(
      BASE_URL+  `/api/Roles/JobList/${page}/${count}`
      , {
        headers: {
        Authorization: getAuthToken(),
      }
    });
    return formatePaginatedResult(data);
  };
  
  export const addJob = async (data: IJobDescription) => {
    const res = await api.post<string>(
      BASE_URL+ `/api/Roles/AddJobDescription`,
      data
      , {
        headers: {
        Authorization: getAuthToken(),
      }
    });
    return 'text-create-success';
  };
  
  export const editJob = async (type: IJobDescription) => {
    debugger;
    const data = Object.keys(type).map((key) => {
      return {
        path: `/${key}`,
        operation: 'replace',
        value: type[key as 'jobDescriptionId'],
      };
    });
    const res = await api.patch<string>(
      
      BASE_URL+`/api/Roles/UpdateJobs/${type.jobDescriptionId}`,
      data
      , {
        headers: {
        Authorization: getAuthToken(),
      }
    });
    return 'text-upt-success';
  };
  
  export const deleteJob = async (id: number | string) => {
    const res = await api.delete<string>(
      BASE_URL+  `/api/Roles/DeleteJob/${id}`
      , {
        headers: {
        Authorization: getAuthToken(),
      }
    });
    return 'text-del-success';
  };
  export const getAllJobDescription = async () => {
    const { data } = await api.get<IJobDescription[]>(BASE_URL+'/api/Roles/GetJobDescriptions', {
      headers: {
      Authorization: getAuthToken(),
    }
  });
    return data;
  };
  // export const GetFeaturesByJob = async (jobDescriptionId: number | string) => {
  //   const { data } = await api.get<IRolesJobDescription>(
  //     BASE_URL+  `/api/Roles/AvailiableJobFeaturesList/${jobDescriptionId}`
  //     , {
  //       headers: {
  //       Authorization: getAuthToken(),
  //     }
  //   });
  //   return(data);
  // };
 
  // export const GetFeaturesByJob = async (jobDescriptionId: number | string) => {
  //   const res = await api.get<IFeature[]>(BASE_URL+ `/api/Roles/AvailiableJobFeaturesList/${jobDescriptionId}` , {
  //     headers: {
  //     Authorization: getAuthToken(),
  //   }
  // });
  //   return res.data;
  // };

  export const GetFeaturesByJob = async (jobDescriptionId?: number | string) => {
 
    const res = await api.get<IFeature>(
       BASE_URL +`/api/Roles/AvailiableJobFeaturesList/${jobDescriptionId}`
      
      , {
        headers: {
        Authorization: getAuthToken(),
      }
    });
 
    return res.data;
  };