import {
  IClient,
  IClientActivity,
  IEditClient,
  IFilterState,
  INewClient,
  IPaginatedResult,
  IProject,
} from '../../../types';
import { formatePaginatedResult } from '../../../utils';
import { BASE_URL } from '../../../utils/constants';
import { getAuthToken, setAuthToken } from '../../../utils/storage';
import api from '../http-client';

export const getAllClients = async (filter: {
  type: string;
  name: string;
  page: number;
  itemsPerPage: number;
  BranchId: number;
}) => {
  if (filter.type === 'all' || filter.name === '') {
    const { data } = await api.get<IPaginatedResult<IClient>>(
      BASE_URL+ `/api/Projects/Select/All/Clients/${filter.page}/${filter.itemsPerPage}/${filter.BranchId}`
      , {
        headers: {
        Authorization: getAuthToken(),
      }
    });

    return {
      numberOfPages: data.item1.pageCount,
      totalCount: data.item1.rawCount,
      clients: data.item2,
    };
  } else {
    return await fetchClientsByName(
      filter.name,
      filter.BranchId,
      filter.page,
      filter.itemsPerPage
    );
  }
};

export const addClient = async (client: INewClient) => {
  const fd = new FormData();

  Object.entries(client).forEach(([key, value]) => {
    fd.set(key, value);
  });

  const { data } = await api.post<string>(BASE_URL+`/api/Projects/Insert/Client`, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': getAuthToken(),
    },
  });

  return data;
};

export const editClient = async (client: IEditClient) => {
  debugger;
  const fd = new FormData();

  Object.entries(client).forEach(([key, value]) => {
    fd.set(key, value);
  });
console.log(fd);
  const { data } = await api.patch<string>(
    BASE_URL+ `/api/Projects/Update/Client/${client.clientId}`,
    fd,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      'Authorization': getAuthToken(),

      },
    }
  );

  return data;
};

export const fetchClientsByName = async (
  name: string,
  branchId: number,
  page = 1,
  count = 30
) => {
  const { data } = await api.post<IPaginatedResult<IClient>>(
    BASE_URL+ `/api/Projects/Select/Client/By/ClientName/${page}/${count}/${branchId}`,
    {
      clientName: name,
    }
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return {
    numberOfPages: data.item1.pageCount,
    totalCount: data.item1.rawCount,
    clients: data.item2,
  };
};

export const clientLogin = async (data: {
  userEmail: string;
  userPassword: string;
}) => {
  const res = await api.post<IClient>(BASE_URL+`/api/WithoutAuthorize/Client/Login`, data,
 
);
setAuthToken(res.data.userToken)  
  return res.data;
};

export const getClientProjects = async (clientId: string | number) => {
  const { data } = await api.get<IProject[]>(
    BASE_URL+`/api/Projects/Select/Client/Projects/${clientId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return data;
};

export const getAllClientActivities = async ({ page, count }: IFilterState) => {
  const { data } = await api.get<IPaginatedResult<IClientActivity>>(
    BASE_URL+  `/api/Projects/Select/Activity/Type/${page}/${count}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return formatePaginatedResult(data);
};

export const addClientActivity = async (data: IClientActivity) => {
  const res = await api.post<string>(
    BASE_URL+ `/api/Projects/Insert/Activity/Type`,
    data
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return 'create-success';
};

export const editClientActivity = async (type: IClientActivity) => {
  const data = Object.keys(type).map((key) => {
    return {
      path: `/${key}`,
      operation: 'replace',
      value: type[key as 'activityTypeId'],
    };
  });
  const res = await api.patch<string>(
    BASE_URL+`/api/Projects/Update/Activity/Type/Data/${type.activityTypeId}`,
    data
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return 'upt-success';
};

export const deleteClientActivity = async (id: number | string) => {
  const res = await api.delete<string>(
    BASE_URL+  `/api/Projects/Delete/Activity/Type/${id}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return 'del-success';
};
export const deleteClient = async (ClientId: number | string) => {
  const res = await api.delete<string>(
    BASE_URL+  `/api/Projects/Delete/Client/${ClientId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return 'del-success';
};