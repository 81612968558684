import { Container } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';

interface MainContainerProps {
  children: React.ReactNode;
  [key: string]: any;
}

const MainContainer: React.FC<MainContainerProps> = ({
  children,
  ...props
}) => {
  return (
    // <AnimatePresence>
      <Container
        maxW={['container.xl', null, null, null, null, '96%']}
        {...props}
      >
        {children}
      </Container>
    // </AnimatePresence>
  );
};

export default MainContainer;
