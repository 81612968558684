import {
  Box,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList,
} from '@chakra-ui/react';
import { menuIcon } from '../../assests/icons';

interface CustomMenuItemProps extends MenuItemProps {}

export const CustomMenuItem: React.FC<CustomMenuItemProps> = (props) => {
  return <MenuItem color='#5C5C5C' fontWeight={400} gap='.7rem' {...props} />;
};

export interface ActionsMenu {
  name: string;
  onClick: () => void;
  icon?: React.ReactNode;
  props?: MenuItemProps;
}

export interface CustomMenuProps {
  actionsMenu: ActionsMenu[];
}

export const CustomMenu: React.FC<CustomMenuProps> = ({ actionsMenu }) => {
  return (
    <Box>
      <Menu>
        <MenuButton>
          <Image src={menuIcon} boxSize='5' />
        </MenuButton>
        <MenuList minW={'5'}>
          {actionsMenu.map((item, index) => (
            <CustomMenuItem
              key={index}
              onClick={item.onClick}
              alignItems={'center'}
              gap={'2'}
              {...item.props}
            >
              {item.icon}
              {item.name}
            </CustomMenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};
