import React from 'react'
import MainContainer from '../MainContainer';
import { Stack, Text } from '@chakra-ui/react';
import { useTranslate } from '../../hooks/langs-hooks';
import { SplitCustodyModal } from './SplitCustodyModal';
import { SplitCustodyTable } from './SplitCustodyTable';


interface HRProps {
    employeeId?: number;
  }
export const SplitCustody:React.FC<HRProps> = ({}) => {
    const t =useTranslate();
  return (
    <>
        <MainContainer>
            <Stack display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexDirection={'row'} bgColor={'#f3f5fa'} p={5}>
                <Text fontWeight={900} fontSize='2.25rem' mb={1} p={6}>
                    {t('text-split-custody')}
                </Text>
                (<SplitCustodyModal />)
            </Stack>
            <SplitCustodyTable/>
        </MainContainer >
    </>
  )
}
