import { Box, BoxProps, Flex, Icon, Image, Text, VStack } from '@chakra-ui/react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { addIcon } from '../../assests/icons';
import { useTranslate } from '../../hooks/langs-hooks';
import { IResource } from '../../types';
import { CardText, CustomCardHeader, KeyValue } from './common';

interface SelelctableResourceCardProps  {
  isSelected: boolean;
  resource: IResource;
  onSelectToggle: () => void;
}

const SelelctableResourceCard: React.FC<SelelctableResourceCardProps> = ({
  isSelected,
  resource: r,
  onSelectToggle,
}) => {
  const t = useTranslate();

  return (
    <Box
      bg='#fff'
      boxShadow={
        '0px 3.00573px 9.0172px rgba(0, 0, 0, 0.1), 0px 3.00573px 6.01147px rgba(0, 0, 0, 0.06)'
      }
      borderRadius={'4px'}
      p={4}
      pos='relative'
      cursor={'pointer'}
      sx={{
        '&:hover .overlay': {
          display: 'flex',
        },
      }}
      border={isSelected ? '2px solid #2D5BFF' : ''}
      overflow={'visible'}
      onClick={onSelectToggle}
    >
      {/* start overlay */}
      <Box
        pos='absolute'
        top={0}
        overflow={'visible'}
        left={0}
        w='100%'
        h='100%'
        bgColor={'rgba(0, 0, 0, 0.4)'}
        bgClip={'blur(2px)'}
        display={'none'}
        flexDir={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={2}
        zIndex={1}
        className='overlay'
      >
        <Image p={'6px'} src={addIcon} bg='#fff' borderRadius={'3px'} />
        <Text color={'#fff'} fontSize={'1.375rem'} fontWeight={600}>
          {t('text-click-to-add')}
        </Text>
      </Box>
      {/* end overlay */}

      {isSelected && (
        <Icon
          as={AiFillCheckCircle}
          boxSize={'1.7rem'}
          color='#2D5BFF'
          pos='absolute'
          top='-10px'
          right={'-10px'}
          zIndex={2}
        />
      )}

      <CustomCardHeader
        title={r.resourceName}
        badge={r.sellingPrice?.toString()}
        acronymProps={{
          fontSize: '.8125rem',
          p: '.8rem',
        }}
        titleProps={{
          fontSize: 'md',
        }}
        badgeProps={{
          fontSize: 'xs',
        }}
      />
      <CardText fontSize={'xs'}>{r.resourceDescription}</CardText>
      <Flex flexWrap={'wrap'} rowGap={3} columnGap={5} alignItems={'center'}>
        {/* <KeyValue fontSize={'xs'} name={t('text-company')} value={'Microsoft'} /> */}
        <KeyValue fontSize={'xs'} name={t('text-price')} value={r.sellingPrice} /> 
        <KeyValue fontSize={'xs'} name={t('text-qty')} value={r.quantity} />
        <VStack ms='auto' spacing={0}>
          {/* <Text color='#5C5C5C' fontSize={'sm'} fontWeight={400}>
            {t('text-sellingPrice')}
          </Text> */}
          <Text color='#000' fontSize={'xl'} fontWeight={700}>
           {r.sellingPrice}
          
          </Text>
        </VStack>
      </Flex>
    </Box>
  );
};

export default SelelctableResourceCard;
