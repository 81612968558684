import { Box, Flex, HStack, Icon } from '@chakra-ui/react';
import { TfiReload } from 'react-icons/tfi';
import { useTranslate } from '../../hooks/langs-hooks';
import { SelectedResource } from '../../types';
import FlushedInput from '../FlushedInput';
import { CardText, CustomCardHeader } from './common';

interface EditableResourceCardProps {
  resource: SelectedResource;
  onQuantityChange: (resourceId: number, qty: number) => void;
}

const EditableResourceCard: React.FC<EditableResourceCardProps> = ({
  onQuantityChange,
  resource: r,
}) => {
  const t = useTranslate();

  return (
    <Box
      bg='#fff'
      boxShadow={
        '0px 3.00573px 9.0172px rgba(0, 0, 0, 0.1), 0px 3.00573px 6.01147px rgba(0, 0, 0, 0.06)'
      }
      borderRadius={'4px'}
      p={4}
      w='100%'
    >
      <CustomCardHeader
        title={r.resourceName}
        badge={r.sellingPrice.toString()}
        acronymProps={{
          fontSize: '.8125rem',
          p: '.8rem',
        }}
        titleProps={{
          fontSize: 'md',
        }}
        badgeProps={{
          fontSize: 'xs',
        }}
      />
      <Flex
        flexWrap={'wrap'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <CardText fontSize={'xs'} maxW={'500px'}>
          {r.resourceDescription}
        </CardText>
        <HStack spacing={3}>
          <FlushedInput
            maxW='10rem'
            variant={'outline'}
            px={1}
            label={t('text-qty')}
            value={r.qty}
            type='number'
            onChange={(e) => onQuantityChange(r.resourceId!, +e.target.value)}
          />
          <Icon
            as={TfiReload}
            alignSelf={'flex-end'}
            strokeWidth={'1px'}
            boxSize={'1.5rem'}
            color='#5C5C5C'
          />
          <FlushedInput
            isReadOnly
            maxW='10rem'
            variant={'outline'}
            px={1}
            type='number'
            label={t('text-price')}
            value={r.qty * r.sellingPrice}
          />
        </HStack>
      </Flex>
    </Box>
  );
};

export default EditableResourceCard;
