import {
  Button,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  background,
  useDisclosure,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import {  DragHandleIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import ServerError from '../../components/Error';
import MainContainer from '../../components/MainContainer';
import Pagination from '../../components/Pagination/Pagination';
import { SearchInput } from '../../components/inputs-with-left-elements';
import Loader from '../../components/loader/Loader';
import { CustomModal, CustomModalHeader } from '../../components/modals';

import { useAppContext } from '../../contexts/app-context';
import { GetAllReservedResource } from '../../data/client/endpoints/repo.endpoints';
import { useTranslate } from '../../hooks/langs-hooks';
import { IFilterState, IExistResource } from '../../types';
import { ITEMS_PER_PAGE } from '../../utils/constants';

import { toLocalDate } from '../../utils/date-utils';
import { MdDelete, MdEdit } from 'react-icons/md';
import { useCustomMutation } from '../../hooks/use-custsom-mutation';
 


interface ReservedResourceProps {
  projectId:number;
  taskId:number;
}

const ReservedResource: React.FC<ReservedResourceProps> = ({projectId,taskId}) => {
  const t = useTranslate();
  const { branch, user } = useAppContext();
  const [filter, setFilter] = useState<IFilterState>({
  //  type: 'all',
    page: 1,
    count: 8,
    userId:0,
    branchId:0
    //search: '',
  });

  const ReservedQuery = useQuery({
    queryKey: ['resourcesreserved',filter ],
    queryFn: () => GetAllReservedResource({ page: 1, count: 20, search:'',userId:projectId,branchId:taskId }),
  
    //refetchOnMount: 'always',
  });
  // const inventoryListQuery = useQuery({
  //   queryKey: ['inventory',filter ],
  //   queryFn: () => getSpecificResource(filter),
  //   //refetchOnMount: 'always',
  // });
  const { isOpen: isReportOpen, onOpen: onReportOpen, onClose: onReportClose } = useDisclosure();
 
  const newRequestModal = useDisclosure();
 
 
  return (
  
    <ModalContent  maxW={'90%'} my={3}>
    <CustomModalHeader title={t('text-ReservedResources')} />
    <ModalBody p={4}>
      {
        ReservedQuery.isLoading ? (
          <Loader size='lg' />
        ) : ReservedQuery.isError ? (
          <ServerError error={ReservedQuery.error} />
        ) : (
        <TableContainer>
        <Table variant='simple'>
            <Thead>
                <Tr>
                <Th>{t('text-resourcenumber')}</Th>
                <Th>{t('text-prjNum')}</Th>
                <Th>{t('text-taskNum')}</Th>

            <Th>{t('text-name')}</Th>
<Th>{t('text-category')}</Th>
<Th>{t('text-price')}</Th>
<Th>{t('text-qty')}</Th>


                </Tr>
            </Thead>
            <Tbody>
            
         {ReservedQuery.data?.items?.map((m) => (
          
<Tr key={m.resourceId }>
<Td>{m.resourceId}</Td>
<Td>{m.projectNum}</Td>
<Td>{m.TaskNum}</Td>

<Td>{m.name} </Td>
<Td>{m. category}</Td>
<Td>{m. price}</Td>
<Td>{m.quantity}</Td>


</Tr> ))} 
            </Tbody>
        </Table>
        </TableContainer>



      )}
                                           
     </ModalBody>
     <ModalFooter alignSelf={'center'}>
                                          {/* <Button colorScheme='red' mr={3} onClick={onReportClose}>
                                              Close
                                          </Button> */}
                                          {/* <Button mr={3} rightIcon={<ArrowDownIcon />} colorScheme='blue' variant='outline'>
                                              Save to PDF
                                          </Button> */}

                                      </ModalFooter>


   </ModalContent>
);
};


export default ReservedResource;
