import {
  MutationFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { handleError } from '../components/Error';
import { useTranslate } from './langs-hooks';
import useNotification from './use-notification-hook';

export const useCustomMutation = <TData, TVariables>({
  mutationFn,
  queryToInvalidate,
  onSuccess,
  onError,
}: {
  mutationFn: MutationFunction<TData, TVariables>;
  queryToInvalidate?: any[];
  onSuccess?: (data: TData) => void;
  onError?: Function;
}) => {
  const t = useTranslate();
  const notify = useNotification();

  const queryClient = useQueryClient();

  const newSupplierMutation = useMutation({
    mutationFn: mutationFn,
    onSuccess(message) {
      typeof message === 'string' && notify.success(t(message));
      queryToInvalidate && queryClient.invalidateQueries(queryToInvalidate);
      onSuccess?.(message);
    },
    onError(err) {
      notify.error(t(handleError(err)));
      onError?.();
    },
  });

  return newSupplierMutation;
};
