import { ICompanyData, IUserData } from '../types';

export const setLangToLocaleStorage = (lang: { id: number; name: string }) => {
  const data = JSON.stringify(lang);
  localStorage.setItem('lang', data);
};

export const getLangFromLocalStorage = (): { id: number; name: string } => {
  const lang = localStorage.getItem('lang');
  return lang ? JSON.parse(lang) : { id: 1, name: 'en' };
};

export const setUserToSessionStorage = (user: IUserData) => {
  const data = JSON.stringify(user);
  localStorage.setItem('user', data);
};
export const setAuthToken = (token: string) => {
  localStorage.setItem('userToken', token);
};

export const getAuthToken = () =>
  localStorage.getItem('userToken');

  
export const getUserFromSessionStorage = (): IUserData | null => {
  const data = localStorage.getItem('user');
  return data ? JSON.parse(data) : null;
};

// export const setUserIdToSessionStorage = (user: IUserData) => {
//   const data = JSON.stringify(user.userId);
//   sessionStorage.setItem('userId', data);
// };

// export const getUserIDFromSessionStorage = (): IUserData | null => {
//   const data = sessionStorage.getItem('user');
//   return data ? JSON.parse(data) : null;
// };

export const clearlocalStorage = () => localStorage.clear();

export const clearStorage = () => sessionStorage.clear();

export const setNotificationToken = (token: string) => {
  sessionStorage.setItem('notification-token', token);
};

export const getNotificationToken = () =>
  sessionStorage.getItem('notification-token');

export const setBranchIdToStorage = (branchId: number) =>
  sessionStorage.setItem('branchId', branchId.toString());

export const getBranchIdFromStorage = () => {
  const id = sessionStorage.getItem('branchId');
  return id ? +id : null;
};

export const storeCompanyInStoreage = (company: ICompanyData) => {
  sessionStorage.setItem('company', JSON.stringify(company));
};

export const getCompanyFromStoreage = (): ICompanyData | null => {
  const company = sessionStorage.getItem('company');

  return company ? JSON.parse(company) : null;
};
