import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react';
import { useTranslate } from '../../hooks/langs-hooks';
import MainContainer from '../MainContainer';

interface ProtectedPageProps {
  children?: React.ReactNode;
  condition?: boolean;
}

const ProtectedPage: React.FC<ProtectedPageProps> = ({
  children,
  condition,
}) => {
  const t = useTranslate();

  return (
    <>
      {condition ? (
        children
      ) : (
        <MainContainer py={4}>
          <Alert status='error'>
            <AlertIcon />
            <AlertTitle>{t('text-access-denied')}</AlertTitle>
            <AlertDescription>
              {t('text-access-denied-description')}
            </AlertDescription>
          </Alert>
        </MainContainer>
      )}
    </>
  );
};

export default ProtectedPage;
