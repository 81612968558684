import { Avatar, Box, Button, Checkbox, HStack, Heading, Image, Input, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Toast, Tr, VStack, useDisclosure, useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CustomMenuItem } from '.';
import { menuIcon } from '../../assests/icons';
import { useMe } from '../../contexts/app-context';
import { deleteTask } from '../../data/client/endpoints/project.endpoints';
import { useTranslate } from '../../hooks/langs-hooks';
import useNotification from '../../hooks/use-notification-hook';
import { ITask } from '../../types';
import { isClient, isSystemUser } from '../../utils/priviliages';
import { useModalAction } from '../modals/context';
import { trueIcon } from '../../assests/icons'
import { ChevronDownIcon } from '@chakra-ui/icons';
import React, { useEffect, useState } from 'react';

interface TaskActionsMenuProps {
  task: ITask;
  onDeleteSuccess?: () => void;
}

export const TaskActionsMenu: React.FC<TaskActionsMenuProps> = ({
  task,
  onDeleteSuccess,
}) => {
  const [selectedOption, setSelectedOption] = useState(0);
  const t = useTranslate();
  const notify = useNotification();
  const { openModal } = useModalAction();
  const me = useMe();
  // const toast = useToast()
  // const statuses = ['success', 'error', 'warning', 'info']

  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isTaskFinishedOpen, onOpen: onTaskFinishedOpen, onClose: onTaskFinishedClose } = useDisclosure();
  const { isOpen: isAdditionalTimeOpen, onOpen: onAdditionalTimeOpen, onClose: onAdditionalTimeClose } = useDisclosure();
  const { isOpen: isKPIScoreOpen, onOpen: onKPIScoreOpen, onClose: onKPIScoreClose } = useDisclosure();




  const handleSelectChange = (event: any) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
  };






  function addDays(date: string, days: number): Date {
    var result = new Date(date);
    var extraTime = result.getDate() + +days;
    result.setDate(extraTime);
    return result;//.toDateString();
  }




  const deleteTaskMuation = useMutation({
    mutationFn: deleteTask,
    onSuccess() {
      notify.success(t('text-del-success'));
      queryClient.invalidateQueries({
        predicate(query: any) {
          const queryKey = query.queryKey?.[0] as string;
          return queryKey.includes('task');
        },
      });
      onDeleteSuccess?.();
    },
  });

  const openCommentsModal = () => {
    openModal('TASK_EMPLOYEE_COMMENTS', task);
  };

  const openClientCommentsModal = () => {
    openModal('TASK_CLIENT_COMMENTS', task);
  };
  const ReservedMaterialsModal = () => {
    openModal('TASK_MATERIAL_Reserved_Project', {
      taskId: task.taskId,
      projectId: task.projectId,
    });
  };
  const openRequestMaterialsModal = () => {
    openModal('TASK_MATERIAL_REQUEST_Project', {
      taskId: task.taskId,
      projectId: task.projectId,
    });
  };
  const openNewTaskMaterialsModal = () => {
    openModal('TASK_REQUEST_MATERIAL', {
      taskId: task.taskId,
      projectId: task.projectId,
    });
  };

  const hasAccess =
    me?.privileges?.editTask ||
    me?.privileges?.addCommentToTask ||
    isClient(me) ||
    me?.privileges?.deleteTask;

  if (!hasAccess) {
    return null;
  }

  return (
    <Box >
      <Menu>
        <MenuButton>
          <Image src={menuIcon} boxSize='5' />
        </MenuButton>
        <MenuList minW='5'>
        {(isClient(me) || me?.privileges?.addCommentToTask) && (
            <CustomMenuItem onClick={openRequestMaterialsModal}>
              {t('text-requestMaterial')}
              
            </CustomMenuItem>
          )}
          {isSystemUser(me) && (
            <CustomMenuItem onClick={openNewTaskMaterialsModal}>
              {t('text-RecoverResources')}
            </CustomMenuItem>
          )}
           {(isClient(me) || me?.privileges?.editTask) && (
            <CustomMenuItem onClick={ReservedMaterialsModal}>
              {t('text-ReservedMaterial')}
              
            </CustomMenuItem>
          )}
          {me?.privileges?.editTask && (
            <>
              <CustomMenuItem
                onClick={() =>
                  openModal('TASK_MEMBERS', { taskId: task.taskId })
                }
              >
                {t('text-members')}
              </CustomMenuItem>
              <CustomMenuItem
                onClick={() => openModal('EDIT_TASK', { taskId: task.taskId })}
              >
                {t('text-edit')}
              </CustomMenuItem>
            </>
          )}
          {me?.privileges?.addCommentToTask && (
            <CustomMenuItem onClick={openCommentsModal}>
              {t('text-task-comments')}
            </CustomMenuItem>
          )}
          {(isClient(me) || me?.privileges?.addCommentToTask) && (
            <CustomMenuItem onClick={openClientCommentsModal}>
              {t('text-client-comments')}
            </CustomMenuItem>
          )}
           
            
          {(isClient(me) || me?.privileges?.addCommentToTask) && (
            <>
              <CustomMenuItem onClick={onAdditionalTimeOpen}>
                {t('text-request-additional-time')}
              </CustomMenuItem>
              <Modal blockScrollOnMount={false} isOpen={isAdditionalTimeOpen} onClose={onAdditionalTimeClose} size={'2xl'}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>{t('text-request-additional-time')}</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody display={'flex'} alignSelf={'center'} alignItems={'center'} justifyContent={'space-between'} gap={5} flexDirection={'column'}>
                    {/* <Image bg={'cadetblue'} w={'auto'} src={'https://res.cloudinary.com/newbookify/image/upload/v1716385288/logo_te0bu7.png'} /> */}

                    <TableContainer>
                      <Table variant='striped' colorScheme='teal'>
                        <Tbody>
                          <Tr>
                            <Td>{t('text-taskName')}</Td>
                            <Td>{task.taskName}</Td>
                          </Tr>
                          {task.taskDescription &&
                            <Tr>
                              <Td>{t('text-taskDescription')} </Td>
                              <Td>{task.taskDescription}</Td>
                            </Tr>}
                          <Tr>
                            <Td>{t('text-taskStartTime')}</Td>
                            <Td>{new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(Date.parse(task.taskStartTime))}</Td>
                          </Tr>
                          <Tr>
                            <Td>{t('text-taskFinishTime')}</Td>
                            <Td>{new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(Date.parse(task.taskFinishTime))}</Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </TableContainer>
                    <Stack alignSelf={'start'}>
                      <Select placeholder={t('text-extra-time')} onChange={handleSelectChange}>
                        <option value='1'>{t('text-one-day')}</option>
                        <option value='2'>{t('text-two-days')}</option>
                        <option value='4'>{t('text-three-four-days')}</option>
                        <option value='6'>{t('text-five-six-days')}</option>
                        <option value='8'>{t('text-seven-eight-days')}</option>
                        <option value='10'>{t('text-nine-ten-days')}</option>
                      </Select>

                    </Stack>
                    <HStack>
                      <VStack>
                        <Text alignSelf={'start'}>{t('text-taskStartTime')}</Text>
                        <Input placeholder='Select Date and Time' size='md' type='text' isDisabled={true}
                          value={new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format((addDays(task.taskFinishTime, 1)))} />
                      </VStack>
                      <VStack>
                        <Text alignSelf={'start'}>{t('text-taskFinishTime')}</Text>
                        <Input placeholder='Select Date and Time' size='md' type='text' isDisabled={true}
                          value={new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format((addDays(task.taskFinishTime, selectedOption)))} />
                      </VStack>
                    </HStack>


                    <Stack spacing={3}>
                      <Text>{t('text-please-state-the-reason')}</Text>
                      <Input />
                    </Stack>
                  </ModalBody>

                  <ModalFooter display={'flex'} justifyContent={'center'}>
                    <Button colorScheme='green'>{t('text-sent')}</Button>
                    <Button colorScheme='red' mr={3} onClick={onAdditionalTimeClose}>
                      {t('text-close')}
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </>
          )}
          {me?.privileges?.deleteTask && (
            <CustomMenuItem
              onClick={() => {
                if (window.confirm(t('text-are-you-sure'))) {
                  deleteTaskMuation.mutate(task.taskId);
                }
              }}
            >
              {t('text-del-task')}
            </CustomMenuItem>
          )}
          {me?.privileges?.addEmpToTask && (
            <>
              <CustomMenuItem
                onClick={onKPIScoreOpen}
              >
                {t('text-KPI-score')}
              </CustomMenuItem>
              <Modal isOpen={isKPIScoreOpen} onClose={onKPIScoreClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader color={'blue'}>{t('text-employees-participating-mission')}</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                  <Stack px={6} mb={5}>
                      <Checkbox defaultChecked alignSelf={'center'}>
                        <Stack display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'row'}>
                          <Avatar size='sm' name='Hossam Salah' src='https://res.cloudinary.com/newbookify/image/upload/v1716961416/%D8%A7%D9%81%D8%AA%D8%A7%D8%B1%D9%A3_sql5kc.png' />
                          <Stack gap={0}>
                            <Heading as='h6' size='xs' mb={0}>Hossam Salah</Heading>
                            <Text fontSize={'x-small'}>Programming</Text>
                          </Stack>
                        </Stack>
                      </Checkbox>
                    </Stack>
                    <Stack px={6} mb={5}>
                      <Checkbox defaultChecked alignSelf={'center'}>
                        <Stack display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'row'}>
                          <Avatar size='sm' name='Hossam Salah' src='https://res.cloudinary.com/newbookify/image/upload/v1716961416/%D8%A7%D9%81%D8%AA%D8%A7%D8%B1%D9%A3_sql5kc.png' />
                          <Stack gap={0}>
                            <Heading as='h6' size='xs' mb={0}>Hossam Salah</Heading>
                            <Text fontSize={'x-small'}>Programming</Text>
                          </Stack>
                        </Stack>
                      </Checkbox>
                    </Stack>
                    <Stack px={6} mb={5}>
                      <Checkbox defaultChecked alignSelf={'center'}>
                        <Stack display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'row'}>
                          <Avatar size='sm' name='Hossam Salah' src='https://res.cloudinary.com/newbookify/image/upload/v1716961416/%D8%A7%D9%81%D8%AA%D8%A7%D8%B1%D9%A3_sql5kc.png' />
                          <Stack gap={0}>
                            <Heading as='h6' size='xs' mb={0}>Hossam Salah</Heading>
                            <Text fontSize={'x-small'}>Programming</Text>
                          </Stack>
                        </Stack>
                      </Checkbox>
                    </Stack>
                    <Stack>
                      <Heading as='h5' size='xs' color={'blue'}>{t('text-initiative-points')}</Heading>
                      <Stack display={'flex'} justifyContent={'space-around'} alignItems={'center'} flexDirection={'row'}>
                        <Text fontSize={'1.1rem'} mb='8px'>{t('text-enter-rating')}:</Text>
                        <Input
                          htmlSize={4} width='auto'
                        />
                      </Stack>
                      <Stack display={'flex'} justifyContent={'space-around'} alignItems={'center'} flexDirection={'row'}>
                        <Text fontSize={'1.1rem'} mb='8px'>{t('text-the-total')}:</Text>
                        <Input
                          isDisabled
                          value={"1/5"}
                          htmlSize={4} width='auto'
                        />
                      </Stack>
                    </Stack>
                  </ModalBody>

                  <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={onKPIScoreClose}>
                      Close
                    </Button>
                    <Button variant='ghost'>{t('text-save')}</Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </>

          )}
          {me?.privileges?.finishTask && (
            <CustomMenuItem
            >
              <Button onClick={onTaskFinishedOpen} colorScheme='teal' size='md'>
                {t('text-done')}
              </Button>
              <Modal blockScrollOnMount={false} isOpen={isTaskFinishedOpen} onClose={onTaskFinishedClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>{t('text-finish-task')}</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody display={'flex'} alignSelf={'center'} alignItems={'center'} justifyContent={'space-between'} gap={5} flexDirection={'column'}>
                    <Image src={trueIcon} boxSize={'5rem'} />
                    <Text
                      textTransform={'capitalize'}
                      maxW='24.5rem'
                      borderRadius={'1.25rem'}
                      fontWeight={1000}
                      fontSize={'1rem'}
                      color='gray'
                    >
                      {t('text-sent-task')}
                    </Text>
                  </ModalBody>

                  <ModalFooter display={'flex'} justifyContent={'center'}>
                    <Button colorScheme='green'>{t('text-sent')}</Button>
                    <Button colorScheme='red' mr={3} onClick={onTaskFinishedClose}>
                      {t('text-close')}
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </CustomMenuItem>
          )}
        </MenuList>
      </Menu>
    </Box>
  );
};
