import { Spinner, SpinnerProps } from '@chakra-ui/react';

const Loader: React.FC<SpinnerProps> = (props) => {
  return (
    <Spinner
      thickness='4px'
      speed='0.65s'
      emptyColor='gray.200'
      color='blue.500'
      display='block'
      mx='auto'
      {...props}
    />
  );
};

export default Loader;
