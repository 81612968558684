import { useQuery } from '@tanstack/react-query';
import { useAppContext } from '../contexts/app-context';
import { getAllDepartmentsInBranch } from './client/endpoints/shared.endpoints';
import { getAllJobDescription } from './client/endpoints/features.endpoints';


export const useDepartments = () => {
  const { branch } = useAppContext();
  return useQuery({
    queryKey: ['departments', branch],
    queryFn: () => getAllDepartmentsInBranch(branch?.branchId),
  });
};
export const useJobsDesc = () => {
   
  return useQuery({
    queryKey: ['jobDescriptions'],
    queryFn: () => getAllJobDescription(),
  });
};
