import {
  ModalHeader,
  ModalCloseButton,
  ModalHeaderProps,
  Modal,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';

interface CustomModalHeaderProps extends ModalHeaderProps {
  title: string;
  p?: number | string;
}

export const CustomModalHeader: React.FC<CustomModalHeaderProps> = ({
  title,
  p = 2,
  ...props
}) => {
  return (
    <ModalHeader
      p={p}
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      fontWeight={700}
      fontSize='h4'
      {...props}
    >
      {title}
      <ModalCloseButton pos='static' size={'lg'} />
    </ModalHeader>
  );
};

export const CustomModal: React.FC<ModalProps> = ({ children, ...props }) => {
  return (
    <Modal closeOnOverlayClick={false} {...props}>
      <ModalOverlay />
      {children}
    </Modal>
  );
};
