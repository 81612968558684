import { Alert, AlertIcon, AlertProps } from '@chakra-ui/react';
import { useTranslate } from '../hooks/langs-hooks';

interface ErrorProps extends AlertProps {
  error: any;
}

const ServerError: React.FC<ErrorProps> = ({ error, ...props }) => {
  const t = useTranslate();

  let message: string = error.message ?? '';

  try {
    if (typeof error.response?.data === 'string') {
      message = error.response?.data;
    }
  } catch (err) {
    console.log(err);
  }

  console.log({ message, translation: t(message)});

  return (
    <Alert status='error' p={2} {...props}>
      <AlertIcon />
      {t(message)}
    </Alert>
  );
};

export const handleError = (err: any) => {
  let message: string = err.message ?? '';
  try {
    if (typeof err.response?.data === 'string') {
      message = err.response?.data;
    }
  } catch (err) {
    console.log(err);
  }

  return message;
};

export default ServerError;
