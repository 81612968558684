import { BASE_URL } from '../../../utils/constants';
import api from '../http-client';
import { getAuthToken } from '../../../utils/storage';
export const createDepartment = async (data: {
  departmentName: string;
  branchId: number;
}) => {
  const res = await api.post<string>(BASE_URL+`/api/Users/Insert/Department`, data  , {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return res.data;
};

export const deleteDepartment = async (departmentId: number) => {
  const res = await api.delete<string>(
    BASE_URL+ `/api/Users/Delete/Department/${departmentId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data;
};

export const getDepartmentsKPI = async () => {
  const { data } = await api.get<
    {
      departmentId: number;
      departmentName: string;
      departmentTaskCount: number;
      departmentTaskCompleted: number;
    }[]
  >(BASE_URL+`/api/Projects/Department/KPI`  , {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return data;
};
