import { ArrowDownIcon, RepeatIcon } from '@chakra-ui/icons'
import { Button, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useTranslate } from '../../hooks/langs-hooks'
import { CustodayObj, ElementObjResponse } from '../../types'
import { useAppContext } from '../../contexts/app-context'
import { GetCustodayPdf } from '../../data/client/endpoints/Covenant/Covenant.endPoint'
import useNotification from '../../hooks/use-notification-hook'

export const LetterReceiptCustody: React.FC<{ info: CustodayObj }> = ({ info }) => {
    const t = useTranslate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { user: me } = useAppContext();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const notify = useNotification();

    const handleSaveToPdf = async () => {
        try {
            setIsLoading(true);
            await GetCustodayPdf(info.id);
            setIsLoading(false);
            notify.success("success operation");
            onClose();
        }
        catch (error) {
            setIsLoading(false);
            notify.error("server error")
        }
    }
    return (
        <>
            <Button rightIcon={<RepeatIcon />} colorScheme={info.isReturned ? 'green' : 'red'} size='md' onClick={!info.isReturned ? onOpen : () => { }}>
                {
                    info.isReturned ? t('text-pledge-restored') : t('text-covenant-longer')
                }
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} size={'6xl'}>
                <ModalOverlay />
                <ModalContent p={0}>
                    <ModalHeader bg={'#f1f4fc'} p={2} display={'flex'} justifyContent={'space-between'} alignItems={'start'}>
                        <Image w={150} src='https://res.cloudinary.com/newbookify/image/upload/v1716877213/Artboard_1_e1aarq.png' bg={'teal'} />
                        <Stack textAlign={'left'}>
                            <Text fontSize={12}>Matrixs Group</Text>
                            <Text fontSize={12}>CS 4032255438</Text>
                            <Text fontSize={12}>info@matrix14.com</Text>
                            <Text fontSize={12}>Alfaisaliyah – nex to Saudi post</Text>
                            <Text fontSize={12}>Taif – Saudi Arabia –26523</Text>
                            <Text fontSize={12}>+966 0127260219</Text>
                        </Stack>
                    </ModalHeader>
                    <ModalBody p={4}>
                        <Text fontSize={30} textAlign={'center'} mb={5}>{t('text-letter-receipt-custody')}</Text>
                        <Stack display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexDirection={'row'} mb={5}>
                            <Stack>
                                <Stack display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexDirection={'row'}>
                                    <Text fontSize={20} color={'blue'}>{t('text-name-of-the-manager')} :</Text>
                                    <Text>{me?.userName}</Text>
                                </Stack>
                                <Stack display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexDirection={'row'}>
                                    <Text fontSize={20} color={'blue'}>{t('text-received-date')} :</Text>
                                    <Text>{info.startDate?.toString().split('T')[0]}</Text>
                                </Stack>
                            </Stack>
                        </Stack>
                        {
                            info.resources.length > 0 &&
                            <TableContainer>
                                <Table variant='simple'>
                                    <Thead>
                                        <Tr>
                                            <Th textAlign={'center'}>{t('text-item-number')}</Th>
                                            <Th textAlign={'center'}>{t('text-category')}</Th>
                                            <Th textAlign={'center'}>{t('text-item-name')}</Th>
                                            <Th textAlign={'center'}>{t('text-quantity')}</Th>
                                            <Th textAlign={'center'}>{t('text-price')}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            info.resources.map((ele, index) => (
                                                <Tr key={index}>
                                                    <Td textAlign={'center'}>{ele.id}</Td>
                                                    <Td textAlign={'center'}>{ele.category}</Td>
                                                    <Td textAlign={'center'}>{ele.name}</Td>
                                                    <Td textAlign={'center'}>{ele.availableQuantity}</Td>
                                                    <Td textAlign={'center'}>{ele.price}</Td>
                                                </Tr>
                                            ))
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        }
                    </ModalBody>
                    <ModalFooter alignSelf={'center'}>
                        <Button colorScheme='red' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button isLoading={isLoading} mr={3} rightIcon={<ArrowDownIcon />} colorScheme='blue' variant='outline' onClick={handleSaveToPdf}>
                            Save to PDF
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
