import { IPriviliges, IUserInfo } from '../types';

export const allPriviliges: IPriviliges = {
  addProject: false,
  editProject: false,
  deleteProject: false,
  addEmpToProject: false,
  addTask: false,
  editTask: false,
  deleteTask: false,
  addEmpToTask: false,
  finishTask: false,
  addCommentToTask: false,
  addBranch: false,
  deleteBranch: false,
  addEmployee: false,
  deleteEmployee: false,
  editEmployee: false,
  addClient: false,
  editClient: false,
  viewClients: false,
  deleteClient: false,
  addEmpToBranch: false,
  viewEmpKPI: false,
  viewClientProjects: false,
  editEmpPriviliges: false,
  editProjectTypes: false,
  editEntityGoals: false,
  addResourceCategory: false,
  createCustodayRequest: false,
  enterMaterialToStore: false,
  viewAllCustodayLogs: false,
  viewAllExchangeLogs: false,
  viewAllReceivingLogs: false,
  custodays: false,
  viewExchangeLogs: false,
  viewReceivingLogs: false,
  resources: false,
  viewReports: false,
  viewReportsStatistics: false,
  viewTask: false,
  viewActivityHistroy: false,
  viewProjects: false,
  viewProject: false,
  addDepartment: false,
  deleteDepartment: false,
  suppliers: false,
  clientActivities: false,
  resourceUnits: false,
};

export const adminPriviliges: IPriviliges = {
  addProject: true,
  editProject: true,
  deleteProject: true,
  addEmpToProject: true,
  addTask: true,
  editTask: true,
  deleteTask: true,
  addEmpToTask: true,
  finishTask: true,
  addCommentToTask: true,
  addBranch: true,
  deleteBranch: true,
  addEmployee: true,
  deleteEmployee: true,
  editEmployee: true,
  addClient: true,
  editClient: true,
  viewClients: true,
  deleteClient: true,
  addEmpToBranch: true,
  viewEmpKPI: true,
  viewClientProjects: true,
  editEmpPriviliges: true,
  editProjectTypes: true,
  editEntityGoals: true,
  addResourceCategory: true,
  createCustodayRequest: true,
  enterMaterialToStore: true,
  viewAllCustodayLogs: true,
  viewAllExchangeLogs: true,
  viewAllReceivingLogs: true,
  custodays: true,
  viewExchangeLogs: true,
  viewReceivingLogs: true,
  resources: true,
  viewReports: true,
  viewReportsStatistics: true,
  viewTask: true,
  viewActivityHistroy: true,
  viewProjects: true,
  viewProject: true,
  addDepartment: true,
  deleteDepartment: true,
  suppliers: true,
  clientActivities: true,
  resourceUnits: true,
};

export const projectManagerPriviliges: IPriviliges = {
  addProject: true,
  editProject: true,
  deleteProject: true,
  addEmpToProject: true,
  addTask: true,
  editTask: true,
  deleteTask: true,
  addEmpToTask: true,
  finishTask: true,
  addCommentToTask: true,
  addBranch: true,
  deleteBranch: false,
  addEmployee: true,
  deleteEmployee: true,
  editEmployee: false,
  addClient: true,
  editClient: true,
  viewClients: true,
  deleteClient: true,
  addEmpToBranch: true,
  viewEmpKPI: true,
  viewClientProjects: true,
  editEmpPriviliges: true,
  editProjectTypes: true,
  editEntityGoals: true,
  addResourceCategory: true,
  createCustodayRequest: true,
  enterMaterialToStore: true,
  viewAllCustodayLogs: true,
  viewAllExchangeLogs: true,
  viewAllReceivingLogs: true,
  custodays: true,
  viewExchangeLogs: true,
  viewReceivingLogs: true,
  resources: true,
  viewReports: false,
  viewReportsStatistics: false,
  viewTask: true,
  viewActivityHistroy: true,
  viewProjects: true,
  viewProject: true,
  addDepartment: true,
  deleteDepartment: true,
  suppliers: false,
  clientActivities: false,
  resourceUnits: false,
};

export const headOfDepartmentPriviliges: IPriviliges = {
  addProject: true,
  editProject: true,
  addEmpToProject: true,
  addTask: true,
  editTask: true,
  deleteTask: true,
  addEmpToTask: true,
  finishTask: true,
  addCommentToTask: true,
  addEmployee: false,
  editEmployee: false,
  addClient: true,
  editClient: true,
  viewClients: true,
  viewEmpKPI: true,
  viewClientProjects: true,
  editEmpPriviliges: false,
  editProjectTypes: false,
  editEntityGoals: false,
  addResourceCategory: false,
  createCustodayRequest: false,
  enterMaterialToStore: false,
  viewAllCustodayLogs: false,
  viewAllExchangeLogs: false,
  viewAllReceivingLogs: false,
  custodays: false,
  viewExchangeLogs: false,
  viewReceivingLogs: false,
  resources: false,
  viewReports: false,
  viewReportsStatistics: false,
  viewTask: true,
  viewActivityHistroy: false,
  viewProjects: true,
  viewProject: true,
  addDepartment: true,
  suppliers: false,
  clientActivities: false,
  resourceUnits: false,
};

export const clientPriviliges: IPriviliges = {
  addCommentToTask: true,
  viewProject: true,
};

export const employeePriviliges: IPriviliges = {
  addTask: true,
  editTask: true,
  addEmpToTask: true,
  finishTask: true,
  addCommentToTask: true,
  addClient: true,
  editClient: true,
  viewClients: true,
  addEmpToBranch: true,
  addResourceCategory: true,
  createCustodayRequest: true,
  enterMaterialToStore: true,
  viewAllCustodayLogs: true,
  viewAllExchangeLogs: true,
  viewAllReceivingLogs: true,
  custodays: true,
  viewExchangeLogs: true,
  viewReceivingLogs: true,
  resources: true,
  viewReportsStatistics: true,
  viewTask: true,
  viewProjects: true,
  viewProject: true,
  addDepartment: true,
  suppliers: true,
  clientActivities: true,
  resourceUnits: true,
};

export const isClient = (user: IUserInfo | null) => {
  if (!user) return false;
  return user.jobDescriptionName === 'client';
};

export const isAdmin = (user: IUserInfo | null) => {
  if (!user) return false;
  return user.jobDescriptionName === 'Owner';
};

export const isHead = (user: IUserInfo | null) => {
  if (!user) return false;
  return user.jobDescriptionName === 'Head Of Department';
};

export const isProjectManager = (user: IUserInfo | null) => {
  if (!user) return false;
  return user.jobDescriptionName === 'Project Manager';
};

export const isStaff = (user: IUserInfo | null) => {
  if (!user) return false;
  return (
    user.jobDescriptionName === 'Owner' ||
    user.jobDescriptionName === 'Project Manager' ||
    user.jobDescriptionName === 'Head Of Department'
  );
};

export const isEmployee = (user: IUserInfo | null) => {
  if (!user) return false;
  return user.jobDescriptionName === 'Employee';
};

export const isAdminOrHead = (user: IUserInfo | null) => {
  if (!user) return false;
  return (
    user.jobDescriptionName === 'Owner' ||
    user.jobDescriptionName === 'Head Of Department'
  );
};

export const isSystemUser = (user: IUserInfo | null) => {
  if (!user) return false;
  return (
    user.jobDescriptionName === 'Owner' ||
    user.jobDescriptionName === 'Head Of Department' ||
    user.jobDescriptionName === 'Project Manager' ||
    user.jobDescriptionName === 'Employee'
  );
};

export const isHr = (user: IUserInfo | null) => {
  if (!user) return false;
  return (
    user.jobDescriptionName === 'Owner' ||
    user.jobDescriptionName === 'Head Of Department' ||
    user.jobDescriptionName === 'Project Manager' ||
    user.jobDescriptionName === 'HR'
  );
};

export const isWarehouseManager = (user: IUserInfo | null) => {
  if (!user) return false;
  return (
    user.jobDescriptionName === 'Owner' ||
    user.jobDescriptionName === 'isWarehouseManager'
  );
};

export const isAdminOrProjectManager = (user: IUserInfo | null) => {
  if (!user) return false;
  return (
    user.jobDescriptionName === 'Owner' ||
    user.jobDescriptionName === 'Project Manager'
  );
};

export const getUserPriviliges = (user: any) => {
  return isAdmin(user)
    ? adminPriviliges
    : isProjectManager(user)
    ? projectManagerPriviliges
    : isHead(user)
    ? headOfDepartmentPriviliges
    : isEmployee(user)
    ? employeePriviliges
    : {};
};
