import { Navigate } from 'react-router-dom';
import { useAppContext } from '../contexts/app-context';

interface PrivateRouteProps {
  children: JSX.Element;
}

export const UserPrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { user } = useAppContext();
  return user ? children : <Navigate to='/signin' />;
};

export const CompanyPrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
}) => {
  const { company } = useAppContext();
  return company ? children : <Navigate to='/company/signin' />;
};

export const UserOrCompanyPrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
}) => {
  const { company, user } = useAppContext();
  return company || user ? children : <Navigate to='/signin' />;
};

