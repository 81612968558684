import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { setNotificationToken } from './utils/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyDTrMWLfwSXsIPMdPZXY8zhxQwF-hn10-A',
  authDomain: 'erp-matrix.firebaseapp.com',
  projectId: 'erp-matrix',
  storageBucket: 'erp-matrix.appspot.com',
  messagingSenderId: '15209657309',
  appId: '1:15209657309:web:42acc9bce24c4b73ee3c34',
  measurementId: 'G-5DJX7X8B82',
};

const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

export const initNotifications = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey:
          'BDUHfuzoatZlx7wu03UoWfqTuLGjorcjfttE-KBywvUVduOVe01D6Lg7uAVKb2xwLfaeWgii1Keqf5k_cOP13tI',
      });
      if (token) {
        setNotificationToken(token);
      } else {
        console.log(`Can't get token`);
      }
    } else {
      console.log('Do not have permission');
    }
  } catch (err) {
    console.log(err);
  }
};
