import { Search2Icon } from '@chakra-ui/icons'
import { Button, Input, InputGroup, InputLeftElement, InputRightAddon, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslate } from '../../hooks/langs-hooks';
import { LetterReceiptCustody } from './LetterReceiptCustody';
import { ReturnProcessModal } from './ReturnProcessModal';
import { useNavigate } from 'react-router-dom';
import { GetCustodayByIdForEmployee, GetUserCustodaysPerPage, GetUserCustodaysTotalCount } from '../../data/client/endpoints/Covenant/Covenant.endPoint';
import { CustodayObj } from '../../types';
import { MatrixLoading } from '../loader/MatrixLoading';
import { NotFound } from '../hr-components/NotFound';
import { Pagination } from '../hr-components/Pagination';

interface CovenantTableProps {
    parentState: boolean;
}


export const CovenantTable: React.FC<CovenantTableProps> = ({ parentState }) => {

    const t = useTranslate();
    const navigate = useNavigate();
    const [custodayArr, setCustodayArr] = useState<CustodayObj[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<number>(0);
    const [notFound, setNotFound] = useState<boolean>(false);
    // ****************************
    const [totalCount, setTotalCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 6;
    // ****************************
    useEffect(() => {
        const getCustoday = async () => {
            try {
                setIsError(false);
                setIsLoading(true);
                var count = await GetUserCustodaysTotalCount();
                setTotalCount(count.data);
                var data = await GetUserCustodaysPerPage(currentPage, itemsPerPage);
                if (data.status === 200) {
                    setIsError(false);
                    setIsLoading(false);
                    if (data.data.length > 0) {
                        setCustodayArr(data.data);
                        setNotFound(false);
                    } else {
                        setNotFound(true);
                    }
                } else {
                    setIsLoading(false);
                    setIsError(true);
                }
            } catch (error) {
                setIsLoading(false);
                setIsError(true);
            }
        }
        getCustoday();
    }, [parentState, currentPage])
    const handleSearchBtn = async () => {
        if (searchValue <= 0) {
            setCurrentPage(1);
        }
        var data = await GetCustodayByIdForEmployee(searchValue);
        if (data.status === 200) {
            const newArray: CustodayObj[] = [
                data.data
            ];
            setCustodayArr(newArray);
            setTotalCount(data.data.length);
        } else {
            setCurrentPage(1);
        }
    }
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };
    return (
        <>
            <Stack display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexDirection={'row'} p={5}>
                <Stack display={'flex'} justifyContent={'space-between'} alignItems={'end'} flexDirection={'row'}>
                    <InputGroup borderRadius={5} size="md">
                        <InputLeftElement
                            children={<Search2Icon color="gray.600" />}
                            cursor={'pointer'}
                            onClick={handleSearchBtn}
                            backgroundColor={'#3182ce'}
                        />
                        <Input type="text"
                            color="#949494" // This sets the text color
                            _placeholder={{ color: '#949494' }} // This sets the placeholder color
                            placeholder={t('text-search-custoday-number')}//"Search..." 
                            border="2px solid #3182ce"
                            pr={6}
                            fontSize={20}
                            onChange={(e) => setSearchValue(+e.target.value)} />
                        <InputRightAddon
                            p={0}
                            border="none"
                        >
                        </InputRightAddon>
                    </InputGroup>
                </Stack>
                <Button colorScheme='blue' variant='solid' onClick={() => navigate('/split-custody')}>
                    {t('text-split-custody')}
                </Button>
            </Stack>
            {
                isLoading && <MatrixLoading />
            }
            {
                notFound && <NotFound />
            }
            {
                isError && <NotFound />
            }
            {(!isLoading && !isError && !notFound && custodayArr.length > 0)
                &&
                <TableContainer>
                    <Table variant='striped' colorScheme='blue' mb={5}>
                        <Thead>
                            <Tr>
                                <Th textAlign={'center'}>{t('text-custody-number')}</Th>
                                <Th textAlign={'center'}>{t('text-date-custody-request')}</Th>
                                <Th textAlign={'center'}>{t('text-type-custody')}</Th>
                                <Th textAlign={'center'}>{t('text-return-date')}</Th>
                                <Th textAlign={'center'}>{t('text-custody-status')}</Th>
                                <Th textAlign={'center'}>{t('text-retrieval-pledge')}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                custodayArr.map((ele, index) => (
                                    <Tr key={index}>
                                        <Td textAlign={'center'}>{ele.id}</Td>
                                        <Td textAlign={'center'}>{ele.startDate?.toString().split('T')[0]}</Td>
                                        <Td textAlign={'center'}>{ele.type}</Td>
                                        <Td textAlign={'center'}>{ele.returnDate?.toString().split('T')[0]}</Td>
                                        <Td textAlign={'center'}>
                                            <LetterReceiptCustody info={ele} />
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <ReturnProcessModal info={ele} />
                                        </Td>
                                    </Tr>
                                ))
                            }
                        </Tbody>
                    </Table>
                    {
                        totalCount > itemsPerPage &&
                        <Pagination
                            totalPages={Math.ceil(totalCount / itemsPerPage)} // Calculate total pages
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    }
                </TableContainer>
            }
        </>
    )
}