import { Button, Icon, Stack, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslate } from '../../hooks/langs-hooks'
import { DragHandleIcon } from '@chakra-ui/icons';
import { GetUserCustodays } from '../../data/client/endpoints/Covenant/Covenant.endPoint';
import { useNavigate } from 'react-router-dom';
import { CustodayObj } from '../../types';
import { CustodayDetails } from './CustodayDetails';
import { MatrixLoading } from '../loader/MatrixLoading';
import { NotFound } from '../hr-components/NotFound';

export const SplitCustodyTable = () => {
    const t = useTranslate();
    const navigate = useNavigate();
    const [custodayArr, setCustodayArr] = useState<CustodayObj[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    useEffect(() => {
        const getCustoday = async () => {
            try {
                setIsError(false);
                setIsLoading(true);
                var data = await GetUserCustodays();
                if (data.status === 200) {
                    setIsError(false);
                    setIsLoading(false);
                    setCustodayArr(data.data);
                } else {
                    setIsError(true);
                    setIsLoading(false);
                }
            } catch (error) {
                setIsError(true);
                setIsLoading(false);
            }
        }
        getCustoday();
    }, [])
    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;
    const totalPages = Math.ceil(custodayArr.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;

    const currentItems = custodayArr.slice(startIndex, startIndex + itemsPerPage);

    const handlePrevClick = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleNextClick = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    return (
        <>
            {
                isLoading && <MatrixLoading />
            }
            {
                isError && <NotFound />
            }
            {
                currentItems.length > 0 &&
                <TableContainer>
                    <Table variant='striped' colorScheme='blue'>
                        <Thead>
                            <Tr>
                                <Th textAlign={'center'}>{t('text-custody-number')}</Th>
                                <Th textAlign={'center'}>{t('text-date-custody-request')}</Th>
                                <Th textAlign={'center'}>{t('text-type-custody')}</Th>
                                <Th textAlign={'center'}>{t('text-details')}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                currentItems.map((ele, index) => (
                                    <Tr key={index}>
                                        <Td textAlign={'center'}>{ele.id}</Td>
                                        <Td textAlign={'center'}>{ele.startDate?.toString().split('T')[0]}</Td>
                                        <Td textAlign={'center'}>{ele.type}</Td>
                                        <Td textAlign={'center'}>
                                            <CustodayDetails info={ele} />
                                        </Td>
                                    </Tr>
                                ))
                            }

                        </Tbody>
                    </Table>
                    {
                        currentItems.length > 0 &&
                        <Stack mb={5} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} flexDirection={'row'} mt={5}>
                            {
                                currentPage !== 1 &&
                                <Button colorScheme={'blue'} onClick={handlePrevClick} disabled={currentPage === 1}>
                                    Previous
                                </Button>
                            }
                            <Text> Page {currentPage} of {totalPages} </Text>
                            {
                                currentPage !== totalPages &&
                                <Button colorScheme={'blue'} onClick={handleNextClick} disabled={currentPage === totalPages}>
                                    Next
                                </Button>
                            }
                        </Stack>
                    }
                </TableContainer>
            }
            {/* <TableContainer>
                <Table variant='striped' colorScheme='blue'>
                    <Thead>
                        <Tr>
                            <Th textAlign={'center'}>{t('text-custody-number')}</Th>
                            <Th textAlign={'center'}>{t('text-date-custody-request')}</Th>
                            <Th textAlign={'center'}>{t('text-type-custody')}</Th>
                            <Th textAlign={'center'}>{t('text-details')}</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td textAlign={'center'}>1</Td>
                            <Td textAlign={'center'}>20/12/2025</Td>
                            <Td textAlign={'center'}>{t('text-sustainable')}</Td>
                            <Td textAlign={'center'}>
                                <Icon as={DragHandleIcon} boxSize={6} cursor={'pointer'} />
                            </Td>
                        </Tr>
                        <Tr>
                            <Td textAlign={'center'}>2</Td>
                            <Td textAlign={'center'}>21/04/2021</Td>
                            <Td textAlign={'center'}>{t('text-sustainable')}</Td>
                            <Td textAlign={'center'}>
                                <Icon as={DragHandleIcon} boxSize={6} cursor={'pointer'} />
                            </Td>
                        </Tr>
                        <Tr>
                            <Td textAlign={'center'}>3</Td>
                            <Td textAlign={'center'}>15/10/2022</Td>
                            <Td textAlign={'center'}>{t('text-sustainable')}</Td>
                            <Td textAlign={'center'}>
                                <Icon as={DragHandleIcon} boxSize={6} cursor={'pointer'} />
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer> */}
        </>
    )
}