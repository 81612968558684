import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  ModalBody,
  ModalContent,
  ModalFooter,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomModalHeader } from '.';
import { useMe } from '../../contexts/app-context';
import {
  addUsersToTask,
  deleteUserFromTask,
} from '../../data/client/endpoints/project.endpoints';
import { useTask } from '../../data/task';
import { useTranslate } from '../../hooks/langs-hooks';
import { useCustomMutation } from '../../hooks/use-custsom-mutation';
import { Id } from '../../types';
import { BASE_URL } from '../../utils/constants';
import ServerError from '../Error';
import { MultiSelectUsers } from '../Selects';
import Loader from '../loader/Loader';
import PageLoader from '../loader/page-loader';

interface MembersModalContentProps {
  users: { userId: number; userName: string; userImage: string | null }[];
  onClose: () => void;
  addUsersMutationFn: (data: {
    id: number;
    users: number[];
  }) => Promise<string>;
  deleteUserMutationFn: (data: {
    id: number;
    userId: number;
  }) => Promise<string>;
  title: string;
  queryToInvaildate?: any[];
  id: number;
  canDeleteUser?: boolean;
  canAddUser?: boolean;
  onSuccess?: () => void;
}

const MembersModalContent: React.FC<MembersModalContentProps> = ({
  users,
  title,
  addUsersMutationFn,
  onClose,
  queryToInvaildate,
  id,
  deleteUserMutationFn,
  canAddUser,
  canDeleteUser,
  onSuccess,
}) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const [unsavedUsers, setUnsavedUsers] = useState<number[]>([]);

  const addUsersMutation = useCustomMutation({
    mutationFn: addUsersMutationFn,
    queryToInvalidate: queryToInvaildate,
    onSuccess(data) {
      onSuccess?.();
      onClose();
    },
  });

  const deleteUserMutation = useCustomMutation({
    mutationFn: deleteUserMutationFn,
    queryToInvalidate: queryToInvaildate,
    onSuccess,
  });

  return (
    <>
      <CustomModalHeader title={title} />
      <ModalBody px={3}>
        <SimpleGrid columns={[1, 1, 1, 1, 2, 3]} gap={2} mb={3}>
          {users.map((u) => (
            <HStack key={u.userId} spacing={1}>
              <Avatar boxSize='2.5rem' src={BASE_URL + u.userImage} />
              <Box>
                <Text
                  cursor={'pointer'}
                  fontSize='lg'
                  fontWeight={400}
                  _hover={{ color: 'blue.400' }}
                  onClick={() => {
                    navigate(`/emp/${u.userId}/profile`);
                  }}
                >
                  {u.userName}
                </Text>
                {canDeleteUser && (
                  <Text
                    fontSize='sm'
                    fontWeight={400}
                    color='#FF3030'
                    textDecoration={'underline'}
                    cursor={'pointer'}
                    onClick={() => {
                      if (window.confirm(t('text-are-you-sure'))) {
                        deleteUserMutation.mutate({ id, userId: u.userId });
                      }
                    }}
                  >
                    {t('text-del')}
                  </Text>
                )}
              </Box>
            </HStack>
          ))}
        </SimpleGrid>
        {canAddUser && (
          <FormControl>
            <FormLabel fontWeight={500} fontSize={'lg'}>
              {t('text-add-members')}
            </FormLabel>
            <MultiSelectUsers
              onChange={(values: any) => {
                setUnsavedUsers(values.map((v: any) => v.value));
              }}
            />
          </FormControl>
        )}
      </ModalBody>
      <ModalFooter justifyContent={'center'}>
        {canAddUser && (
          <Button
            isDisabled={addUsersMutation.isLoading}
            onClick={() => addUsersMutation.mutate({ id, users: unsavedUsers })}
          >
            {addUsersMutation.isLoading ? <Loader size='md' /> : t('text-save')}
          </Button>
        )}
      </ModalFooter>
    </>
  );
};

export default MembersModalContent;

interface TaskMemberModalContentProps {
  taskId: Id;
  closeModal: () => void;
}

export const TaskMemberModalContent: React.FC<TaskMemberModalContentProps> = ({
  taskId,
  closeModal,
}) => {
  const t = useTranslate();
  const me = useMe();
  const { isLoading, task, isError, error } = useTask(taskId);
  const queryClient = useQueryClient();

  return (
    <ModalContent maxW={'xl'}>
      {isLoading ? (
        <PageLoader />
      ) : isError || !task ? (
        <ServerError error={error} />
      ) : (
        <MembersModalContent
          id={task.taskId}
          title={t('text-task-members')}
          onClose={closeModal}
          users={task.taskMembers}
          deleteUserMutationFn={deleteUserFromTask}
          addUsersMutationFn={addUsersToTask}
          canAddUser={me?.privileges?.addEmpToTask}
          canDeleteUser={me?.privileges?.deleteEmployee}
          onSuccess={() => {
            queryClient.invalidateQueries(['tasks', task.taskId]);
            queryClient.invalidateQueries(['project-tasks', task.projectId]);
          }}
        />
      )}
    </ModalContent>
  );
};
