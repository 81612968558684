const colors = {
  green: {
    '300': '#E6FFE2',
    '400': '#B2FFA6',
    '500': '#28B411',
    '600': '#3AC922',
  },
  yellow: {
    '400': '#FFEF98',
    '500': '#F6D523',
    '600': '#F6D523',
    '700': 'crimson',
  },
  gray: {
    '400': '#D7D7D7',
    '500': '#F8F8F8',
    '600': '#D7D7D7',
  },
  gray2: {
    '100': '#EFEFEF',
    '400': '#F8F8F8',
    '500': '#EFEFEF',
    '600': '#D7D7D7',
  },
  red: {
    '300': '#FFF2F2',
    '400': '#FFC0C0',
    '500': '#FF3030',
    '600': '#ED2121',
  },
  primary: {
    '100': '#ECF0FF',
    '200': '#001253',
    '300': '#6284FD',
    '500': '#2D5BFF',
    '400': '#1B4AF0',
    '600': '#002ED0',
    '700': '#002ED0',
  },
  secondary: {
    '100': '#F4EEFF',
    '200': '#C0A0FF',
    '300': '#4B01DC',
    '400': '#2C0182',
    '500': '#4C01AC',
    '600': '#4A00E0',
    '700': '#4C01AC',
  },
  'main-blue': '#010e40',
};

const theme = {
  colors,
  fonts: {},
  fontSizes: {
    h1: '3.5rem',
    h2: '3rem',
    h3: '2.5rem',
    h4: '2rem',
    h5: '1.5rem',
    h6: '1.25rem',
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
  },
  space: {
    1: '.5rem',
    2: '1rem',
    3: '1.5rem',
    4: '2rem',
    5: '2.5rem',
    6: '4rem',
    7: '5rem',
    9: '5.625rem',
    10: '7.5rem',
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: '4px',
        fontSize: 'lg',
        fontWeight: 600,
      },
      defaultProps: {
        colorScheme: 'primary',
      },
    },
    Modal: {
      parts: ['body'],
      baseStyle: {
        body: {
          px: 2,
        },
      },
    },
    Table: {
      defaultProps: {
        size: 'sm',
      },
    },
    FormLabel: {
      baseStyle: {
        mb: 1,
      },
    },
  },
};

export default theme;
