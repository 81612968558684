import { Button } from '@chakra-ui/react';
import React, { useState } from 'react';
import { gridIcon, tableIcon } from '../assests/icons';
import ServerError from '../components/Error';
import FilterBar from '../components/FilterBar';
import MainContainer from '../components/MainContainer';
import Loader from '../components/loader/Loader';
import TasksLists from '../components/task/TasksLists';
import { useMe } from '../contexts/app-context';
import { useMyProjects } from '../data/project';
import { useMytasks } from '../data/task';
import { useTranslate } from '../hooks/langs-hooks';
import { useFormData } from '../hooks/use-form-data-hook';
import { IMyTasksOptions } from '../types';
import { filterDateEnd, filterDateStart, filterDateStartTask } from '../utils/date-utils';
import { NavLink } from 'react-router-dom';

interface MyTasksProps { }

const MyTasks: React.FC<MyTasksProps> = ({ }) => {
  const me = useMe();
  const [displayType, setDisplayType] = useState('grid');
  const { projects } = useMyProjects();
  const {
    data: filter,
    register,
    setData,
  } = useFormData<IMyTasksOptions>({
    userid: me?.userId!,
    startTime: filterDateStartTask,
    finishTime: filterDateEnd,
  });
  const t = useTranslate();

  const { isError, isLoading, error, data } = useMytasks(filter);

  return (
    <MainContainer py={5}>
      <FilterBar
        title={t('text-my-tasks')}
        displayType={{
          activeType: displayType,
          types: [
            {
              id: 'grid',
              icon: gridIcon,
            },
            {
              id: 'table',
              icon: tableIcon,
            },
          ],
          changeDisplayType(type) {
            setDisplayType(type);
          },
        }}
      />

      <Button mb={5}>
        <NavLink to='/completed-tasks' className='link'>
          {t('text-completed-tasks-count')}
        </NavLink>
      </Button>




      {/* <Stack direction={['column', 'row']} spacing={2} mb={3} >
        <FlushedInput
          variant={'outline'}
          type='date'
          label={t('text-start-time')}
          maxW={'20rem'}
          {...register('startTime')}
        />
        <FlushedInput
          variant={'outline'}
          type='date'
          maxW={'20rem'}
          label={t('text-end-time')}
          {...register('finishTime')}
        />
        <FormControl maxW={'20rem'}>
          <FormLabel mb={0} fontWeight={600} fontSize='md'>
            {t('text-project')}
          </FormLabel>
          <Select
            value={filter.projectId}
            onChange={(e) => {
              const id = +e.target.value;
              setData((prev) => ({
                ...prev,
                projectId: id ? id : undefined,
              }));
            }}
          >
            <option value='0'>{t('text-all')}</option>
            {projects.map((p) => (
              <option key={p.projectId} value={p.projectId}>
                {p.projectName}
              </option>
            ))}
          </Select>
        </FormControl>
      </Stack> */}

      {isLoading ? (
        <Loader size='xl' />
      ) : isError ? (
        <ServerError error={error} />
      ) : (
        <TasksLists
          displayType={displayType}
          showClientDetails
          showProjectDetails
          {...data}
        />
      )}
    </MainContainer>
  );
};

export default MyTasks;
