import { HStack, Image, Text } from '@chakra-ui/react';
import { fileIcon, timeIcon } from '../assests/icons';
import { useTranslate } from '../hooks/langs-hooks';

interface FileCountProps {
  count: number;
}

export const FileCount: React.FC<FileCountProps> = ({ count }) => {
  const t = useTranslate();
  return (
    <HStack alignItems='center'>
      <Text fontWeight={400} fontSize='sm'>
        {count}
      </Text>
      <Image src={fileIcon} boxSize='4' />
    </HStack>
  );
};
export const TimeCount: React.FC<FileCountProps> = ({ count }) => {
  const t = useTranslate();
  return (
    <HStack alignItems='center' spacing='5px'>
      <Text fontWeight={400} fontSize='md'>
        {count} {t('text-days-left')}
      </Text>
      <Image src={timeIcon} boxSize='5' />
    </HStack>
  );
};
