import { useQuery } from '@tanstack/react-query';
import { useAppContext } from '../contexts/app-context';
import { getUserBranches } from './client/endpoints/user.endpoints';
 
export const useMyBranches = () => {
  
  const { user, setUser } = useAppContext();
  const userBranchesQuery = useQuery({
    queryKey: ['branches'],
    queryFn: () => getUserBranches(user?.userId ?? 0),
    onSuccess(data) {
      setUser((prev) => {
        if (!prev) return null;
        return {
          ...prev,
          branches: data,
        };
      });
    },
  //  enabled: true,

    // onError(data)
    // {
    //   setAllowedToFetch(true)
    //   debugger;
    //   console.log(data);
    // }
  });

  return userBranchesQuery;
};
