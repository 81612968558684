import {
  Box,
  Button,
  Divider,
  HStack,
  Image,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { closeIcon } from '../assests/icons';
import { useAppContext } from '../contexts/app-context';
import { useTranslate } from '../hooks/langs-hooks';
import { isStaff } from '../utils/priviliages';

interface Item {
  id: number;
  value: string;
}

interface FilterBarProps {
  title?: string;
  filter?: {
    activeFilter: Item;
    changeFilter: (item: Item) => void;
    items: Item[];
  };
  filterWithId?: {
    activeFilter: { id: number; value: string };
    changeFilter: (item: { id: number; value: string }) => void;
    items: { id: number; value: string }[];
    onDelete?: (id: number) => void;
  };
  displayType?: {
    activeType: string;
    changeDisplayType: (type: string) => void;
    types: {
      id: string;
      name?: string;
      icon: string;
    }[];
    children?: React.ReactNode;
  };
  children?: React.ReactNode;
}

const FilterBar: React.FC<FilterBarProps> = ({
  title,
  filter,
  displayType,
  children,
  filterWithId,
}) => {
  const t = useTranslate();
  const { user } = useAppContext();

  return (
    <>
      <HStack
        mb={!filterWithId ? '5' : ''}
        borderBottom={!filterWithId ? '0.5px solid #B3B3B7' : ''}
        mt={4}
      >
        {title && (
          <Text color='#001253' fontWeight={600} fontSize={'1.5rem'}>
            {title}
          </Text>
        )}
        {filter && (
          <HStack spacing={4} flexWrap={'wrap'}>
            {filter.items.map((item) => (
              <Button
                key={item.id}
                variant='unstyled'
                px={0}
                pb={2}
                borderRadius={0}
                isActive={filter.activeFilter.id === item.id}
                fontSize='sm'
                fontWeight={500}
                _active={{
                  color: '#2D5BFF',
                  borderBottom: '3px solid #2D5BFF',
                }}
                _hover={{ color: '#2D5BFF', borderBottom: '3px solid #2D5BFF' }}
                onClick={filter.changeFilter.bind(null, item)}
              >
                {t('text-' + item.value.toLowerCase())}
              </Button>
            ))}
          </HStack>
        )}
        {filterWithId && (
          <HStack spacing={2} flexWrap={'wrap'}>
            {filterWithId.items.map((item) => (
              <HStack
                key={item.id}
                className={
                  filterWithId.activeFilter.id === item.id ? 'active' : ''
                }
                px={2}
                alignItems={'center'}
                cursor={'pointer'}
                borderRadius={'38px'}
                fontWeight={600}
                sx={{
                  '&.active,&:hover': { bg: '#2D5BFF', color: '#fff' },
                  '&.active:not(:first-of-type)': { pe: 1 },
                  '&.active .close-icon': { display: 'block' },
                }}
                onClick={filterWithId.changeFilter.bind(null, item)}
              >
                <Text py='.3rem'>{item.value}</Text>
                {filterWithId.onDelete &&
                  item.id !== 0 &&
                  item.id !== 100 &&
                  isStaff(user) && (
                    <Box
                      className='close-icon'
                      display={'none'}
                      p={'.5rem'}
                      borderRadius={'50%'}
                      _hover={{
                        bg: 'blue.400',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        filterWithId.onDelete?.(item.id);
                      }}
                    >
                      <Image src={closeIcon} boxSize={'.8rem'} />
                    </Box>
                  )}
              </HStack>
            ))}
          </HStack>
        )}
        <Spacer />
        {displayType && (
          <HStack>
            {displayType.children}
            {displayType.types.map((type) => (
              <Button
                key={type.id}
                px={0}
                pb={2}
                borderRadius={0}
                isActive={displayType.activeType === type.id}
                fontSize='sm'
                bg='inherit'
                fontWeight={500}
                _active={{
                  color: '#2D5BFF',
                  borderBottom: '3px solid #2D5BFF',
                }}
                _hover={{ color: '#2D5BFF', borderBottom: '3px solid #2D5BFF' }}
                onClick={displayType.changeDisplayType.bind(null, type.id)}
              >
                <Image src={type.icon} boxSize={5} w='100%' />
                {type.name}
              </Button>
            ))}
          </HStack>
        )}
        {children}
      </HStack>
      {filterWithId && <Divider borderColor={'#B3B3B7'} my={1} mb={4} />}
    </>
  );
};

export default FilterBar;
