import {
  Avatar,
  Box,
  Button,
  CloseButton,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Progress,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  useIsFetching,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { Suspense, lazy, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { downIcon, notificationIcon, searchIcon } from '../../assests/icons';
import { useAppContext } from '../../contexts/app-context';
import { useMyBranches } from '../../data/branch';
import { deleteBranch } from '../../data/client/endpoints/shared.endpoints';
import { useTranslate } from '../../hooks/langs-hooks';
import useNotification from '../../hooks/use-notification-hook';
import { BASE_URL } from '../../utils/constants';
import ServerError, { handleError } from '../Error';
import MainContainer from '../MainContainer';
import Notifications from '../Notifications';
import Loader from '../loader/Loader';
import { CustomMenuItem } from '../menus';
import { CustomModal } from '../modals';

const NewBranchModalContent = lazy(
  () => import('../modals/NewBranchModalContent')
);
const SearchModal = lazy(() => import('../modals/SearchModal'));

interface HeaderProps {}

const Header: React.FC<HeaderProps> = ({}) => {
  const notify = useNotification();
  const [showSearchModal, setShowSearchModal] = useState(false);
  const newBranchModal = useDisclosure();
  const t = useTranslate();
  const { user, branch: currentBranch, setBranch } = useAppContext();
  let { pathname } = useLocation();
  pathname = 'Dashboard' + pathname;

  const branchesQuery = useMyBranches();

  const queryClient = useQueryClient();
  const deleteBranchMutation = useMutation({
    mutationFn: deleteBranch,
    onSuccess(data) {
      queryClient.invalidateQueries(['branches']);
      notify.success(t('text-del-success'));
    },
    onError(error) {
      notify.error(t(handleError(error)));
    },
  });

  const openSearchModal = () => setShowSearchModal(true);
  const closeSearchModal = () => setShowSearchModal(false);

  const isFetching = useIsFetching();

  return (
    <>
      {/* new branch modal */}
      {user?.privileges?.addBranch && (
        <CustomModal
          isOpen={newBranchModal.isOpen}
          onClose={newBranchModal.onClose}
        >
          <Suspense>
            <NewBranchModalContent onClose={newBranchModal.onClose} />
          </Suspense>
        </CustomModal>
      )}
      <Box
        bg='#fff'
        boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
        pos='relative'
      >
        <MainContainer py={2}>
          <HStack spacing={2} flexWrap={'wrap'}>
            <Text color='#001253' fontWeight={500} me={1} className='hide-sm'>
              {pathname}
            </Text>
            {user?.privileges?.viewEmpKPI && (
              <HStack
                w='20rem'
                borderBottom='0.494588px solid #B3B3B7'
                py={1}
                cursor='pointer'
                onClick={openSearchModal}
              >
                <Image src={searchIcon} boxSize='6' />
                <Text color='#001253' fontWeight={500}>
                  {t('text-search')}
                </Text>
              </HStack>
            )}
            <Spacer />
            {/* branches */}
            {branchesQuery.data && (
              <Box>
                <Menu>
                  <MenuButton
                    minW='15rem'
                    as={Button}
                    rightIcon={
                      <Image
                        src={downIcon}
                        filter='brightness(0) saturate(100%) invert(0%) sepia(84%) saturate(7436%) hue-rotate(328deg) brightness(114%) contrast(114%)'
                      />
                    }
                    colorScheme=''
                    bg='#F8F8F8'
                    color='#8A8A8A'
                    border={'1px solid #000'}
                  >
                    {currentBranch?.bramchName ?? t('text-branch')}
                  </MenuButton>
                  <MenuList minW='15rem'>
                    {branchesQuery.isLoading ? (
                      <Loader size='md' />
                    ) : branchesQuery.isError ? (
                      <ServerError error={branchesQuery.error} />
                    ) : (
                      branchesQuery.data?.map((branch) => (
                        <CustomMenuItem
                          px={1}
                          key={branch.branchId}
                          onClick={() => setBranch(branch)}
                          justifyContent={'space-between'}
                        >
                          {branch.bramchName}
                          {user?.privileges?.deleteBranch && (
                            <CloseButton
                              as={'span'}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (window.confirm(t('text-are-you-sure'))) {
                                  deleteBranchMutation.mutate(branch.branchId);
                                }
                              }}
                            />
                          )}
                        </CustomMenuItem>
                      ))
                    )}
                    {user?.privileges?.addBranch && (
                      <MenuItem
                        color='#2D5BFF'
                        textAlign={'center'}
                        fontWeight={600}
                        justifyContent={'center'}
                        onClick={newBranchModal.onOpen}
                      >
                        {t('text-new-branch')}
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>
              </Box>
            )}
            {/* notifications */}
            <Box>
              <Menu placement='bottom'>
                <MenuButton
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Image src={notificationIcon} boxSize={'1.5rem'} />
                </MenuButton>
                <MenuList
                  bg='#fff'
                  py={2}
                  px={1}
                  boxShadow={
                    '0px 4px 8px -2px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.06)'
                  }
                  borderRadius={'8px 0px 0px 8px'}
                  maxW='100%'
                  w='25rem'
                >
                  <Notifications />
                </MenuList>
              </Menu>
            </Box>
            <HStack as={Link} to={`/my-profile`}>
              <Avatar size='sm' src={BASE_URL + user?.userImage} />
              <Text
                color='#000'
                fontSize='md'
                fontWeight={500}
                className='hide-sm'
              >
                {user?.userName}
              </Text>
            </HStack>
          </HStack>
          {user?.privileges?.viewEmpKPI && (
            <Suspense>
              <SearchModal show={showSearchModal} onClose={closeSearchModal} />
            </Suspense>
          )}
        </MainContainer>
      </Box>
      <Progress size='xs' bg='transparent' isIndeterminate={isFetching > 0} />
    </>
  );
};

export default Header;
