import {
  Badge,
  Box,
  Button,
  Grid,
  HStack,
  ModalBody,
  ModalContent,
  ModalFooter,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { CustomModalHeader } from '../modals';
import {
  addTaskScope,
  getResourcesByName,
  getTaskResourcesByName,
} from '../../data/client/endpoints/repo.endpoints';
import { useTranslate } from '../../hooks/langs-hooks';
import { useCustomMutation } from '../../hooks/use-custsom-mutation';
import { ICreateTaskProjectScopeResouce, SelectedResource } from '../../types';
import ServerError from '../Error';
import EditableResourceCard from '../cards/EditableResourceCard';
import SelelctableResourceCard from '../cards/SelectableResourceCard';
import { SearchInput } from '../inputs-with-left-elements';
import Loader from '../loader/Loader';

interface TaskProjectScopeModalcontentProps {
  onClose: () => void;
  projectId: number;
  taskId:number;
}

const TaskProjectScopeModalcontent: React.FC<TaskProjectScopeModalcontentProps> = ({
  
  onClose,
  projectId, taskId
}) => {
  const t = useTranslate();
  const [mode, setMode] = useState<'select' | 'confirm'>('select');
  const [resourceName, setResourceName] = useState('');
  const [selectedResources, setSelectedResources] = useState<
    SelectedResource[]
  >([]);

  const resourcesQuery = useQuery({
    queryKey: ['Taskresources-by-name', resourceName],
    queryFn: () =>
      getTaskResourcesByName({ page: 1, count: 20, search: resourceName,userId:projectId }),
  });

  const projectScopeMutation = useCustomMutation({
    mutationFn: addTaskScope,
    queryToInvalidate: ['task-resources'],
    onSuccess: onClose,
  });

  const isResourceSelected = (id?: number) => {
    return selectedResources.find((r) => r.resourceId === id) ? true : false;
  };

  const handleSubmitClick = () => {
    if (mode === 'select') {
      return setMode('confirm');
    }

    const scopeData: ICreateTaskProjectScopeResouce[] = selectedResources.map(
      
      (r) => {
        return {
          projectScopeQuantity: r.qty,
          resourceId: r.resourceId!,
          projectId,
          taskId
        };
      }
    );

    projectScopeMutation.mutate(scopeData);
  };

  const totalPrice = selectedResources.reduce(
    (prev, cur) => prev + cur.qty * cur.sellingPrice,
    0
  );

  return ( 
    <ModalContent my={3} maxW={'90%'} bg='#F8F8F8'>
      <CustomModalHeader title={t('text-prj-resources-scope')} />
      <ModalBody pt={0}>
        <Text color={'#7C7B7B'} fontWeight={500} fontSize={'h5'}>
          {t('text-prj-resources-scope-text')}
        </Text>
        <HStack my={3} bg='#fff' w='100%' p={3} color={'#B3B3B7'}>
          <SearchInput
            placeholder={t('text-prj-resources-scope-search')}
            maxW={'500px'}
            value={resourceName}
            onChange={(e) => setResourceName(e.target.value)}
          />

          {/* <FormControl display={'flex'} alignItems={'center'} gap={1}>
            <Checkbox size={'lg'} />
            <FormLabel mb={0}>{t('text-material-not-affiliated')}</FormLabel>
          </FormControl> */}
        </HStack>
        <Grid gap={2} templateColumns={mode === 'select' ? '2fr 1fr' : '1'}>
          {mode === 'select' &&
            (resourcesQuery.isLoading ? (
              <Loader size='xl' />
            ) : resourcesQuery.isError ? (
              <ServerError error={resourcesQuery.error} />
            ) : (
              <SimpleGrid
                bg='#fff'
                gap={2}
                columns={[1, 1, 1, 2]}
                py={'10px'}
                pr={'10px'}
                maxH={'80vh'}
                overflow={'auto'}
              >
                {  
              
                resourcesQuery.data.resources.map((r) => ( 
                  <SelelctableResourceCard
                    key={r.resourceId}
                    resource={r}
                    isSelected={isResourceSelected(r.resourceId)}
                    onSelectToggle={() => {
                      setSelectedResources((prev) => {
                
                        const newSelectedResources = prev.filter(
                          resource => resource.resourceId !== r.resourceId
                        );

                        newSelectedResources.push({ ...r, qty: 0 });

                        return newSelectedResources;
                      });
                    }}
                  />
                ))}
              </SimpleGrid>
            ))}

          <VStack
            spacing={3}
            maxH={'80vh'}
            overflow={'auto'}
            bg='#fff'
            p={mode === 'confirm' ? 3 : 0}
          >
            {mode === 'confirm' && (
              <HStack w='100% '>
                <Text fontWeight={700} fontSize={'1.8rem'}>
                  {t('text-selected-mat-summary')}
                </Text>
                <Badge
                  bg='#ECF0FF'
                  color='#2D5BFF'
                  fontSize={'xl'}
                  fontWeight={700}
                  borderRadius={'6px'}
                >
                  {selectedResources.length}
                </Badge>
                <Spacer />
                <Box>
                  <Text color='#5C5C5C' fontWeight={400} fontSize={'h6'}>
                    {t('text-estimated-price')}
                  </Text>
                  <Text
                    color='#2D5BFF'
                    fontSize={'h3'}
                    fontWeight={700}
                    textAlign={'center'}
                  >
                    {totalPrice}
                  </Text>
                </Box>
              </HStack>
            )}
            {selectedResources.map((r) => (
              <EditableResourceCard
                key={r.resourceId}
                resource={r}
                onQuantityChange={(resourceId, qty) => {
                  setSelectedResources((prev) => {
                    const resourceIndex = selectedResources.findIndex(
                      (r) => r.resourceId === resourceId
                    );
                    const newSelectedResources = [...prev];
                    if (resourceIndex !== -1) {
                      newSelectedResources[resourceIndex].qty = qty;
                    }

                    return newSelectedResources;
                  });
                }}
              />
            ))}
          </VStack>
        </Grid>
      </ModalBody>
      <ModalFooter justifyContent={'flex-end'}>
        <Button
          onClick={handleSubmitClick}
          isLoading={projectScopeMutation.isLoading}
        >
          {mode === 'select' ? t('text-next') : t('text-confirm')}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

export default TaskProjectScopeModalcontent;
