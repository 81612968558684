import {
  ICreateProject,
  ICreateProjectScopeResouce,
  ICreateTask,
  IDepartment,
  IProject,
  IProjectDepartment,
  IProjectType,
  ITasksLists,
} from '../../../types';
import { BASE_URL } from '../../../utils/constants';
import api from '../http-client';
import { getAuthToken } from '../../../utils/storage';
export const createProject = async (project: ICreateProject) => {
  const fd = new FormData();
  fd.set('ProjectName', project.ProjectName);
  fd.set('ProjectDescription', project.ProjectDescription);
  fd.set('ClientId', project.ClientId.toString());
  fd.set('BranchId', project.BranchId.toString());
  fd.set('TypeId', project.TypeId.toString());
  fd.set('ProjectImage', project.ProjectImage!);

  project.Files.forEach((file) => {
    fd.append('Files', file);
  });

  const { data: projectId } = await api.post<string>(
    BASE_URL+ `/api/Projects/Insert/Project`,
    fd,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': getAuthToken()
      },
    }
  );

  const res = await api.post<string>(
    BASE_URL+`/api/Projects/Insert/Project/List/Members/Departments`,
    {
      projectId,
      heads: project.Heads.map((userId) => {
        return {
          userId,
        };
      }),
      department: project.Department.map((d) => {
        return {
          ...d,
          projectId,
        };
      }),
    }
  );

  return {
    projectId,
    data: res.data,
  };
};

export const deleteProject = async (projectId: number | string) => {
  const res = await api.delete<any>(
    BASE_URL+   `/api/Projects/Delete/Project/${projectId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data;
};

export const getProjectsByStatus = async (data: {
  userId: number;
  projectStatus: string;
  branchId: number;
  projectName: string;
}) => {
  const res = await api.post<IProject[]>(
    BASE_URL+ `/api/Projects/Select/All/Project/By/PojectStatus/Without/Date`,
    data
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  return res.data;
};

export const getProjectsByStatusAndDate = async (data: {
  userId: number;
  projectStatus: string;
  projectDateTime: Date;
  branchId: number;
}) => {
  const res = await api.post<IProject[]>(
    BASE_URL+ `/api/Projects/Select/All/Project/By/PojectStatus`,
    data
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  return res.data;
};

export const fetchProjectTasks = async (
  projectId: number | string,
  departmentId: number = 100
) => {
  const { data } = await api.get<ITasksLists>(
    BASE_URL+  `/api/Projects/Select/Project/Tasks/${projectId}/${departmentId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  console.log('project tasks', data);
  return data;
};

export const createTask = async (task: ICreateTask) => {
 
  const fd = new FormData();

  fd.set('TaskName', task.TaskName);
  fd.set('TaskDescription', task.TaskDescription);
  fd.set('TaskStartTime', task.TaskStartTime);
  fd.set('TaskFinishTime', task.TaskFinishTime);
  fd.set('TaskStatus', task.TaskStatus.toString());
  fd.set('ProjectId', task.ProjectId);
  fd.set('DepartmentId', task.DepartmentId);
  fd.set('FinishTime', task.finshtimeExact);

  if (task.TaskImage) {
    fd.set('TaskImage', task.TaskImage);
  }

  task.Files.forEach((file) => {
    fd.append('Files', file);
  });

  const { data: taskId } = await api.post<string>(
    BASE_URL+  `/api/Projects/Insert/Task`,
    fd,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': getAuthToken()
      },
    }
  );

  const usersIds = task.UserId.map((id) => {
    return {
      userId: id,
      taskId,
      assessementMark: false,
    };
  });

  const res = await api.post<string>(
    BASE_URL+`/api/Projects/Insert/List/Of/Empioyee/In/Task`,
    usersIds
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  return {
    taskId,
    data: res.data,
  };
};

export const AddProjectResourceScope = async (client: ICreateProjectScopeResouce) => {
 
  const fd = new FormData();
 
  client.Files?.forEach((file) => {
    fd.append('Files', file);
  });
  Object.entries(client).forEach(([key, value]) => {
   
    if(key!="Files")
    fd.set(key, value);
  });

  const { data } = await api.post<string>(BASE_URL+`/api/StoreHouse/AddProjectResourceScope`, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': getAuthToken(),
    },
  });

  return data;
};
export const deleteTask = async (taskId: string | number) => {
  const res = await api.delete<string>(BASE_URL+`/api/Projects/Delete/Task/${taskId}`, {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return res.data;
};

export const addDepartmentsToProject = async ({
  departments,
  projectId,
}: {
  departments: IProjectDepartment[];
  projectId: number;
}) => {
  const data = departments.map((d) => {
    return {
      ...d,
      projectId,
    };
  });

  const res = await api.post<string>(
    BASE_URL+ `/api/Projects/Insert/List/Of/Department/In/Project`,
    data
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  return res.data;
};

export const getProject = async (projectId: number | string) => {
  const res = await api.get<[IProject]>(
    BASE_URL+`/api/Projects/Select/Project/${projectId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data[0];
};

export const deleteProjectFile = async (fileId: number | string) => {
  const res = await api.delete<string>(
    `/api/Projects/Delete/File/From/Project/${fileId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data;
};

export const deleteTaskFile = async (fileId: number | string) => {
  const res = await api.delete<string>(
    BASE_URL+  `/api/Projects/Delete/File/From/Task/${fileId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data;
};

export const getProjectDepartments = async (projectId: number | string) => {
  const res = await api.get<IDepartment[]>(
    BASE_URL+  `/api/Projects/Select/Project/Department/${projectId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data;
};

export const updateProject = async ({
  coverImage,
  descriptoin,
  name,
  unsavedFiles,
  projectId,
}: {
  projectId: number | string;
  unsavedFiles: File[];
  name: string;
  descriptoin: string;
  coverImage: File | null;
}) => {
  const patchData = [
    {
      op: 'replace',
      path: '/projectName',
      value: name,
    },
    {
      op: 'replace',
      path: '/projectDescription',
      value: descriptoin,
    },
  ];

  const res1 = await api.patch<string>(
    BASE_URL+  `/api/Projects/Update/Project/Data/${projectId}`,
    patchData
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  if (coverImage) {
    const fd = new FormData();
    fd.set('Files', coverImage);
    const res = await api.post<string>(
      BASE_URL+`/api/Projects/Upload/Project/Image/${projectId}`,
      fd,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': getAuthToken()
        },
      }
    );
  }

  if (unsavedFiles.length) {
    const fd = new FormData();
    fd.set('ProjectId', projectId.toString());
    unsavedFiles.map((file) => {
      fd.append('Files', file);
    });
    const res = await api.post<string>(
      BASE_URL+  `/api/Projects/Insert/List/Of/Files/In/Project`,
      fd,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': getAuthToken()
        },
      }
    );
  }

  return {};
};

export const updateTask = async ({
  coverImage,
  dsecription,
  name,
  unsavedFiles,
  taskId,
  status,
  finishTime,
  startTime,
  finshtimeExact
}: {
  taskId: number | string;
  unsavedFiles: File[];
  name: string;
  dsecription: string;
  startTime: string;
  finishTime: string;
  finshtimeExact: string;

  coverImage: File | null;
  status: boolean;
}) => {
  const fd = new FormData();
  debugger;
  if (name) fd.set('TaskName', name);
  if (dsecription) fd.set('TaskDescription', dsecription);
  if (startTime) fd.set('TaskStartTime', startTime);
  if (finishTime) fd.set('TaskFinishTime', finishTime);
  if (coverImage) fd.set('TaskImage', coverImage);
  if (finshtimeExact) 
    
    fd.set('FinishTime', finshtimeExact);
    debugger;
    
    unsavedFiles.map((file) => {
      fd.append('Files', file);
    });
  fd.set('TaskStatus', status?.toString());

  const res = await api.patch(
    BASE_URL+  `/api/Projects/Update/Task/with/Image/${taskId}`,
    fd,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': getAuthToken()
      },
    }
  );

  if (unsavedFiles.length) {
    const fd = new FormData();
    fd.set('ProjectId', taskId.toString());
    unsavedFiles.map((file) => {
      fd.append('Files', file);
    });
    const res = await api.post<string>(
      BASE_URL+ `/api/Projects/Insert/List/Of/Files/In/Task`,
      fd,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': getAuthToken()
        },
      }
    );
  }

  return {};
};

export const deleteProjectDepartment = async ({
  projectId,
  departmentId,
}: {
  projectId: number | string;
  departmentId: number | string;
}) => {
  const res = await api.delete<string>(
    BASE_URL+ `/api/Projects/Delete/Department/From/Project/${projectId}/${departmentId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  return res.data;
};

export const addUsersToProject = async ({
  id: projectId,
  users,
}: {
  id: number;
  users: number[];
}) => {
  const data = users.map((id) => {
    return {
      userId: id,
      projectId,
    };
  });
  const res = await api.post<string>(
    BASE_URL+  `/api/Projects/Insert/List/Of/Heads/In/Project`,
    data
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data;
};

export const addUsersToTask = async ({
  id: taskId,
  users,
}: {
  id: number;
  users: number[];
}) => {
  const data = users.map((id) => {
    return {
      userId: id,
      taskId,
      assessementMark: false,
    };
  });
  const res = await api.post<string>(
    BASE_URL+ `/api/Projects/Insert/List/Of/Empioyee/In/Task`,
    data
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  console.log(res.data);
  return res.data;
};

export const deleteUserFromProject = async ({
  id: projectId,
  userId,
}: {
  id: number;
  userId: number;
}) => {
  const res = await api.delete<string>(
    BASE_URL+  `/api/Projects/Delete/Member/From/Project/${projectId}/${userId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  return res.data;
};

export const deleteUserFromTask = async ({
  id: taskId,
  userId,
}: {
  id: number;
  userId: number;
}) => {
  const res = await api.delete<string>(
    BASE_URL+ `/api/Projects/Delete/Member/From/Task/${taskId}/${userId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  return res.data;
};

export const addTaskComment = async (data: {
  commentText: string;
  taskId: number;
  userId: number;
}) => {
  const res = await api.post<string>(BASE_URL+`/api/Projects/Insert/Task/Comment`, data, {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return res.data;
};

export const getTaskClientComments = async ({
  taskId,
}: {
  taskId: number | string;
}) => {
  const res = await api.get<
    {
      clientCommentId: number;
      commentText: string;
      commentDate: string;
      commentFile: string;
      clientName: string;
    }[]
  >(BASE_URL+`/api/Projects/Select/Client/Comments/${taskId}`, {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return res.data;
};

export const addClientCommentOnTask = async (data: {
  CommentText?: string;
  CommentFile?: File;
  CommentDate?: string;
  ClientId: string;
  TaskId: string;
}) => {
  const fd = new FormData();
  Object.keys(data).forEach((key) => {
    // @ts-ignore
    fd.set(key, data[key]);
  });

  const res = await api.post<string>(
    BASE_URL+ `/api/Projects/Insert/Client/Comment`,
    fd,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': getAuthToken()
      },
    }
  );
  return res.data;
};

export const deleteTaskClientComment = async (commentId: number | string) => {
  const res = await api.delete<string>(
    BASE_URL+   `/api/Projects/Delete/Client/Comment/${commentId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data;
};

export const getTaskComments = async (taskId: number | string) => {
  const { data } = await api.get<
    {
      commentId: number;
      commentText: string;
      taskId: number;
      taskName: string;
      userId: number;
      userName: string;
      commmentDate: string;
    }[]
  >(BASE_URL+`/api/Projects/Task/Comments/${taskId}`, {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return data;
};

export const getProjectTypes = async () => {
  const { data } = await api.get<IProjectType[]>(
    BASE_URL+ `/api/Projects/Select/All/Project/Type`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  } );
  return data;
};

export const addProjectType = async ({
  typeName,
  typeDescription,
  typeId = 0,
}: IProjectType) => {
  const { data } = await api.post<string>(BASE_URL+`/api/Projects/Insert/Project/Type`, {
    typeName,
    typeDescription,
  }, {
    headers: {
    Authorization: getAuthToken(),
  }
});

  return data;
};

export const editProjectType = async (type: IProjectType) => {
  const data = Object.keys(type).map((key) => {
    return {
      path: `/${key}`,
      operation: 'replace',
      value: type[key as 'typeId'],
    };
  });
  const res = await api.patch<string>(
    BASE_URL+ `/api/Projects/Update/Project/Type/Data/${type.typeId}`,
    data
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data;
};

export const deleteProjectType = async (typeId: number | string) => {
  const { data } = await api.delete<string>(
    BASE_URL+ `/api/Projects/Delete/Project/Type/${typeId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  return data;
};
