import { DragHandleIcon, Search2Icon } from '@chakra-ui/icons'
import { Avatar, Badge, Button, Heading, Input, InputGroup, InputLeftElement, InputRightAddon, Select, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslate } from '../../hooks/langs-hooks'
import { useNavigate } from 'react-router-dom'
import { CustodayAppropriation } from './CustodayAppropriation'
import { GetAllCustodys, GetAllCustodysPerPage, GetCustodayById, GetCustodaysTotalCount } from '../../data/client/endpoints/Covenant/Covenant.endPoint'
import { CustodayApproveObj } from '../../types'
import { MatrixLoading } from '../loader/MatrixLoading'
import { NotFound } from '../hr-components/NotFound'
import { Pagination } from '../hr-components/Pagination'

export const AppropriationsTable = () => {
    const t = useTranslate();
    const navigate = useNavigate();
    const [approveRequestArr, setApproveRequestArr] = useState<CustodayApproveObj[]>([]);
    const [details, setDetails] = useState<CustodayApproveObj>();
    const [searchValue, setSearchValue] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [notFound, setNotFound] = useState<boolean>(false);
    // ****************************
    const [totalCount, setTotalCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isNew, setIsNew] = useState<Boolean>(false);
    const itemsPerPage = 6;
    // ****************************
    useEffect(() => {
        var custodays = async () => {
            try {
                setIsError(false)
                setIsLoading(true);
                var count = await GetCustodaysTotalCount();
                setTotalCount(count.data);
                var data = await GetAllCustodysPerPage(currentPage, itemsPerPage);
                if (data.status === 200) {
                    setIsLoading(false);
                    if (data.data.length > 0) {
                        setApproveRequestArr(data.data);
                        setNotFound(false);
                    } else {
                        setNotFound(true);
                    }
                } else {
                    setIsLoading(false);
                    setIsError(true);
                }
            } catch (error) {
                setIsLoading(false);
                setIsError(true);
            }
        }
        custodays();
    }, [currentPage,isNew])
    const handleSearchBtn = async () => {
        if (searchValue <= 0) {
            try {
                setIsError(false)
                setIsLoading(true);
                var count = await GetCustodaysTotalCount();
                setTotalCount(count.data);
                var data = await GetAllCustodysPerPage(currentPage, itemsPerPage);
                if (data.status === 200) {
                    setIsLoading(false);
                    if (data.data.length > 0) {
                        setApproveRequestArr(data.data);
                        setNotFound(false);
                    } else {
                        setNotFound(true);
                    }
                } else {
                    setIsLoading(false);
                    setIsError(true);
                }
            } catch (error) {
                setIsLoading(false);
                setIsError(true);
            }
            return;
        }
        var data = await GetCustodayById(searchValue);
        if (data.status === 200) {
            const newArray: CustodayApproveObj[] = [
                data.data
            ];
            setApproveRequestArr(newArray);
            setTotalCount(data.data.length)
        } else {
            setCurrentPage(1);
        }
    }
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };
    const handleApproveCustoday = () =>{
        setIsNew(!isNew)
    }
    return (
        <>
            <Stack display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexDirection={'row'} p={5}>
                <Stack display={'flex'} justifyContent={'space-between'} alignItems={'end'} flexDirection={'row'}>
                    <InputGroup borderRadius={5} size="md">
                        <InputLeftElement
                            children={<Search2Icon color="gray.600" />}
                            cursor={'pointer'}
                            onClick={handleSearchBtn}
                            backgroundColor={'#3182ce'}
                        />
                        <Input type="text"
                            color="#949494"
                            _placeholder={{ color: '#949494' }}
                            placeholder={t('text-search-custoday-number')}
                            border="2px solid #3182ce"
                            pr={6}
                            fontSize={20}
                            onChange={(e) => setSearchValue(+e.target.value)} />
                        <InputRightAddon
                            p={0}
                            border="none"
                        >
                        </InputRightAddon>
                    </InputGroup>
                </Stack>
                {/* <Select placeholder={t('text-type-accreditation')} width={'auto'}>
                    <option value={0}>{t('text-custody-withdrawal-requests')}</option>
                    <option value={1}>{t('text-material-disbursement-requests')}</option>
                </Select> */}
            </Stack>
            {
                isLoading && <MatrixLoading />
            }
            {
                notFound && <NotFound />
            }
            {
                isError && <NotFound />
            }
            {
                (!isLoading && !isError && !notFound) &&
                <TableContainer>
                    {
                        approveRequestArr &&
                        <>
                            <Table variant='striped' colorScheme='blue' mb={5}>
                                <Thead>
                                    <Tr>
                                        <Th textAlign={'center'}>{t('text-employee-photo')}</Th>
                                        <Th textAlign={'center'}>{t('text-userName')}</Th>
                                        {/* <Th textAlign={'center'}>{t('text-type-accreditation')}</Th> */}
                                        <Th textAlign={'center'}>{t('text-status')}</Th>
                                        <Th textAlign={'center'}>{t('text-details')}</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        approveRequestArr.map((ele, index) => (
                                            <Tr key={index}>
                                                <Td textAlign={'center'}><Avatar borderRadius={'20%'} name={ele.userName} src={ele.userImage ?? 'https://res.cloudinary.com/newbookify/image/upload/v1716961436/%D8%A7%D9%81%D8%AA%D8%A7%D8%B1%D9%A4_bi1szj.png'} /></Td>
                                                <Td textAlign={'center'}><Heading as='h5' size='sm'>{ele.userName}</Heading></Td>
                                                {/* <Td textAlign={'center'}><Heading as='h5' size='sm'>{t('text-custoday-request')}</Heading></Td> */}
                                                <Td textAlign={'center'}><Badge colorScheme={ele.isAccepted === true ? 'green' : ele.isAccepted === false ? 'red' : 'gray'}><Heading as='h5' size='sm'>{ele.isAccepted === true ? t('text-agree') : ele.isAccepted === false ? t('text-refuse') : t('text-hanging')}</Heading> </Badge></Td>
                                                <Td textAlign={'center'}>
                                                    <CustodayAppropriation info={ele} onDataFromChild={handleApproveCustoday} />
                                                </Td>
                                            </Tr>
                                        ))
                                    }
                                </Tbody>
                            </Table>
                            {
                                totalCount > itemsPerPage &&
                                <Pagination
                                    totalPages={Math.ceil(totalCount / itemsPerPage)} // Calculate total pages
                                    currentPage={currentPage}
                                    onPageChange={handlePageChange}
                                />
                            }
                        </>
                    }
                </TableContainer>
            }
        </>
    )
}