import { DragHandleIcon } from '@chakra-ui/icons'
import { Avatar, Button, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useTranslate } from '../../hooks/langs-hooks';
import { CustodayApproveObj } from '../../types';
import { CustodyRejection, CustodysAdoption } from '../../data/client/endpoints/Covenant/Covenant.endPoint';
import useNotification from '../../hooks/use-notification-hook';

interface CustodayAppropriationProps {
    info?: CustodayApproveObj;
    onDataFromChild: () => void;
  }

export const CustodayAppropriation: React.FC<CustodayAppropriationProps> = ({ info, onDataFromChild }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isRefuseLoading, setIsRefuseLoading] = useState<boolean>(false);
    const t = useTranslate();
    const notify = useNotification();
    const handleSaveBtn = async () => {
        try {
            if (info?.id !== undefined) {
                setIsLoading(true);
                var data = await CustodysAdoption(info?.id);
                if (data.status === 200) {
                    setIsLoading(false);
                    notify.success("success operation");
                    onDataFromChild();
                    onClose();
                } else {
                    setIsLoading(false);
                    notify.error(data.response.data);
                }
            }
        } catch (error) {
            setIsLoading(false);
            notify.error("server error");
        }
    }
    const handleRefuseBtn = async () => {
        try {
            if (info?.id !== undefined) {
                setIsRefuseLoading(true);
                var data = await CustodyRejection(info?.id);
                if (data.status === 200) {
                    setIsRefuseLoading(false);
                    notify.success("success operation");
                    onDataFromChild();
                    onClose();
                } else {
                    setIsRefuseLoading(false);
                    notify.error(data.response.data);
                }
            }

        } catch (error) {
            setIsRefuseLoading(false);
            notify.error("server error");
        }
    }
    return (
        <>
            <DragHandleIcon boxSize={6} cursor={'pointer'} onClick={onOpen} />
            <Modal isOpen={isOpen} onClose={onClose} size={'4xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Heading>{t('text-accreditation-request')}</Heading>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <TableContainer mb={5}>
                            <Table variant='striped' colorScheme='blue'>
                                <Tbody>
                                    <Tr>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {t('text-userName')}
                                            </Heading>
                                        </Td>
                                        <Td textAlign={'center'} display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'row'}>
                                            <Avatar borderRadius={"10px"} name={info?.userName} src={info?.userImage ?? 'https://res.cloudinary.com/newbookify/image/upload/v1716961436/%D8%A7%D9%81%D8%AA%D8%A7%D8%B1%D9%A4_bi1szj.png'} />
                                            <Heading as='h5' size='sm'>
                                                {info?.userName}
                                            </Heading>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {t('text-prj-num')}
                                            </Heading>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {info?.proNo}
                                            </Heading>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {t('text-date-application')}
                                            </Heading>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {info?.startDate?.toString().split('T')[0]}
                                            </Heading>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {t('text-custody-number')}
                                            </Heading>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {info?.id}
                                            </Heading>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {t('text-type-custody')}
                                            </Heading>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {info?.type}
                                            </Heading>
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                        <Heading as='h3' size='lg' mb={5} mx={3}>
                            {t('text-items')}
                        </Heading>
                        <TableContainer mb={8}>
                            <Table variant='simple'>
                                <Thead bgColor={'#f0f0f0'}>
                                    <Tr>
                                        {/* <Th><Checkbox colorScheme='red' defaultChecked>
                                        </Checkbox></Th> */}
                                        <Th textAlign={'center'}>{t('text-item-no')}</Th>
                                        <Th textAlign={'center'}>{t('text-item-name')}</Th>
                                        {/* <Th textAlign={'center'}>{t('text-category')}</Th> */}
                                        <Th textAlign={'center'}>{t('text-quantity')}</Th>
                                        <Th textAlign={'center'}>{t('text-price')}</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        info?.resources.map((ele, index) => (
                                            <Tr key={index}>
                                                {/* <Td textAlign={'center'}><Checkbox colorScheme='red' defaultChecked>
                                                </Checkbox></Td> */}
                                                <Td textAlign={'center'}>
                                                    <Heading as='h5' size='sm'>
                                                        {ele.id}
                                                    </Heading>
                                                </Td>
                                                <Td textAlign={'center'}>
                                                    <Heading as='h5' size='sm'>
                                                        {ele.name}
                                                    </Heading>
                                                </Td>
                                                {/* <Td textAlign={'center'}>
                                                    <Heading as='h5' size='sm'>
                                                        ألكترونيات
                                                    </Heading>
                                                </Td> */}
                                                <Td textAlign={'center'}>
                                                    <Heading as='h5' size='sm'>
                                                        {ele.quantity}
                                                    </Heading>
                                                </Td>
                                                <Td textAlign={'center'}>
                                                    <Heading as='h5' size='sm'>
                                                        {ele.price}
                                                    </Heading>
                                                </Td>
                                            </Tr>
                                        ))
                                    }
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </ModalBody>
                    {
                        (info?.isAccepted === null) &&
                        <ModalFooter>
                            <Button isLoading={isLoading} colorScheme='blue' mr={3} onClick={handleSaveBtn}>
                                {t('text-approval-request')}
                            </Button>
                            <Button isLoading={isRefuseLoading} colorScheme='red' mr={3} onClick={handleRefuseBtn}>
                                {t('text-refuse')}
                            </Button>
                        </ModalFooter>
                    }
                </ModalContent>
            </Modal>
        </>
    )
}