import React, { useState } from 'react'
import MainContainer from '../../components/MainContainer'
import { Stack, Text } from '@chakra-ui/react'
import { useAppContext } from '../../contexts/app-context'
import { useTranslate } from '../../hooks/langs-hooks'
import { CovenantRequest } from '../../components/covenant-components/CovenantRequest'
import { CovenantTable } from '../../components/covenant-components/CovenantTable'
import { CustodayObj } from '../../types'

interface HRProps {
  employeeId?: number;
}



export const CovenantEmployee: React.FC<HRProps> = ({ }) => {
  const t = useTranslate();
  const { user } = useAppContext();
  const [parentState, setParentState] = useState<boolean>(false);
  const handleAddCustoday = ()=>{
    setParentState(!parentState);
  };
  return (
    <>
      <MainContainer>
        <Stack display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexDirection={'row'} bgColor={'#f3f5fa'} p={5}>
          <Text fontWeight={900} fontSize='2.25rem' mb={1} p={6}>
            {t('text-covenant')}
          </Text>
          (<CovenantRequest onDataFromChild={handleAddCustoday} />)
        </Stack>
        <CovenantTable parentState={parentState} />
      </MainContainer >
    </>
  )
}