import { Image, Stack } from '@chakra-ui/react'
import React from 'react'

export const MatrixLoading = () => {
    return (
        <>
            <Stack display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Image boxSize='300px' 
                src='https://res.cloudinary.com/newbookify/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1721112871/An-zVgrCESTvKwftAxNVaXHvhhNHhFENfDX86Qyb6XyCVUW3EGmvRTZ5lBLV7GDtT-xIRRoFOfeIL-uYTsD30txI_knjj38.gif' alt='Loading....' />
            </Stack>
        </>
    )
}
