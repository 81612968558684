import { Box, HStack, Spacer, Text } from '@chakra-ui/react';
import { useTranslate } from '../../hooks/langs-hooks';
import { IEvent } from '../../types';
import { diffInDays } from '../../utils/date-utils';

interface EventCardProps {
  event: IEvent;
}

const colors = new Map();

colors.set('prj', '#D7B400');
colors.set('task', '#4B01DC');
colors.set('meeting', '#2D5BFF');
colors.set('public', '#F05223');

const EventCard: React.FC<EventCardProps> = ({ event }) => {
  const t = useTranslate();

  const color = colors.get(event.eventType);

  return (
    <Box
      bg='#F8F8F8'
      p={2}
      borderRadius='5px'
      borderLeft={`.3rem solid ${color}`}
      w='100%'
    >
      <HStack>
        <Text fontSize='sm' fontWeight={600}>
          {event.eventName}
        </Text>
        <Spacer />
        <Text color={color} fontWeight={600} fontSize='md'>
          {diffInDays(event.eventStartTime, event.eventFinishTime) +
            ' ' +
            t('text-days-left')}
        </Text>
      </HStack>
    </Box>
  );
};

export default EventCard;
