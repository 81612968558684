import { Suspense } from 'react';
import Loader from './loader/Loader';

interface CustomSuspenseProps {
  children: React.ReactNode;
}

const CustomSuspense: React.FC<CustomSuspenseProps> = ({ children }) => {
  return <Suspense fallback={<Loader size='xl' />}>{children}</Suspense>;
};

export default CustomSuspense;
