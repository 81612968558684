import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMe } from '../contexts/app-context';
import { useTranslate } from '../hooks/langs-hooks';
import useNotification from '../hooks/use-notification-hook';
import client from './client';
import { deleteProject } from './client/endpoints/project.endpoints';

export const useDeleteProject = (projectId: number | string) => {
  const t = useTranslate();
  const notify = useNotification();
  const queryClient = useQueryClient();
  const deleteProjectMutation = useMutation({
    mutationFn: deleteProject,
    onSuccess(data) {
      queryClient.invalidateQueries(['projects']);
      if (typeof data === 'string') {
        notify.success(t(data));
      } else {
        notify.success(t('text-del-success'));
      }
    },
    onError(error: any) {
      notify.error(t(error));
    },
  });

  const handleDeleteProject = () => {
    if (window.confirm(t('text-are-you-sure'))) {
      return deleteProjectMutation.mutateAsync(projectId);
    }
  };

  return {
    deleteProjectMutation,
    handleDeleteProject,
  };
};

export const useMyProjects = () => {
  const me = useMe();
  const { isLoading, isError, error, data } = useQuery({
    queryKey: ['my-projects'],
    queryFn: () => client.projects.myProjects(me?.userId!),
    enabled: !!me?.userId,
  });

  return {
    isLoading,
    isError,
    error,
    projects: data ?? [],
  };
};

export const useProjectResources = (projectId: number) => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['project-resources'],
    queryFn: () => client.repo.resources.projectResources(projectId),
  });

  return {
    resources: data ?? [],
    isLoading,
    isError,
    error,
  };
};
