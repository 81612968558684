import { Button, Grid, Text, VStack } from '@chakra-ui/react';
import FlushedInput from '../components/FlushedInput';
import FlushedPassword from '../components/FlushedPassword';
import MainContainer from '../components/MainContainer';
import { useAppContext } from '../contexts/app-context';
import { updateCompanyData } from '../data/client/endpoints/company.endpoints';
import { useTranslate } from '../hooks/langs-hooks';
import { useCustomMutation } from '../hooks/use-custsom-mutation';
import { useFormData } from '../hooks/use-form-data-hook';

interface CompanyDetailsProps {}

const CompanyDetails: React.FC<CompanyDetailsProps> = ({}) => {
  const t = useTranslate();
  const { company, setCompany } = useAppContext();
  const { data, handleChange, handleChangeNumber } = useFormData(company);

  const updateCompanyMutation = useCustomMutation({
    mutationFn: updateCompanyData,
    onSuccess() {
      setCompany(data);
    },
  });

  const handleEditCompany = (e: React.FormEvent) => {
    e.preventDefault();
    updateCompanyMutation.mutate(data ?? {});
  };

  return (
    <MainContainer py='5'>
      <Grid
        templateColumns={'repeat(auto-fill, minmax(250px, 1fr))'}
        gap={3}
        mb={4}
      >
        <CompanyDetailsCard
          count={company?.branchCount}
          name={t('text-branch-count')}
        />
        <CompanyDetailsCard
          count={company?.userCount}
          name={t('text-user-count')}
        />
        <CompanyDetailsCard
          count={company?.clientCount}
          name={t('text-client-count')}
        />
      </Grid>

      <form onSubmit={handleEditCompany}>
        <VStack spacing={3}>
          <FlushedInput
            label={t('text-branch-count')}
            value={data?.branchCount ?? ''}
            name='branchCount'
            onChange={handleChangeNumber}
          />
          <FlushedInput
            label={t('text-client-count')}
            value={data?.clientCount ?? ''}
            name='clientCount'
            onChange={handleChangeNumber}
          />
          <FlushedInput
            label={t('text-user-count')}
            value={data?.userCount ?? ''}
            name='userCount'
            onChange={handleChangeNumber}
          />

          <FlushedInput
            label={t('text-username')}
            value={data?.username ?? ''}
            name='username'
            onChange={handleChange}
          />

          <FlushedPassword
            label={t('text-password')}
            value={data?.password ?? ''}
            name='password'
            onChange={handleChange}
          />

          <Button type='submit' isLoading={updateCompanyMutation.isLoading}>
            {t('text-save')}
          </Button>
        </VStack>
      </form>
    </MainContainer>
  );
};

export default CompanyDetails;

interface CompanyDetailsCardProps {
  name: string;
  count?: number;
}

const CompanyDetailsCard: React.FC<CompanyDetailsCardProps> = ({
  count,
  name,
}) => {
  return (
    <VStack
      bg='#fff'
      boxShadow='0px 12px 16px -4px rgba(0, 0, 0, 0.08), 0px 4px 6px -2px rgba(0, 0, 0, 0.03)'
      p={3}
      borderRadius='4px'
      spacing={3}
    >
      <Text fontSize='1.5rem' fontWeight={700}>
        {name}
      </Text>
      <Text color='#7C7B7B' fontSize='lg' fontWeight={500}>
        {count}
      </Text>
    </VStack>
  );
};
