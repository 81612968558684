import { Box } from '@chakra-ui/react';
import Loader from './Loader';

interface PageLoaderProps {}

const PageLoader: React.FC<PageLoaderProps> = ({}) => {
  return (
    <Box w='100%' py='10'>
      <Loader size={'xl'} />
    </Box>
  );
};

export default PageLoader;
