import { useContext } from 'react';
import { appContext } from '../contexts/app-context';

import ar from '../langs/ar.json';
import en from '../langs/en.json';
import ur from '../langs/ur.json';

export interface IIndexable {
  [key: string]: any;
}

const langs: IIndexable = { en, ar, ur };

export const LANGUAGES = [
  {
    id: 1,
    name: 'en',
  },
  {
    id: 2,
    name: 'ar',
  },
  {
    id: 3,
    name: 'ur',
  },
];

export const useTranslate = () => {
  const {
    lang: { name: langCode },
  } = useContext(appContext);

  return (key: string) => {
    if (langCode in langs && key in langs[langCode]) {
      return langs[langCode][key];
    }
    return key;
  };
};

export const useLanguage = () => {
  const { changeLang, lang } = useContext(appContext);
  return { changeLang, lang };
};

export const isRTLLang = (lang: string) => {
  if (lang === 'ar' || lang === 'ur') return true;
  return false;
}