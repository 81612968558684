import { DragHandleIcon } from '@chakra-ui/icons'
import { Button, Heading, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import useNotification from '../../hooks/use-notification-hook';
import { useTranslate } from '../../hooks/langs-hooks';
import { CustodayObj } from '../../types';


export const CustodayDetails: React.FC<{ info: CustodayObj }> = ({ info }) => {
    const t = useTranslate();
    const notify = useNotification();
    const { isOpen, onOpen, onClose } = useDisclosure();
    
    return (
        <>
            <Icon as={DragHandleIcon} boxSize={6} cursor={'pointer'} onClick={onOpen} />
            <Modal size={'5xl'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Heading>{t('text-details')}</Heading>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody p={5}>
                        <TableContainer mb={5}>
                            <Table variant='striped' colorScheme='blue'>
                                <Tbody>
                                    <Tr>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {t('text-custody-number')}
                                            </Heading>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {info.id}
                                            </Heading>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {t('text-date-custody-trust')}
                                            </Heading>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {info.startDate?.toString().split('T')[0]}
                                            </Heading>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {t('text-return-date')}
                                            </Heading>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {info.returnDate?.toString().split('T')[0]}
                                            </Heading>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {t('text-type-custody')}
                                            </Heading>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {info.type}
                                            </Heading>
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                        <Heading as='h4' size='md' mb={5}>
                            {t('text-items')}
                        </Heading>
                        <TableContainer>
                            <Table variant='simple'>
                                <Thead bgColor={'#f0f0f0'}>
                                    <Tr>
                                        <Th textAlign={'center'}>{t('text-id')}</Th>
                                        <Th textAlign={'center'}>{t('text-name')}</Th>
                                        <Th textAlign={'center'}>{t('text-category')}</Th>
                                        <Th textAlign={'center'}>{t('text-quantity')}</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        info.resources.map((ele, index) => (
                                            <Tr key={index}>
                                                <Td textAlign={'center'}>{ele.id}</Td>
                                                <Td textAlign={'center'}>{ele.name}</Td>
                                                <Td textAlign={'center'}>{ele?.category}</Td>
                                                <Td textAlign={'center'}>{ele?.quantity}</Td>
                                            </Tr>
                                        ))
                                    }
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            {t('text-close')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}