import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppContext } from '../contexts/app-context';
import { isAdmin, isClient, isEmployee, isStaff } from '../utils/priviliages';
import CompanyDetails from './CompanyDetails';
import AdminDashboard from './admin-dashboard';

const Dashboard = lazy(() => import('./Dashboard'));
const ClientHome = lazy(() => import('./client/ClientHome'));

interface HomeProps {}

const Home: React.FC<HomeProps> = ({}) => {
  const { user, company } = useAppContext();

  return company ? (
    <CompanyDetails />
  ) : isClient(user) ? (
    <ClientHome />
  ) : isAdmin(user) ? (
    <AdminDashboard />
  ) : isStaff(user) ? (
    <Dashboard />
  ) : isEmployee(user) ? (
    <Navigate to='/my-tasks' />
  ) : null;
};

export default Home;
