import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import CustomSuspense from './CustomSuspense';
import Header from './layout/Header';
import Sidebar from './layout/sidebar/Sidebar';

interface PageWrapperProps {
  bg?: string;
  showHeader?: boolean;
  PrivateRouteWrapper: React.ComponentType<any>;
}

const MainLayout: React.FC<PageWrapperProps> = ({
  bg,
  PrivateRouteWrapper,
  showHeader = true,
}) => {
  return (
    <PrivateRouteWrapper>
      <Box>
        <Sidebar />
        <Box
          bg={
            bg ??
            'linear-gradient(267.82deg, #FFFAE1 -8.03%, #EEF2FF 44.43%, #F4EEFF 105.14%)'
          }
          minH='100vh'
          ps='4rem'
        >
          {showHeader && <Header />}
          <CustomSuspense>
            <Outlet />
          </CustomSuspense>
        </Box>
      </Box>
    </PrivateRouteWrapper>
  );
};

export default MainLayout;
