import {
  IBranch,
  ICreateUser,
  IFilterUsersState,
  ILookup,
  IMessage,
  IPaginatedResult,
  IPriviliges,
  ITask,
  ITaskCompleted,
  IUser,
  IUserInfo,
  IPieChart,
  IDeptKPI,
  ITaskProjectKPI
} from '../../../types';
import { getNumberOfDays } from '../../../utils/date-utils';
import { getNotificationToken } from '../../../utils/storage';
import api from '../http-client';
import { BASE_URL } from '../../../utils/constants';
import { setAuthToken,clearStorage } from '../../../utils/storage';
import { getAuthToken } from '../../../utils/storage';
const config = {
 
  headers: { 'Authorization': getAuthToken (),'Content-Type': 'application/json', }
};
export const fetchUsers = async (
  { itemsPerPage, page, type, departmentId, userName }: IFilterUsersState,
  branchId: number
) => {
  let data: IPaginatedResult<IUser>;

  if (type.id !== 0) {
    const res = await api.get<IUser[]>(
       BASE_URL + `/api/Users/Select/All/Users/In/Departement/${departmentId}/${branchId}`
       , {
        headers: {
        Authorization: getAuthToken(),
      }
    });
    return {
      total: 0,
      numberOfPages: 0,
      users: res.data,
    };
  } else if (type.value === 'search' && userName !== '') {
    const res = await api.post<IPaginatedResult<IUser>>(
       BASE_URL + `/api/Users/Select/User/By/UserName/${page}/${itemsPerPage}/${branchId}`,
      {
        userName,
      }
      , {
        headers: {
        Authorization: getAuthToken(),
      }
    });
    data = res.data;
  } else {
    const res = await api.get<IPaginatedResult<IUser>>(
       BASE_URL + `/api/Users/Select/All/Users/${page}/${itemsPerPage}/${branchId}`
       , {
        headers: {
        Authorization: getAuthToken(),
      }
    });
    data = res.data;
  }

  return {
    total: data.item1.rawCount,
    numberOfPages: data.item1.pageCount,
    users: data.item2,
  };
};

export const fetchUsersByUserNames = async ({
  userName,
  branchId,
}: {
  userName: string;
  branchId: number;
}) => {
  const res = await api.post<IPaginatedResult<IUser>>(
     BASE_URL +`/api/Users/Select/User/By/UserName/${1}/${20}/${branchId}`,
    {
      userName,
    }
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  return res.data.item2;
};

export const deleteListOfUsers = async (users: number[]) => {
  for (const userId of users) {
    await deleteUser(userId);
  }
  return {};
};

export const deleteUser = async (userId: number | string) => {
  const res = await api.delete<string>(BASE_URL+`/api/Users/Delete/User/${userId}`, {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return res.data;
};

export const createUser = async (data: ICreateUser) => {
  const res = await api.post<IUser>(BASE_URL+`/api/Users/Insert/Employee`, data, {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return res.data;
};

export const addUsersToDepartment = async ({
  usersIds,
  departmentId,
}: {
  usersIds: number[];
  departmentId: number;
}) => {
  const data = usersIds.map((id) => ({
    userId: id,
    departmentId,
  }));

  const res = await api.post<string>(
     BASE_URL + `/api/Users/Insert/User/In/Department`,
    data
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  return res.data;
};

export const addUsersToBranch = async ({
  usersIds,
  branchId,
}: {
  usersIds: number[];
  branchId: number;
}) => {
  const data = usersIds.map((userId) => ({
    userId,
    branchId,
  }));

  const res = await api.post<string>(BASE_URL+`/api/Users/Insert/User/In/Branch`, data, {
    headers: {
    Authorization: getAuthToken(),
  }
});

  return res.data;
};

export const login = async (data: {
  userEmail: string;
  userPassword: string;
}) => {
  debugger;
  const { data: userData } = await api.post<IUserInfo>(
     BASE_URL +'/api/WithoutAuthorize/User/Login',
    {
      ...data,
      token: getNotificationToken() ?? '',
    
    }
    
  ) 
  setAuthToken(userData.userToken);
  ;

  const branches = await getUserBranches(userData.userId);

  let privilege: IPriviliges | null = null;

  try {
    privilege = JSON.parse(userData.privilege) as IPriviliges;
  } catch (err) {
    console.log(err);
  }

  return {
    ...userData,
    branches,
    privileges: privilege,
  };
};

export const editUser = async (user: {
  userId: number;
  userName?: string;
  userPhone?: string;
  userAddress?: string;
  userEmail?: string;
  userPassword?: string;
  userNationalNumber?: string;
  image?: File | null;
  privilege?: string;
  jobDescriptionId?: number;
  userActivition?: boolean;
}) => {
  const data = Object.keys(user)
    .filter((key) => key != 'image')
    .map((key) => {
      return {
        op: 'replace',
        path: `/${key}`,
        value: user[key as 'userId'],
      };
    });

  const res = await api.patch<string>(
     BASE_URL + `/api/Users/Update/User/Data/${user.userId}`,
    data
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  let imageUrl = null;

  if (user.image) {
    imageUrl = await uploadUserImage(user.userId, user.image);
  }

  return {
    imageUrl,
    data: res.data,
  };
};

const uploadUserImage = async (userId: number, image: File) => {
  const formData = new FormData();
  formData.append('Files', image);

  const res = await api.post<string>(
     BASE_URL +`/api/Users/Upload/User/Image/${userId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': getAuthToken(),
      },
    }
  );

  return res.data;
};

export const getUser = async (userId: number | string) => {
  const res = await api.get<IUser>( BASE_URL +`/api/Users/Select/User/By/${userId}` , {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return res.data;
};

export const getAllEmpTasks = async (
  userId: number | string,
  pageNumber = 1,
  itemsPerPage = 10
) => {
  const { data } = await api.get<IPaginatedResult<ITask>>(
     BASE_URL + `/api/Users/Select/All/User/Tasks/${pageNumber}/${itemsPerPage}/${userId}`
     , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  return {
    total: data.item1.rawCount,
    numberOfPages: data.item1.pageCount,
    tasks: data.item2,
  };
};

export const getEmpKPI1 = async (empId: number | string, date: Date) => {
  const res = await api.post<{
    jaNcount: number;
    jan: number;
    feBcount: number;
    feb: number;
    maRcount: number;
    mar: number;
    apRcount: number;
    apr: number;
    maYcount: number;
    may: number;
    juNcount: number;
    jun: number;
    juLcount: number;
    jul: number;
    auGcount: number;
    aug: number;
    sePcount: number;
    sep: number;
    ocTcount: number;
    oct: number;
    noVcount: number;
    nov: number;
    deScount: number;
    des: number;
    excellentPointCount: number;
  }>( BASE_URL +`/api/Users/Select/All/User/Tasks/Copleted/In/KPI`, {
    userId: empId,
    date,
    headers :{  Authorization: getAuthToken(),}
  
  }
  

  
  );

  return res.data;
};

export const getTasksAndProjects = async ( date: Date) => {
  const res = await api.post<ITaskProjectKPI[]>//{
 
  ( BASE_URL +`/api/Users/TasksAndProjectsPerYear`, {
    
    date,
    headers :{  Authorization: getAuthToken(),}
  
  }
  

  
  );

  return res.data;
};
export const getDeptKPI = async ( date: Date) => {
  const res = await api.post<IDeptKPI[]>//{
    // jaNcount: number;
    // jan: number;
    // feBcount: number;
    // feb: number;
    // maRcount: number;
    // mar: number;
    // apRcount: number;
    // apr: number;
    // maYcount: number;
    // may: number;
    // juNcount: number;
    // jun: number;
    // juLcount: number;
    // jul: number;
    // auGcount: number;
    // aug: number;
    // sePcount: number;
    // sep: number;
    // ocTcount: number;
    // oct: number;
    // noVcount: number;
    // nov: number;
    // deScount: number;
    // des: number;
    //  departmentNameJan :string;
    //   departmentNameFeb :string;
    //   departmentNameMAr :string;
    //   departmentNameApri:string;
    //   departmentNameMay :string;
    //   departmentNameJun :string;
    //   departmentNameJuly:string;
    //   departmentNameAug :string;
    //   departmentNameSept:string;
    //   departmentNameNov :string;
    //   departmentNameOct :string;
    //   departmentNameDec :string;

    //excellentPointCount: number;
  ( BASE_URL +`/api/Users/DepartmentKBIs`, {
    
    date,
    headers :{  Authorization: getAuthToken(),}
  
  }
  

  
  );

  return res.data;
};
export const getUsersDept = async () => {
  const res = await api.get<IPieChart[]>//{
   
  ( BASE_URL +`/api/Users/EmpInDepartments`, {
    
    
    headers :{  Authorization: getAuthToken(),}
  
  }
  );
  return res.data;
};
export const getprojectComplete = async () => {
  const res = await api.get<IPieChart[]>//{
   
  ( BASE_URL +`/api/Users/CompletedProjects`, {
    
    
    headers :{  Authorization: getAuthToken(),}
  
  }
  );
  return res.data;
};
export const getTasksComplete = async () => {
  const res = await api.get<IPieChart[]>//{
   
  ( BASE_URL +`/api/Users/TasksCompletedUnCompleted`, {
    
    
    headers :{  Authorization: getAuthToken(),}
  
  }
  );
  return res.data;
};

export const getCompletedUnTasks = async (empId: number | string, date: Date) => {
  const res = await api.post<ITaskCompleted[]>//{
    // jaNcount: number;
    // jan: number;
    // feBcount: number;
    // feb: number;
    // maRcount: number;
    // mar: number;
    // apRcount: number;
    // apr: number;
    // maYcount: number;
    // may: number;
    // juNcount: number;
    // jun: number;
    // juLcount: number;
    // jul: number;
    // auGcount: number;
    // aug: number;
    // sePcount: number;
    // sep: number;
    // ocTcount: number;
    // oct: number;
    // noVcount: number;
    // nov: number;
    // deScount: number;
    // des: number;
    // excellentPointCount: number;
  //}>
  ( BASE_URL +`/api/Users/UserTasksCompleteNonComplete`, {
    userId: empId,
    date,
    headers :{  Authorization: getAuthToken(),}
  
  }
  

  
  );

  return res.data;
};
export const usersCompletedTasks = async (month: number | undefined) => {
  const res = await api.get<Number>//{
   
  ( BASE_URL +`/api/Users/UsersCompletedTasks/${month}`, {
     
   
    headers :{  Authorization: getAuthToken(),}
  
  }
  

  
  );

  return res.data;
};

export const uploadEmpsData = async (file: File) => {
  const fd = new FormData();
  fd.set('formFile', file);
  const res = await api.post<string>( BASE_URL +`/api/Users/Insert/Users/File`, fd, {
    headers: {
      'Content-Type': 'multipart-form-data',
      Authorization: getAuthToken(),
    },
  });
  return res.data;
};

export const uploadAbsence = async (file: File) => {
  const fd = new FormData();
  fd.set('formFile', file);
  const res = await api.post<string>(
     BASE_URL + `/api/Users/Insert/Users/Apsent/File`,
    fd,
    {
      headers: {
        'Content-Type': 'multipart-form-data',
        Authorization: getAuthToken(),
      },
    }
  );
  return res.data;
};

export const getUserAbsence = async (data: { userId: number; date: Date }) => {
  const res = await api.post<
    {
      apsentdate: string;
    }[]
  >( BASE_URL +`/api/Users/Select/User/Apsent`, data, {
    headers: {
    Authorization: getAuthToken(),
  }
});

  // console.log('absencse', res.data);

  const days = res.data.map((d, index) => {
    return {
      number: new Date(d.apsentdate).getDate(),
      value: false,
    };
  });

  for (let i = 1; i <= getNumberOfDays(data.date); i++) {
    const isExists = days.find((d) => d.number === i);
    if (!isExists) {
      days.push({ number: i, value: true });
    }
  }

  days.sort((a, b) => a.number - b.number);

  const numberOfAbsenceDays = res.data.length,
    absencePercent = Math.round((numberOfAbsenceDays / days.length) * 100);

  return {
    days,
    presesncePercent: 100 - absencePercent,
    absencePercent,
  };
};

export const getUserBranches = async (userId: number | string) => {
 
  const { data } = await api.get<IBranch[]>(
     BASE_URL + `/api/Projects/Select/All/Branchs/By/${userId}`
  , {
    headers: {
    Authorization: getAuthToken(),
  }
})    


  return data;
};

export const getNotifications = async (userId: number, page: number) => {
  const { data } = await api.get<IPaginatedResult<IMessage>>(
     BASE_URL +`/api/Users/Select/All/User/Message/${page}/${3}/${userId}`
     , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  return {
    notifications: data.item2,
    totalCount: data.item1.rawCount,
    numberOfPages: data.item1.pageCount,
  };
};

export const sendEmail = async (email: string) => {
  const res = await api.post<string>( BASE_URL +`/api/Users/Send/Email`, {
    toEmail: email,
  }, {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return res.data;
};

export const eidtUserPoints = async (data: {
  userId: number;
  point: number;
}) => {
  const res = await api.patch<string>( BASE_URL +`/api/Users/User/Excellent/Point`, data, {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return res.data;
};
