import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ChakraCustomProvider from './chakra/ChakraCustomProvider';
import { ModalProvider } from './components/modals/context';
import AppContextProvider from './contexts/app-context';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 60 * 1000,
      refetchOnWindowFocus: true,
      retry: 0,
    },
  },
});

root.render(
  <React.StrictMode>
    <AppContextProvider>
      <ChakraCustomProvider>
        <QueryClientProvider client={queryClient}>
          <ModalProvider>
            <App />
          </ModalProvider>
          <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
        </QueryClientProvider>
      </ChakraCustomProvider>
    </AppContextProvider>
 </React.StrictMode>
);
