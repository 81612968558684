import { AddIcon, DeleteIcon } from '@chakra-ui/icons'
import { Button, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslate } from '../../hooks/langs-hooks'
import { ElementObj, resourcesRequestObj, SplitRequestObj, SplitCustodaysOnTasksObj } from '../../types'
import { SplitCustodaysOnTasks } from '../../data/client/endpoints/Covenant/Covenant.endPoint'
import useNotification from '../../hooks/use-notification-hook'


export const AddElementsToTasks: React.FC<SplitRequestObj> = ({ taskId, projectId, custodayId, resources }) => {
    const t = useTranslate();
    const notify = useNotification();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [elementArr, setElementArr] = useState<ElementObj[]>([]);
    const [resourcesRequestArr, setResourcesRequestArr] = useState<resourcesRequestObj[]>([]);
    const [quantity, setQuantity] = useState<number>(0);
    const [isLoading,setIsLoading] = useState<boolean>(false);
    const handleAddButton = (ele: any) => {
        if (quantity > ele.quantity) {
            notify.error("Please enter a valid quantity");
            return;
        }
        const newObject: ElementObj = { id: ele.id, name: ele.name, availableQuantity: ele.quantity, requiredQuantity: quantity };
        const requestElementObj: resourcesRequestObj = { id: ele.id, quantity: quantity };
        setResourcesRequestArr([...resourcesRequestArr, requestElementObj])
        setElementArr([...elementArr, newObject]);
        setQuantity(0);
        // resources = resources?.filter(element => element.id !== ele.id)
    }
    const removeObject = (idToRemove: number) => {
        const filteredArray = elementArr.filter(element => element.id !== idToRemove);
        const newElementArr = resourcesRequestArr.filter(element => element.id !== idToRemove);
        setResourcesRequestArr(newElementArr);
        setElementArr(filteredArray);
    }

    const handleSaveBtn = async () => {
        var requestObj: SplitCustodaysOnTasksObj = {
            taskId: taskId,
            projectId: projectId,
            custodayId: custodayId,
            resources: resourcesRequestArr
        }
        try {
            setIsLoading(true)
            var data = await SplitCustodaysOnTasks(requestObj);
            if (data.status === 200) {
                notify.success("success operation");
                onClose();
                setIsLoading(false)
            } else {
                notify.error(data.response.data);
                setIsLoading(false)
            }
        } catch (error) {
            notify.error("server error")
        }
    }
    return (
        <>
            <Button onClick={onOpen} leftIcon={<AddIcon />} colorScheme='gray' variant='solid'>
                {t('text-add')}
            </Button>
            <Modal size={'4xl'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader><Heading>{t('text-add-items-to-task')}</Heading></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <TableContainer mb={5}>
                            <Table variant='simple'>
                                <Thead bgColor={'#f0f0f0'}>
                                    <Tr>
                                        <Th textAlign={'center'}>{t('text-item-no')}</Th>
                                        <Th textAlign={'center'}>{t('text-name')}</Th>
                                        <Th textAlign={'center'}>{t('text-available-quantity')}</Th>
                                        <Th textAlign={'center'}>{t('text-required-quantity')}</Th>
                                        <Th textAlign={'center'}>{t('text-add')}</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        resources?.map((ele, index) => (
                                            <Tr key={index}>
                                                <Td textAlign={'center'} bgColor={'#8f9fd8'}>{ele.id}</Td>
                                                <Td textAlign={'center'}>{ele.name}</Td>
                                                <Td textAlign={'center'}>{ele.quantity}</Td>
                                                <Td textAlign={'center'}>
                                                    <Input value={quantity} width={'100px'} color={'red'} htmlSize={5} bgColor={'white'} type={"number"} onChange={e => setQuantity(+e.target.value)} />
                                                </Td>
                                                <Td textAlign={'center'}>
                                                    <Button leftIcon={<AddIcon />} colorScheme='gray' variant='solid' onClick={() => handleAddButton(ele)}>
                                                        {t('text-add')}
                                                    </Button>
                                                </Td>
                                            </Tr>
                                        ))
                                    }
                                </Tbody>
                            </Table>
                        </TableContainer>
                        {
                            elementArr.length > 0 &&
                            <TableContainer mb={5}>
                                <Table variant='simple'>
                                    <Thead bgColor={'#f0f0f0'}>
                                        <Tr>
                                            <Th textAlign={'center'}>{t('text-item-no')}</Th>
                                            <Th textAlign={'center'}>{t('text-name')}</Th>
                                            <Th textAlign={'center'}>{t('text-available-quantity')}</Th>
                                            <Th textAlign={'center'}>{t('text-required-quantity')}</Th>
                                            <Th textAlign={'center'}>{t('text-remove')}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            elementArr.map((ele, index) => (
                                                <Tr key={index}>
                                                    <Td textAlign={'center'} bgColor={'#8f9fd8'}>{ele.id}</Td>
                                                    <Td textAlign={'center'}>{ele.name}</Td>
                                                    <Td textAlign={'center'}>{ele.availableQuantity}</Td>
                                                    <Td textAlign={'center'}>{ele.requiredQuantity}</Td>
                                                    <Td textAlign={'center'}>
                                                        <DeleteIcon w={8} h={8} color="red.500" cursor={'pointer'} onClick={() => removeObject(ele.id)} />
                                                    </Td>
                                                </Tr>
                                            ))
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button variant='ghost' mr={3} onClick={onClose}>
                            {t('text-close')}
                        </Button>
                        <Button isLoading={isLoading} colorScheme='blue' onClick={handleSaveBtn}>{t('text-save')}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}