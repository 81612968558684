import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Button, ButtonGroup, Flex, IconButton } from '@chakra-ui/react'
import React from 'react'
interface PaginationProps {
    totalPages: number;
    currentPage: number;
    onPageChange: (page: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({ totalPages, currentPage, onPageChange }) => {
    const handleClick = (page: number) => {
        onPageChange(page);
    };


    const goToPrevPage = () => {
        if (currentPage > 1) {
            handleClick(currentPage - 1);
        }
    };

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            handleClick(currentPage + 1);
        }
    };
    // Generate array of three page numbers based on current page
    const getPageNumbers = () => {
        const pageNumbers: number[] = [];

        if (currentPage <= totalPages) {
            if (currentPage > 1) pageNumbers.push(currentPage - 1);
            pageNumbers.push(currentPage);
            if (currentPage < totalPages) pageNumbers.push(currentPage + 1);
        }

        return pageNumbers;
    };

    return (
        <>
            <Flex align="center" justify="end">
                <ButtonGroup>
                    <IconButton
                        aria-label="Previous Page"
                        icon={<ChevronLeftIcon />}
                        onClick={goToPrevPage}
                        isDisabled={currentPage === 1}
                    />
                    {getPageNumbers().map((page) => (
                        <Button
                            key={page}
                            onClick={() => handleClick(page)}
                            colorScheme={currentPage === page ? 'blue' : undefined}
                            variant={currentPage === page ? 'solid' : 'outline'}
                        >
                            {page}
                        </Button>
                    ))}
                    <IconButton
                        aria-label="Next Page"
                        icon={<ChevronRightIcon />}
                        onClick={goToNextPage}
                        isDisabled={currentPage === totalPages}
                    />
                </ButtonGroup>
            </Flex>
        </>
    )
}
