import { ModalOverlay } from '@chakra-ui/react';
import { Suspense, lazy } from 'react';
import { CustomModal } from '.';
import { TaskMemberModalContent } from './MembersModalContent';
import { MODAL_VIEWS, useModalAction, useModalState } from './context';
import TaskResourcesModalContent from '../task/TaskMaterialRequestModal';
import TaskProjectScopeModalcontent from '../task/TaskMaterialRequestModal';
import TaskMaterialReservedModal from '../task/TaskMaterialReservedModal';
const EditTaskModalContent = lazy(() => import('../task/edit-task'));
const TaskClientCommentsModalContent = lazy(
  () => import('../task/TaskClientCommentsModal')
);
const TaskCommentsModalContent = lazy(
  () => import('../task/TaskCommentsModal')
);
const NewTaskMaterialModalContent = lazy(
  () => import('./repo/NewTaskMaterialRequestModalContent')
);
const TaskDetailsModalContent = lazy(
  () => import('../task/TaskDetailModalContent')
);

interface RenderModalContentProps {
  view?: MODAL_VIEWS;
  data: any;
  closeModal: () => void;
}

function renderModalContent({
  data,
  view,
  closeModal,
}: RenderModalContentProps) {
  switch (view) {
    case 'TASK_DETAILS':
      return <TaskDetailsModalContent />;
    case 'EDIT_TASK':
      return <EditTaskModalContent {...data} />;
    case 'TASK_MEMBERS':
      return <TaskMemberModalContent closeModal={closeModal} {...data} />;
    case 'TASK_CLIENT_COMMENTS':
      return <TaskClientCommentsModalContent {...data} />;
    case 'TASK_EMPLOYEE_COMMENTS':
      return <TaskCommentsModalContent {...data} />;
    case 'TASK_REQUEST_MATERIAL':
      return <NewTaskMaterialModalContent {...data} />;
    case 'TASK_MATERIAL_REQUEST_Project':
      return <TaskProjectScopeModalcontent {...data} />;
      case 'TASK_MATERIAL_Reserved_Project':
        return <TaskMaterialReservedModal {...data} />;
      
    default:
      return null;
  }
}

export default function ModalsContainer() {
  const { isOpen, view, data } = useModalState();
  const { closeModal } = useModalAction();

  return (
    <CustomModal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <Suspense>
        {renderModalContent({
          data,
          view,
          closeModal,
        })}
      </Suspense>
    </CustomModal>
  );
}
