import { ICompanyData } from '../../../types';
import { BASE_URL } from '../../../utils/constants';
import api from '../http-client';
import { getAuthToken, setAuthToken } from '../../../utils/storage';
export const loginAsCompany = async (data: {
  userEmail: string;
  userPassword: string;
}) => {
  const res = await api.post<ICompanyData>(BASE_URL+`/api/WithoutAuthorize/Login/As/Company`, data)
  setAuthToken(res.data.userToken);
  ;
  return res.data;
};

export const updateCompanyData = async (data: {
  branchCount?: number;
  clientCount?: number;
  userCount?: number;
  username?: string;
  password?: string;
  repositoryStatus?: boolean;
}) => {
  const updatedData: object[] = [];

  Object.keys(data).forEach((key) => {
    updatedData.push({
      op: 'replace',
      path: `/${key}`,
      // @ts-ignore
      value: data[key],
    });
  });

  const res = await api.patch<string>(
    BASE_URL+ `/api/Users/Update/Company/Control/${1}`,
    updatedData
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return 'upt-success';
};
