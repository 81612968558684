import {
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  InputProps,
} from '@chakra-ui/react';
import { useState } from 'react';
import { eyeImage } from '../assests/icons';

interface FlushedPasswordProps extends InputProps {
  label?: string;
}

const FlushedPassword: React.FC<FlushedPasswordProps> = ({
  label,
  ...props
}) => {
  const [show, setShow] = useState(false);

  const toggleShow = () => setShow((val) => !val);

  return (
    <FormControl>
      {label && <FormLabel>{label}</FormLabel>}
      <InputGroup>
        <Input
          type={show ? 'text' : 'password'}
          borderColor='#A6B2BE'
          color={'#B3B3B7'}
          variant='flushed'
          fontWeight={500}
          fontSize='md'
          _placeholder={{
            fontWeight: 500,
            fontSize: 'md',
            color: '#B3B3B7',
          }}
          {...props}
        />
        <InputRightElement onClick={toggleShow} cursor='pointer'>
          <Image src={eyeImage} h='5' />
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
};

export default FlushedPassword;
