import {
  ICategory,
  ICreateCustody,
  ICreateExchange,
  ICreateProjectScopeResouce as ICreateProjectScopeResource,
  ICreateReceive,
  ICreateRecoveryResource,
  ICreateTaskProjectScopeResouce,
  ICustody,
  IExchange,
  IExistResource,
  IFilterState,
  IInventoryResource,
  IPaginatedResult,
  IPricingResource,
  IProjectResource,
  IReceive,
  IRecoveryResource,
  IResource,
  IResourceDetails,
  IResourceLst,
  IResourceUnit,
  ISupplier,
  ISupplingResource,
  IUserInfo,
  IUserVm,
} from '../../../types';
import { formatePaginatedResult } from '../../../utils';
import { BASE_URL } from '../../../utils/constants';
import api from '../http-client';
import { getAuthToken } from '../../../utils/storage';
export const getAllCategories = async () => {
 
  var prod = document.getElementById('ProductId');
  var service = document.getElementById('ServiceId');
  if(prod&& service)
    {  prod.style.display = "none" ;
    service.style.display = "none" ;
    }
  const res = await api.get<ICategory[]>(BASE_URL+`/api/Repository/Select/All/Category` , {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return res.data;
};

export const addCategory = async (data: ICategory) => {
  const res = await api.post<string>(BASE_URL+`/api/Repository/Insert/Category`, data , {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return res.data;
};

export const deleteCategory = async (categoryId: number | string) => {
  const res = await api.delete<string>(
    BASE_URL+`/api/Repository/Delete/Category/${categoryId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data;
};

export const addReceivingRequest = async (data: ICreateReceive[]) => {
  const res = await api.post<string>(BASE_URL+`/api/Repository/Insert/Receiving`, data , {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return res.data;
};
 
export const addPricingResource = async (client: IPricingResource) => {
 
  const fd = new FormData();
 
  client.Files.forEach((file) => {
    fd.append('Files', file);
  });
  Object.entries(client).forEach(([key, value]) => {
   
    if(key!="Files")
    fd.set(key, value);
  });

  const { data } = await api.post<string>(BASE_URL+`/api/StoreHouse/AddPricingResource`, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': getAuthToken(),
    },
  });

  return data;
};
export const addExistResource = async (client: IExistResource) => {
 
  const fd = new FormData();
 
  client.Files.forEach((file) => {
    fd.append('Files', file);
  });
  Object.entries(client).forEach(([key, value]) => {
   
    if(key!="Files")
    fd.set(key, value);
  });

  const { data } = await api.post<string>(BASE_URL+`/api/StoreHouse/AddExistResource`, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': getAuthToken(),
    },
  });

  return data;
};
export const addSupplingProcess = async (client: ISupplingResource) => {
 
  const fd = new FormData();
 
  client.Files.forEach((file) => {
    fd.append('Files', file);
  });
  Object.entries(client).forEach(([key, value]) => {
   
    if(key!="Files")
    fd.set(key, value);
  });

  const { data } = await api.post<string>(BASE_URL+`/api/StoreHouse/AddSupplingProcess`, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': getAuthToken(),
    },
  });

  return data;
};
export const addInventoryResource = async (client: IInventoryResource) => {
 
   const fd = new FormData();
  
   client.Files.forEach((file) => {
     fd.append('Files', file);
   });
  //  client.UserId?.forEach((user) => {
  //   fd.append('UserId', user as unknown as Blob);
  // });
  // const usersIds = client.UserId?.map((id) => {
  //   return {
  //     userId: id,
  //   };
  // });
  //client.UserId?=usersIds?.[0].userId;
   Object.entries(client).forEach(([key, value]) => {
    
     if(key!="Files")
     fd.set(key, value);
   

   });
 
   const { data } = await api.post<string>(BASE_URL+`/api/StoreHouse/AddInventory`, fd, {
     headers: {
       'Content-Type': 'multipart/form-data',
       'Authorization': getAuthToken(),
     },
   });
  //  const usersIds = client.UserId?.map((id) => {
  //   return {
  //     userId: id,
  //     inventoryId:client.inventoryId,
 
  //   };
  // });

  // const res = await api.post<string>(
  //   BASE_URL+`/api/StoreHouse/AddUsersInventory`,
  //   usersIds
  //   , {
  //     headers: {
  //     Authorization: getAuthToken(),
  //   }
  // });
   return data;
 };
 export const addRecoveryResource = async (client: IRecoveryResource) => {
 
  const fd = new FormData();
 
  client.Files.forEach((file) => {
    fd.append('Files', file);
  });
 //  client.UserId?.forEach((user) => {
 //   fd.append('UserId', user as unknown as Blob);
 // });
 // const usersIds = client.UserId?.map((id) => {
 //   return {
 //     userId: id,
 //   };
 // });
 //client.UserId?=usersIds?.[0].userId;
  Object.entries(client).forEach(([key, value]) => {
   
    if(key!="Files")
    fd.set(key, value);
  

  });

  const { data } = await api.post<string>(BASE_URL+`/api/StoreHouse/AddRecovery`, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': getAuthToken(),
    },
  });
 //  const usersIds = client.UserId?.map((id) => {
 //   return {
 //     userId: id,
 //     inventoryId:client.inventoryId,

 //   };
 // });

 // const res = await api.post<string>(
 //   BASE_URL+`/api/StoreHouse/AddUsersInventory`,
 //   usersIds
 //   , {
 //     headers: {
 //     Authorization: getAuthToken(),
 //   }
 // });
  return data;
};

// export const createProject = async (project: IPricingResource) => {
//   const fd = new FormData();
//   fd.set('pricingDate', project.pricingDate);
//   fd.set('ProjectDescription', project.ProjectDescription);
//   fd.set('ClientId', project.ClientId.toString());
//   fd.set('BranchId', project.BranchId.toString());
//   fd.set('TypeId', project.TypeId.toString());
//   fd.set('ProjectImage', project.ProjectImage!);

//   project.Files.forEach((file) => {
//     fd.append('Files', file);
//   });

//   const { data: projectId } = await api.post<string>(
//     BASE_URL+ `/api/Projects/Insert/Project`,
//     fd,
//     {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//         'Authorization': getAuthToken()
//       },
//     }
//   );

//   const res = await api.post<string>(
//     BASE_URL+`/api/Projects/Insert/Project/List/Members/Departments`,
//     {
//       projectId,
//       heads: project.Heads.map((userId) => {
//         return {
//           userId,
//         };
//       }),
//       department: project.Department.map((d) => {
//         return {
//           ...d,
//           projectId,
//         };
//       }),
//     }
//   );

//   return {
//     projectId,
//     data: res.data,
//   };
// };
// export const getSpecificResource = async ({ page, count,search }: IFilterState) => {
//   debugger;
//   const { data } = await api.get<IPaginatedResult<IResourceLst>>(
//     BASE_URL+  `/api/Repository/GetSpecificResource/${page}/${count}/${search}`
//     , {
//       headers: {
//       Authorization: getAuthToken(),
//     }
//   });
   
//   return formatePaginatedResult(data);
// };
export const getSpecificResourc = async ({
  coverImage,
  descriptoin,
  name,
  unsavedFiles,
  projectId,
}: {
  projectId: number | string;
  unsavedFiles: File[];
  name: string;
  descriptoin: string;
  coverImage: File | null;
}) => {
  const patchData = [
    {
      op: 'replace',
      path: '/projectName',
      value: name,
    },
    {
      op: 'replace',
      path: '/projectDescription',
      value: descriptoin,
    },
  ];
debugger;
  const res1 = await api.patch<string>(
    BASE_URL+  `/api/Projects/Update/Project/Data/${projectId}`,
    patchData
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  if (coverImage) {
    const fd = new FormData();
    fd.set('Files', coverImage);
    const res = await api.post<string>(
      BASE_URL+`/api/Projects/Upload/Project/Image/${projectId}`,
      fd,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': getAuthToken()
        },
      }
    );
  }

  if (unsavedFiles.length) {
    const fd = new FormData();
    fd.set('ProjectId', projectId.toString());
    unsavedFiles.map((file) => {
      fd.append('Files', file);
    });
    const res = await api.post<string>(
      BASE_URL+  `/api/Projects/Insert/List/Of/Files/In/Project`,
      fd,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': getAuthToken()
        },
      }
    );
  }

  return {};
};


export const getSpecificResource = async (pricingResourceId : number|string) => {
 debugger;
  const { data } = await api.get<IResourceLst[]>(
    
    BASE_URL+  `/api/Repository/GetSpecificResource/${pricingResourceId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  return data;
};

export const getCustodyLogs = async ({
  page,
  count,
  type,
  userId = 1,
  branchId: breanchId,
}: IFilterState) => {
  let data: IPaginatedResult<ICustody> = {
    item1: {
      pageCount: 0,
      rawCount: 0,
    },
    item2: [],
  };

  if (type === 'all') {
    const res = await api.get<IPaginatedResult<ICustody>>(
      BASE_URL+  `/api/Repository/Select/All/Custoday/Log/${page}/${count}/${userId}/${breanchId}`
      , {
        headers: {
        Authorization: getAuthToken(),
      }
    });
    data = res.data;
  }

  return {
    numberOfPages: data.item1.pageCount,
    totalCount: data.item1.rawCount,
    logs: data.item2,
  };
};

export const newCustodyReq = async (data: ICreateCustody[]) => {
  // console.log({ data });
  const res = await api.post<string>(BASE_URL+`/api/Repository/Insert/Custoday`, data , {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return res.data;
};

export const getAllSuppliers = async () => {
  const res = await api.get<ISupplier[]>(BASE_URL+`/api/Repository/Select/All/Supplier` , {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return res.data;
};

export const getSuppliersByName = async ({
  count,
  page,
  search = '',
}: IFilterState) => {
  const res = await api.post<IPaginatedResult<ISupplier>>(
    BASE_URL+ `/api/Repository/Select/Supplier/By/UserName/${page}/${count}`,
    {
      supplierName: search,
    }
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return formatePaginatedResult(res.data);
};
export const editSupplier = async (type: ISupplier) => {
  const data = Object.keys(type).map((key) => {
    return {
      path: `/${key}`,
      operation: 'replace',
      value: type[key as 'supplierId'],
    };
  });
  const res = await api.patch<string>(
    BASE_URL+`/api/Repository/UpdateSupplier/${type.supplierId}`,
    data
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return 'upt-success';
};
// export const addSupplier = async (data: ISupplier) => {
//   const res = await api.post<string>(BASE_URL+`/api/Repository/Insert/Supplier`, data , {
//     headers: {
//     Authorization: getAuthToken(),
//   }
// });
//   return 'create-success';
// };
export const addSupplier = async (client: ISupplier) => {
  const fd = new FormData();

  Object.entries(client).forEach(([key, value]) => {
    fd.set(key, value);
  });

  const { data } = await api.post<string>(BASE_URL+`/api/Repository/Insert/Supplier`, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': getAuthToken(),
    },
  });

  return data;
};

export const deleteRecovery = async (id: number | string) => {
  const res = await api.delete<string>(
    BASE_URL+ `/api/StoreHouse/DeleteRecovery/${id}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  return res.data;
};
export const deleteSupplier = async (supplierId: number | string) => {
  const res = await api.delete<string>(
    BASE_URL+ `/api/Repository/Delete/Supplier/${supplierId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  return res.data;
};
export const deletePricing = async (id: number | string) => {
  const res = await api.delete<string>(
    BASE_URL+ `/api/StoreHouse/DeletePricingResource/${id}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  return res.data;
};
export const getAllemp = async ({ page, count }: IFilterState) => {
  var service = document.getElementById('ClientId');
  var prod = document.getElementById('EmpId');

  if(service)
  service.style.display = "none" ;
if(prod)
  prod.style.display = "none" ;

  const { data } = await api.get<IPaginatedResult<IUserVm>>(
    BASE_URL+  `/api/StoreHouse/GetEmployeeUsers/${page}/${count}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
 
  return formatePaginatedResult(data);
};
export const getAllprojects = async ({ page, count,branchId }: IFilterState) => {
 

 
  const { data } = await api.get<IPaginatedResult<IUserVm>>(
    BASE_URL+  `/api/StoreHouse/GetProjects/${page}/${count}/${branchId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
 
  return formatePaginatedResult(data);
};
export const deleteExistResource = async (id: number | string) => {
  const res = await api.delete<string>(
    BASE_URL+ `/api/StoreHouse/DeleteExistResources/${id}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  return res.data;
};
export const deleteSupplingResource = async (id: number | string) => {
  const res = await api.delete<string>(
    BASE_URL+ `/api/StoreHouse/DeleteSuppling/${id}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  return res.data;
};
export const deleteInventoryResource = async (id: number | string) => {
  const res = await api.delete<string>(
    BASE_URL+ `/api/StoreHouse/DeleteInventory/${id}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  return res.data;
};
export const addResourceUnit = async (data: IResourceUnit) => {
  const res = await api.post<string>(
    BASE_URL+ `/api/Repository/Insert/Resource/Unit`,
    data
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data;
};

export const getAllResourceUnits = async () => {
  const res = await api.get<IResourceUnit[]>(
    BASE_URL+  `/api/Repository/Select/All/Resource/Unit`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  return res.data;
};

export const deleteResourceUnit = async (resourceUnitId: number | string) => {
  const res = await api.delete<string>(
    BASE_URL+`/api/Repository/Delete/ResourceUnit/${resourceUnitId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data;
};

export const addResource = async (data: IResource) => {
  const res = await api.post<string>(BASE_URL+`/api/Repository/Insert/Resource`, data , {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return res.data;
};
export const uploadProducts = async (file: File) => {
  debugger;
  const fd = new FormData();
  fd.set('formFile', file);
  
  const res = await api.post<string>(
     BASE_URL + `/api/Repository/UploadProducts`,
    fd,
    {
      headers: {
        'Content-Type': 'multipart-form-data',
        Authorization: getAuthToken(),
      },
    }
  );
  return res.data;
};
export const UploadServices = async (file: File) => {
  debugger;
  const fd = new FormData();
  fd.set('formFile', file);
  
  const res = await api.post<string>(
     BASE_URL + `/api/Repository/UploadServices`,
    fd,
    {
      headers: {
        'Content-Type': 'multipart-form-data',
        Authorization: getAuthToken(),
      },
    }
  );
  return res.data;
};


export const getAllResources = async ({ page, count }: IFilterState) => {
  const { data } = await api.get<IPaginatedResult<IResourceDetails>>(
    BASE_URL+  `/api/Repository/Select/All/Resource/${page}/${count}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  return formatePaginatedResult(data);
};
export const getAllResourcesLst = async ({ page, count,search }: IFilterState) => {
 
  const { data } = await api.get<IPaginatedResult<IResourceLst>>(
    
    BASE_URL+  `/api/Repository/GetAllResources/${page}/${count}/${search}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  return formatePaginatedResult(data);
};

export const deleteResource = async (resourceId: number | string) => {
  const res = await api.delete<string>(
    BASE_URL+ `/api/Repository/Delete/Resource/${resourceId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return 'del-success';
};

export const getExchangeLogs = async ({
  page,
  count,
  userId = 1,
  search,
  type,
  branchId: breanchId,
}: IFilterState) => {
  let data: IPaginatedResult<IExchange>;

  if (search) {
    const res = await api.post<IPaginatedResult<IExchange>>(
      BASE_URL+ `/api/Repository/Select/Exchage/By/ResourceName/${page}/${count}`,
      {
        resourceName: search,
      }
      , {
        headers: {
        Authorization: getAuthToken(),
      }
    });
    data = res.data;
  } else {
    const res = await api.get<IPaginatedResult<IExchange>>(
      BASE_URL+ `/api/Repository/Select/Exchage/Log/${page}/${count}/${userId}/${breanchId}`
      , {
        headers: {
        Authorization: getAuthToken(),
      }
    });
    data = res.data;
  }

  return {
    numberOfPages: data.item1.pageCount,
    totalCount: data.item1.rawCount,
    exchangeLogs: data.item2,
  };
};

export const getResourcesByName = async ({
  page,
  count,
  search = '',
  branchId
}: IFilterState) => {
 
  const { data } = await api.post<IPaginatedResult<IResource>>(
    BASE_URL+ `/api/Repository/Select/Resource/By/ResourceName/${page}/${count}/${branchId}`,
    {
      resourceName: search,
      
    }
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return {
    numberOfPages: data.item1.pageCount,
    totalCount: data.item1.rawCount,
    resources: data.item2,
  };
};
export const getTaskResourcesByName = async ({
  page,
  count,
  search = '',userId
  
}: IFilterState) => {
 
  const { data } = await api.post<IPaginatedResult<IResource>>(
    BASE_URL+ `/api/Repository/GetTaskResourcesByName/${page}/${count}/${userId}`,
    {
      resourceName: search,
    }
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return {
    numberOfPages: data.item1.pageCount,
    totalCount: data.item1.rawCount,
    resources: data.item2,
  };
};

export const addExchange = async (data: ICreateExchange[]) => {
  const res = await api.post<string>(BASE_URL+`/api/Repository/Insert/Exchange`, data , {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return res.data;
};

export const addRecovery = async (data: IRecoveryResource[]) => {
  const res = await api.post<string>(BASE_URL+`/api/Repository/Insert/Recovery`, data , {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return res.data;
};

export const getReceivingLogs = async ({
  page,
  count,
  type,
  search,
  branchId,
}: IFilterState) => {
  if (search) {
    // TODO: search receiving by branch id (alaa)
    const { data } = await api.post<IPaginatedResult<IReceive>>(
      BASE_URL+ `/api/Repository/Select/Receiving/By/ResourceName/${page}/${count}`,
      {
        resourceName: search,
      }
      , {
        headers: {
        Authorization: getAuthToken(),
      }
    });
    return formatePaginatedResult(data);
  } else {
    const { data } = await api.get<IPaginatedResult<IReceive>>(
      BASE_URL+ `/api/Repository/Select/Receiving/Log/${page}/${count}/${branchId}`
      , {
        headers: {
        Authorization: getAuthToken(),
      }
    });
    return formatePaginatedResult(data);
  }
};
export const GetAllPricingResource = async ({ page, count,search }: IFilterState) => {
 
 
  // if (search===undefined)
  //       search='';
 
  const { data } = await api.get<IPaginatedResult<IPricingResource>>(
    
    BASE_URL+  `/api/StoreHouse/GetAllPricingResource/${page}/${count}/${search}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  
  });

  return formatePaginatedResult(data);
 };

 export const GetAllExistResource = async ({ page, count,search }: IFilterState) => {
 
 
  // if (search===undefined)
  //       search='';
 
  const { data } = await api.get<IPaginatedResult<IExistResource>>(
    
    BASE_URL+  `/api/StoreHouse/GetAllExistResource/${page}/${count}/${search}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  
  });

  return formatePaginatedResult(data);
 };

 export const GetAllSupplingResource = async ({ page, count,search,branchId }: IFilterState) => {
 
  const { data } = await api.get<IPaginatedResult<ISupplingResource>>(
    
    BASE_URL+  `/api/StoreHouse/GetAllSuppling/${page}/${count}/${search}/${branchId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  
  });

  return formatePaginatedResult(data);
 };
 export const GetAllInventoryResource = async ({ page, count,search,branchId }: IFilterState) => {
 
  const { data } = await api.get<IPaginatedResult<IInventoryResource>>(
    
    BASE_URL+  `/api/StoreHouse/GetAllInventroy/${page}/${count}/${search}/${branchId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  
  });

  return formatePaginatedResult(data);
 };
 export const GetAllReservedResource = async ({ page, count,userId,branchId }: IFilterState) => {
 

   
  // if (search===undefined)
  //       search='';
 
  const { data } = await api.get<IPaginatedResult<IResourceLst>>(
    
    BASE_URL+  `/api/StoreHouse/GetAllReservedResource/${page}/${count}/${userId}/${branchId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  
  });

  return formatePaginatedResult(data);
 };
 export const GetAllRecoveryResource = async ({ page, count,search,userId,branchId }: IFilterState) => {
 
 
  if (search===undefined)
        search='t';
  if (branchId===undefined)
    branchId='t';
  if (userId===undefined)
      userId=0;
  const { data } = await api.get<IPaginatedResult<IRecoveryResource>>(
    
    BASE_URL+  `/api/StoreHouse/GetAllRecoveryResource/${page}/${count}/${search}/${userId}/${branchId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  
  });

  return formatePaginatedResult(data);
 };
export const getReceivingByCategory = async ({
 
  categoryId,
  dateFrom = '',
  dateTo = '',
  page,
  count,
  search = '',
  branchId,
}: {
  categoryId: number;
  dateFrom?: string;
  dateTo?: string;
  page: number;
  count: number;
  search?: string;
  branchId: number;
}) => {
 
  if (search) {
    return getReceivingLogs({ count, page, search });
  }
  const res = await api.post<IPaginatedResult<IReceive>>(
    BASE_URL+`/api/Repository/Select/Receiving/Log/Category/${page}/${count}`,
    {
      categoryId,
      dateFrom,
      dateTo,
      branchId,
    }
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  return formatePaginatedResult(res.data);
};

export const addProjectScope = async (data: ICreateProjectScopeResource[]) => {
  const res = await api.post<string>(
    BASE_URL+   `/api/Repository/Insert/Project/Scope`,
    data
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data;
};
export const addTaskScope = async (data: ICreateTaskProjectScopeResouce[]) => {
  const res = await api.post<string>(
    BASE_URL+   `/api/Repository/Insert/Task/Scope`,
    data
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  //this.location.reload();
  return res.data;
};
export const getProjectScopeResources = async (projectId: number | string) => {
  const res = await api.get<IProjectResource[]>(
    BASE_URL+ `/api/Repository/Select/Project/Scope/${projectId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data;
};
export const getTaskScopeResources = async (projectId: number | string) => {
  const res = await api.get<IProjectResource[]>(
    BASE_URL+ `/api/Repository/Select/Project/Scope/${projectId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data;
};

export const getProjectCosts = async (projectId: number | string) => {
  const res = await api.get<{
    projectScopeCost: number;
    recivingCost: number;
    exchangedCost: number;
    restCost: number;
  }>(BASE_URL+`/api/Repository/Select/Project/Estimated/Cost/${projectId}` , {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return res.data;
};

export const getTaskCosts = async (projectId: number | string) => {
  const res = await api.get<{
    projectScopeCost: number;
    recivingCost: number;
    exchangedCost: number;
    restCost: number;
  }>(BASE_URL+`/api/Repository/Select/Project/Estimated/Cost/${projectId}` , {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return res.data;
};
export const getTaskMaterials = async (taskId: number | string) => {
  const res = await api.get<
    {
      exchangId: number;
      exchangResourceQuantity: number;
      exchangDate: string;
      resourceId: number;
      resourceName: string;
      resourcePrice: number;
      projectId: number;
      projectName: string;
      exchangeStatus: string;
    }[]
  >(BASE_URL+`/api/Repository/Select/Task/Material/Request/${taskId}` , {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return res.data;
};

export const getCustodyById = async (id: number | string) => {
  const res = await api.get<ICustody>(
    BASE_URL+  `/api/Repository/Select/Custoday/By/${id}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data;
};

export const getExchangeById = async (id: number | string) => {
  const res = await api.get<IExchange>(
    BASE_URL+ `/api/Repository/Select/Exchange/By/${id}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data;
};

export const confirmCustodyReceive = async ({
  custodyId,
  userId,
}: {
  custodyId: number | string;
  userId: number | string;
}) => {
  const res = await api.patch<string>(
    BASE_URL+ `/api/Repository/Custoday/Confirmtion/${custodyId}/${userId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data;
};

export const confirmReceiveExchange = async ({
  exchangeId,
  userId,
}: {
  userId: number | string;
  exchangeId: number | string;
}) => {
  const res = await api.patch<string>(
    BASE_URL+ `/api/Repository/Exchage/User/Confirmtion/${exchangeId}/${userId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data;
};

export const confirmSendExchange = async ({
  exchangeId,
  userId,
}: {
  userId: number | string;
  exchangeId: number | string;
}) => {
  const res = await api.patch<string>(
    BASE_URL+`/api/Repository/Exchage/Employee/Confirmtion/${exchangeId}/${userId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data;
};
