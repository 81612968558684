import axios from "axios";
import { BASE_URL } from "../../../../utils/constants";
import { saveAs } from 'file-saver';
import { getAuthToken } from "../../../../utils/storage";
import { CustodayRequestObj, elementsRequest, SplitCustodaysOnTasksObj, TaskCustodayObj } from "../../../../types";

export const GetUserCustodays = async () => {
    try {
        const response = await axios.get(BASE_URL + `/api/Custodays/GetUserCustodays`, { headers: { Authorization: getAuthToken() } });
        return response;
    } catch (error: any) {
        return error;
        throw new Error('Error fetching user data: ' + error.message);
    }
}

export const GetAllCustodysPerPage = async (pageNo:number,pageSize:number) => {
    try {
        const response = await axios.get(BASE_URL + `/api/Custodays/GetAllCustodysPerPage/${pageNo}/${pageSize}`, { headers: { Authorization: getAuthToken() } });
        return response;
    } catch (error: any) {
        return error;
        throw new Error('Error fetching user data: ' + error.message);
    }
}

export const GetCustodaysTotalCount = async () => {
    try {
        const response = await axios.get(BASE_URL + `/api/Custodays/GetCustodaysTotalCount`, { headers: { Authorization: getAuthToken() } });
        return response;
    } catch (error: any) {
        return error;
        throw new Error('Error fetching user data: ' + error.message);
    }
}


export const GetUserCustodaysPerPage = async (pageNo:number,pageSize:number) => {
    try {
        const response = await axios.get(BASE_URL + `/api/Custodays/GetUserCustodaysPerPage/${pageNo}/${pageSize}`, { headers: { Authorization: getAuthToken() } });
        return response;
    } catch (error: any) {
        return error;
        throw new Error('Error fetching user data: ' + error.message);
    }
}

export const GetUserCustodaysTotalCount = async () => {
    try {
        const response = await axios.get(BASE_URL + `/api/Custodays/GetUserCustodaysTotalCount`, { headers: { Authorization: getAuthToken() } });
        return response;
    } catch (error: any) {
        return error;
        throw new Error('Error fetching user data: ' + error.message);
    }
}


export const CustodayTotalReturn = async (custodayId:number) => {
    try {
        const response = await axios.get(BASE_URL + `/api/Custodays/custodayTotalReturn/${custodayId}`, { headers: { Authorization: getAuthToken() } });
        return response;
    } catch (error: any) {
        return error;
        throw new Error('Error fetching user data: ' + error.message);
    }
}

export const CustodayPartialReturn = async (custodayId:number,elements:elementsRequest[]) => {
    try {
        const response = await axios.post(BASE_URL + `/api/Custodays/CustodayPartialReturn/${custodayId}`,elements, { headers: { Authorization: getAuthToken() } });
        return response;
    } catch (error: any) {
        return error;
        throw new Error('Error fetching user data: ' + error.message);
    }
}

export const GetElementById = async (elementId:number) => {
    try {
        const response = await axios.get(BASE_URL + `/api/Elements/GetElementById/${elementId}`, { headers: { Authorization: getAuthToken() } });
        return response;
    } catch (error: any) {
        return error;
        throw new Error('Error fetching user data: ' + error.message);
    }
}


export const AddCustoday = async (custodayObj:CustodayRequestObj) => {
    try {
        const response = await axios.post(BASE_URL + `/api/Custodays/AddCustoday`,custodayObj, { headers: { Authorization: getAuthToken() } });
        return response;
    } catch (error: any) {
        return error;
        throw new Error('Error fetching user data: ' + error.message);
    }
}


export const GetCustodayById = async (custodayId:number) => {
    try {
        const response = await axios.get(BASE_URL + `/api/Custodays/GetCustodayById/${custodayId}`, { headers: { Authorization: getAuthToken() } });
        return response;
    } catch (error: any) {
        return error;
        throw new Error('Error fetching user data: ' + error.message);
    }
}

export const GetCustodayByIdForEmployee = async (custodayId:number) => {
    try {
        const response = await axios.get(BASE_URL + `/api/Custodays/GetCustodayByIdForEmployee/${custodayId}`, { headers: { Authorization: getAuthToken() } });
        return response;
    } catch (error: any) {
        return error;
        throw new Error('Error fetching user data: ' + error.message);
    }
}


export const GetUserTasks = async (taskObj:TaskCustodayObj) => {
    try {
        const response = await axios.post(BASE_URL + `/api/Users/Select/User/Tasks`,taskObj, { headers: { Authorization: getAuthToken() } });
        return response;
    } catch (error: any) {
        return error;
        throw new Error('Error fetching user data: ' + error.message);
    }
}

// export const GetUserTasks = async (taskObj:TaskCustodayObj) => {
//     try {
//         const response = await axios.post(BASE_URL + `/api/Custodays/GetUserTasks/${taskObj.prjId}`,taskObj, { headers: { Authorization: getAuthToken() } });
//         console.log(response)
//         return response;
//     } catch (error: any) {
//         return error;
//         throw new Error('Error fetching user data: ' + error.message);
//     }
// }



export const SearchProject = async (name:string) => {
    try {
        const response = await axios.get(BASE_URL + `/api/Custodays/GetProjectByName/${name}`, { headers: { Authorization: getAuthToken() } });
        return response;
    } catch (error: any) {
        return error;
        throw new Error('Error fetching user data: ' + error.message);
    }
}


export const GetAllCustodys = async () => {
    try {
        const response = await axios.get(BASE_URL + `/api/Custodays/GetAllCustodys`, { headers: { Authorization: getAuthToken() } });
        return response;
    } catch (error: any) {
        return error;
        throw new Error('Error fetching user data: ' + error.message);
    }
}



export const CustodysAdoption  = async (id:number) => {
    try {
        const response = await axios.get(BASE_URL + `/api/Custodays/ApproveCustoday/${id}`, { headers: { Authorization: getAuthToken() } });
        return response;
    } catch (error: any) {
        return error;
        throw new Error('Error fetching user data: ' + error.message);
    }
}


export const CustodyRejection  = async (id:number) => {
    try {
        const response = await axios.get(BASE_URL + `/api/Custodays/RefuseCustoday/${id}`, { headers: { Authorization: getAuthToken() } });
        return response;
    } catch (error: any) {
        return error;
        throw new Error('Error fetching user data: ' + error.message);
    }
}

export const GetCustodayPdf  = async (id:number) => {
    try {
        const response = await axios.get(BASE_URL + `/api/Custodays/ExportPdf/${id}`, { headers: { Authorization: getAuthToken() } ,responseType: 'blob'});
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        saveAs(pdfBlob, `custoday${id}.pdf`);
        return response;
    } catch (error: any) {
        return error;
        throw new Error('Error fetching user data: ' + error.message);
    }
}


export const SplitCustodaysOnTasks = async (requestObj:SplitCustodaysOnTasksObj) => {
    try {
        const response = await axios.post(BASE_URL + `/api/Custodays/SplitCustodaysOnTasks`,requestObj, { headers: { Authorization: getAuthToken() } });
        return response;
    } catch (error: any) {
        return error;
        throw new Error('Error fetching user data: ' + error.message);
    }
}