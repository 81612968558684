import {
  Badge,
  BadgeProps,
  Box,
  BoxProps,
  HStack,
  Spacer,
  Text,
  TextProps,
  chakra,
} from '@chakra-ui/react';
import { ActionsMenu, CustomMenu } from '../menus';

interface CustomHeaderProps {
  title: string;
  titleProps?: TextProps;
  badge?: string;
  badgeProps?: BadgeProps;
  showAcronym?: boolean;
  acronymProps?: BoxProps;
  actionsMenu?: ActionsMenu[];
}

export const CustomCardHeader: React.FC<CustomHeaderProps> = ({
  badge,
  actionsMenu,
  title,
  showAcronym = true,
  badgeProps,
  titleProps,
  acronymProps,
}) => {
  return (
    <HStack spacing={2} mb={3}>
      {showAcronym && <CardAcronym title={title} {...acronymProps} />}
      <Text fontSize={'1.3rem'} fontWeight={600} {...titleProps}>
        {title}
      </Text>
      {!actionsMenu && <Spacer />}
      {badge && <CardBadge {...badgeProps}>{badge}</CardBadge>}
      {actionsMenu && (
        <>
          <Spacer />
          <CustomMenu actionsMenu={actionsMenu} />
        </>
      )}
    </HStack>
  );
};

interface CardAcronymProps extends BoxProps {
  title: string;
}

export const CardAcronym: React.FC<CardAcronymProps> = ({
  title,
  ...props
}) => {
  const titleStr = title?.split(' ').reduce((prev, cur) => {
    return prev + cur[0];
  }, '');

  return (
    <Box
      p={2}
      bg='#4B01DC'
      color={'#fff'}
      borderRadius={'5px'}
      fontWeight={700}
      fontSize={'md'}
      {...props}
    >
      {titleStr}
    </Box>
  );
};

interface CardBadgeProps extends BadgeProps {}

export const CardBadge: React.FC<CardBadgeProps> = ({ children, ...props }) => {
  return (
    <Badge
      bg='#ECF0FF'
      color='#4B01DC'
      fontSize={'sm'}
      fontWeight={500}
      textTransform={'capitalize'}
      {...props}
    >
      {children}
    </Badge>
  );
};

interface KeyValueProps extends TextProps {
  name: string;
  value: number | string | null | undefined;
}

export const KeyValue: React.FC<KeyValueProps> = ({
  name,
  value,
  ...props
}) => {
  return (
    <Text fontWeight={600} fontSize={'sm'} color='#5C5C5C' {...props}>
      <chakra.span color={'#000'}>{name}: </chakra.span>
      {value}
    </Text>
  );
};

interface CardTextProps extends TextProps {}

export const CardText: React.FC<CardTextProps> = ({ children, ...props }) => {
  return (
    <Text color='#5C5C5C' fontWeight={400} fontSize={'sm'} mb={2} {...props}>
      {children}
    </Text>
  );
};
