import {
  Avatar,
  Button,
  Image,
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { addIcon } from '../../assests/icons';
import { useAppContext } from '../../contexts/app-context';
import { useTranslate } from '../../hooks/langs-hooks';
import { ITask } from '../../types';
import { diffInDays, displayDate } from '../../utils/date-utils';
import { isStaff, isSystemUser } from '../../utils/priviliages';
import { KeyValue } from '../cards/common';
import { TimeCount } from '../counts';
import { TaskActionsMenu } from '../menus/task-menu';
import TaskCard from './TaskCard';
import TaskCategory from './TaskCategory';

interface TasksListsProps {
  displayType: string;
  ongoingTasks: ITask[];
  upcomingTasks: ITask[];
  completedTasks: ITask[];
  uncompletedTasks: ITask[];
  showProjectDetails?: boolean;
  showClientDetails?: boolean;
}

const TasksLists: React.FC<TasksListsProps> = ({
  displayType,
  upcomingTasks,
  completedTasks,
  uncompletedTasks,
  ongoingTasks,
  showProjectDetails = false,
  showClientDetails = false,
}) => {
  const t = useTranslate();
  const { user: me } = useAppContext();

  const categories = [
    {
      id: 4,
      name: t('text-uncompleted'),
      tasks: uncompletedTasks,
      bg: '#FDECE5',
      color: '#ED5221',
    },
    {
      id: 2,
      name: t('text-ongoing'),
      tasks: ongoingTasks,
      bg: '#DFCEFF',
      color: '#2C0182',
    },
    {
      id: 1,
      name: t('text-upcoming'),
      tasks: upcomingTasks,
      bg: '#FDECE5',
      color: '#ED5221',
    },
    {
      id: 3,
      name: t('text-completed'),
      tasks: completedTasks,
      bg: '#E4FEE0',
      color: '#28B411',
    },
  ];

  const isEmployee = isSystemUser(me);

  return (
    <>
      {displayType === 'grid' ? (
        <SimpleGrid gap={4} columns={[1, 2, 3,4]}>
          {categories.map((cat) => (
            <VStack key={cat.id}>
              <AnimatePresence>
                <TaskCategory title={cat.name} bg={cat.bg} color={cat.color} />
                {cat.tasks?.map((task) => (
                  <TaskCard key={task.taskId} task={task}>
                    {showProjectDetails && (
                      <>
                        <KeyValue
                          name={t('text-project-id')}
                          value={task.projectId}
                        />
                        <KeyValue
                          name={t('text-project-name')}
                          value={task.projectName}
                        />
                      </>
                    )}
                    {showClientDetails && (
                      <>
                        <KeyValue
                          name={t('text-client-id')}
                          value={task.clientId}
                        />
                        <KeyValue
                          name={t('text-client-name')}
                          value={task.clientName}
                        />
                      </>
                    )}
                  </TaskCard>
                ))}
              </AnimatePresence>
            </VStack>
          ))}
        </SimpleGrid>
      ) : (
        <TableContainer>
          <Table size='sm'>
            <Thead>
              <Tr>
                <Th>{t('text-task-name')}</Th>
                {isEmployee && (
                  <>
                    <Th>{t('text-created-by')}</Th>
                    <Th>{t('text-deadline')}</Th>
                    <Th>{t('text-start-date')}</Th>
                    <Th>{t('text-end-date')}</Th>
                    <Th>{t('text-finish-time')}</Th>

                  </>
                )}
                <Th textAlign='center'>{t('text-actions')}</Th>
              </Tr>
            </Thead>
            <Tbody fontSize='md' fontWeight={600}>
              {categories.map((cat) => (
                <React.Fragment key={cat.id}>
                  <Tr bg={cat.bg} color={cat.color}>
                    <Td>{cat.name}</Td>
                    {isEmployee && (
                      <>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                      </>
                    )}
                    <Td></Td>
                  </Tr>
                  {cat.tasks?.map((task) => (
                    <Tr key={task.taskId}>
                      <Td display='flex' alignItems='center' gap={1}>
                        <Avatar size='sm' src={task.taskImage} />
                        {task.taskName}
                      </Td>
                      {isEmployee && (
                        <>
                          <Td>
                            {isEmployee
                              ? task.taskMembers?.[0].userName
                              : task.departmentName}
                          </Td>
                          <Td>
                            {task.taskStatus ? (
                              t('text-completed')
                            ) : (
                              <TimeCount
                                count={diffInDays(
                                  new Date(),
                                  task.taskFinishTime
                                )}
                              />
                            )}
                          </Td>
                          <Td>{displayDate(task.taskStartTime)}</Td>
                          <Td>{displayDate(task.taskFinishTime)}</Td>
                          <Td>{displayDate(task.finishTime)}</Td>
                        </>
                      )}
                      <Td textAlign='center'>
                        <TaskActionsMenu task={task} />
                      </Td>
                    </Tr>
                  ))}
                </React.Fragment>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default TasksLists;
