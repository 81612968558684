import React from 'react'
import MainContainer from '../../components/MainContainer'
import { Button, Stack, Text } from '@chakra-ui/react'
import { useTranslate } from '../../hooks/langs-hooks'

interface HRProps {
    employeeId?: number;
}

export const WarehouseDashboard: React.FC<HRProps> = () => {
    const t = useTranslate();
    return (
        <>
            <MainContainer>
                <Stack display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexDirection={'row'} bgColor={'#f3f5fa'} p={5}>
                    <Text fontWeight={900} fontSize='2.25rem' mb={1} p={6}>
                        {t('text-warehouse')}
                    </Text>
                    <Button colorScheme='blue' variant='solid'>
                        Button
                    </Button>
                </Stack>
            </MainContainer >
        </>
    )
}
