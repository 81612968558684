import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  BoxProps,
  HStack,
  Icon,
  Image,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { MdOutlineDownloadDone, MdSettings } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useMe } from '../../contexts/app-context';
import { useTranslate } from '../../hooks/langs-hooks';
import { fadeInBottom } from '../../lib/framer-motion/fade-in-bottom';
import { ITask } from '../../types';
import { BASE_URL } from '../../utils/constants';
import { diffInDays, displayDate } from '../../utils/date-utils';
import { isSystemUser } from '../../utils/priviliages';
import { KeyValue } from '../cards/common';
import { FileCount, TimeCount } from '../counts';
import { TaskActionsMenu } from '../menus/task-menu';
import { TimeIcon } from '@chakra-ui/icons';

interface TaskCardProps extends BoxProps {
  showImage?: boolean;
  children?: React.ReactNode;
  task: ITask;
}

const TaskCard: React.FC<TaskCardProps> = ({
  task,
  showImage = true,
  children,
  ...props
}) => {
  const t = useTranslate();
  const me = useMe();

  const isAuthed = isSystemUser(me);

  const {
    clientId,
    clientName,
    departmentName,
    taskDescription,
    taskFiles,
    taskFinishTime,
    taskId,
    taskImage,
    taskMembers,
    taskName,
    taskStartTime,
    taskStatus,
    projectDescription,
    projectId,
    projectName,
    finishTime,
  } = task;

  return (
    <Box
      as={motion.div}
      bg='#fff'
      boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
      p='2'
      borderRadius='6px'
      w='100%'
      {...fadeInBottom()}
      {...props}
    >
      {showImage && taskImage && (
        <Image
          src={BASE_URL + taskImage}
          borderRadius='6px'
          w='100%'
          h='10.5rem'
          mb={2}
        />
      )}
      <HStack mb='2'>
        <Text
          color='#181818'
          fontWeight={700}
          fontSize='lg'
          {...(me?.privileges?.viewTask
            ? { as: Link, to: `/tasks/${taskId}` }
            : {})}
        >
          {taskName}
        </Text>
        {taskStatus && (
          <Icon
            as={MdOutlineDownloadDone}
            color={'green'}
            fontSize={'1.6rem'}
          />
        )}



        <Spacer />
        <VStack alignItems={'end'}>
          {/* {(Date.parse(taskStartTime) < Date.now() && Date.parse(taskFinishTime) > Date.now()) &&  */}
            <TaskActionsMenu
            task={{
              taskId,
              taskDescription,
              taskFiles,
              taskFinishTime,
              taskImage,
              taskMembers,
              taskName,
              taskStartTime,
              taskStatus,
              departmentName,
              projectId,
              clientId,
              clientName,
              finishTime,
              taskUserInserted: task.taskUserInserted
            }}
          />
          {/* } */}
          
          <HStack>
            <Badge color={'blue'}>Research</Badge>
            <Badge color={'blue'}>UX</Badge>
            <Badge color={'blue'}>Design</Badge>
          </HStack>
        </VStack>
      </HStack>
      {isAuthed && (
        <Text
          color='#5C5C5C'
          fontSize='sm'
          fontWeight={400}
          mb='2'
          fontFamily='Inter'
        >
          {taskDescription}
        </Text>
      )}

      {isAuthed && (
        <HStack alignItems={'center'}>
          {/* <Text
          color='#181818'
          fontSize='sm'
          fontWeight={400}
          // mb='2'
          fontFamily='Inter'
        >
          0 {t('text-days')}
        </Text> */}
          {!taskStatus && (
            <TimeCount count={diffInDays(new Date(), taskFinishTime)} />
          )}
        </HStack>
      )}

      {isAuthed && (
        <>
          {/* <VStack alignItems={'flex-start'} gap={0}>
            {children}
            <KeyValue
              name={t('text-start-time')}
              value={displayDate(taskStartTime)}
            />
            <KeyValue
              name={t('text-end-time')}
              value={displayDate(taskFinishTime)}
            />
            
          </VStack> */}
          <HStack mt={2}>
      
            {/* <AvatarGroup size='md' max={2} color={'blue'}>
              <Avatar name='Ryan Florence' src='https://bit.ly/ryan-florence' ignoreFallback={false} />
              <Avatar name='Segun Adebayo' src='https://bit.ly/sage-adebayo' />
              <Avatar name='Kent Dodds' src='https://bit.ly/kent-c-dodds' />
              <Avatar name='Prosper Otemuyiwa' src='https://bit.ly/prosper-baba' />
              <Avatar name='Christian Nwamba' src='https://bit.ly/code-beast' />
            </AvatarGroup> */}
            <Spacer />
            <FileCount count={taskFiles.length} />
            {/* <Spacer />
            {!taskStatus && (
              <TimeCount count={diffInDays(new Date(), taskFinishTime)} />
            )} */}
          </HStack>
        </>
      )}
    </Box>
  );
};

export default TaskCard;

interface TaskActionsMenuProps { }
