import { AddIcon, DeleteIcon, DragHandleIcon } from '@chakra-ui/icons'
import { Button, Heading, Icon, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslate } from '../../hooks/langs-hooks'
import { CustodayObj, ElementObj, ElementObjResponse, ElementResponse, elementsRequest, ReturnItemObj } from '../../types'
import { CustodayPartialReturn, CustodayTotalReturn } from '../../data/client/endpoints/Covenant/Covenant.endPoint'
import useNotification from '../../hooks/use-notification-hook'

export const ReturnProcessModal: React.FC<{ info: CustodayObj }> = ({ info }) => {
    const t = useTranslate();
    const notify = useNotification();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [returnItem, setReturnItem] = useState<ReturnItemObj[]>([]);
    const [elementArr,setElementArr] = useState<ElementObjResponse[]>([]);
    const [status, setStatus] = useState<string>(t('text-salim'));
    const [returnType, setReturnType] = useState<number>();
    const [returnQuantity,setReturnQuantity] = useState<number>(0);
    const [error,setError] = useState<string>("");
    const [isLoading,setIsLoading] = useState<boolean>(false);


    const handleAddBtn = (ele:ElementResponse) => {
        var newElement: ReturnItemObj = {
            id:ele.id,
            name: ele.name,
            category: ele.category,
            returnQuantity: returnQuantity,
            status: status,
            price: ele.price !== undefined ? ele.price : 0,
        }
        setReturnItem([...returnItem, newElement]);
        setReturnQuantity(0);
        const filteredArray = elementArr.filter(element => element.resourceId !== ele.id);
        setElementArr(filteredArray);
    }

// last updated
    const handleSaveBtn = async () => {
        if (returnType === 0) {
            try {
                setIsLoading(true)
                var data = await CustodayTotalReturn(info.id);
                if (data.status === 200) {
                    notify.success("success operation");
                    setIsLoading(false)
                    onClose();
                }
                else {
                    notify.error(data.response.data);
                    setIsLoading(false);
                }
            } catch (error) {
                notify.error("server error");
                setIsLoading(false);
            }
        }else {
            try {
                setIsLoading(true)
                var data = await CustodayPartialReturn(info.id,transformToElementsRequest(returnItem));
                if(data.status == 200){
                    notify.success("success operation");
                    setIsLoading(false);
                    onClose();
                }else {
                    notify.error(data.response.data);
                    setIsLoading(false);
                }
            } catch (error) {
                notify.error("server error");
                setIsLoading(false);
            }
        }
    }
    const transformToElementsRequest = (returnItems: ReturnItemObj[]): elementsRequest[] => {
        return returnItems.map(item => ({
          id: item.id,
          quantity: item.returnQuantity,
        }));
      };
    const removeObject = (idToRemove: number) => {
        const filteredArray = returnItem.filter(element => element.id !== idToRemove);
        setReturnItem(filteredArray);
      };

      const handleQuantityInput = (value:number,quantity:number) => {
        if(value > quantity)
            notify.error("Please enter a value less than the available quantity");
        else{
            setReturnQuantity(value);
        }
      }
    return (
        <>
            <Icon as={DragHandleIcon} boxSize={6} cursor={'pointer'} onClick={!info.isReturned ? onOpen: ()=>{}} />
            <Modal size={'5xl'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Heading>{t('text-recovery-process')}</Heading>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody p={5}>
                        <TableContainer mb={5}>
                            <Table variant='striped' colorScheme='blue'>
                                <Tbody>
                                    <Tr>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {t('text-custody-number')}
                                            </Heading>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {info.id}
                                            </Heading>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {t('text-date-custody-trust')}
                                            </Heading>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {info.startDate?.toString().split('T')[0]}
                                            </Heading>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {t('text-return-date')}
                                            </Heading>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {info.returnDate?.toString().split('T')[0]}
                                            </Heading>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {t('text-type-custody')}
                                            </Heading>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {info.type}
                                            </Heading>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td textAlign={'center'}>
                                            <Heading as='h5' size='sm'>
                                                {t('text-return-type')}
                                            </Heading>
                                        </Td>
                                        <Td textAlign={'-moz-initial'}>
                                            <Select placeholder={t('text-return-type')} width={'150px'} onChange={e => setReturnType(+e.target.value)}>
                                                <option value={0}>{t('text-total-recovery')}</option>
                                                <option value={1}>{t('text-partial-refund')}</option>
                                            </Select>
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                        {
                            info.resources.length > 0 &&
                            <TableContainer mb={5}>
                                <Table variant='simple'>
                                    <Thead bgColor={'#d8f1d4'}>
                                        <Tr>
                                            <Th textAlign={'center'}>{t('text-item-number')}</Th>
                                            <Th textAlign={'center'}>{t('text-item-name')}</Th>
                                            {/* <Th textAlign={'center'}>{t('text-category')}</Th> */}
                                            <Th textAlign={'center'}>{t('text-quantity')}</Th>
                                            <Th textAlign={'center'}>{t('text-price')}</Th>
                                            {/* <Th textAlign={'center'}>{t('text-total')}</Th> */}
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            info.resources.map((ele, index) => (
                                                <Tr key={index}>
                                                    <Td textAlign={'center'}>{ele.id}</Td>
                                                    <Td textAlign={'center'}>{ele.name}</Td>
                                                    {/* <Td textAlign={'center'}>{ele.category}</Td> */}
                                                    <Td textAlign={'center'}>{ele.quantity}</Td>
                                                    <Td textAlign={'center'}>{ele.price}</Td>
                                                    {/* <Td textAlign={'center'}>{}</Td> */}
                                                </Tr>
                                            ))
                                        }

                                    </Tbody>
                                </Table>
                            </TableContainer>
                        }

                        <Heading as='h4' size='md' mb={5}>
                            {t('text-recovered-items')}
                        </Heading>
                        {
                            info.resources.length > 0 &&
                            <TableContainer mb={8}>
                                <Table variant='simple'>
                                    <Thead bgColor={'#f0f0f0'}>
                                        <Tr>
                                            <Th textAlign={'center'}>{t('text-item-name')}</Th>
                                            {/* <Th textAlign={'center'}>{t('text-category')}</Th> */}
                                            <Th textAlign={'center'}>{t('text-quantity')}</Th>
                                            <Th textAlign={'center'}>{t('text-return-quantity')}</Th>
                                            <Th textAlign={'center'}>{t('text-status')}</Th>
                                            <Th textAlign={'center'}>{t('text-price')}</Th>
                                            <Th textAlign={'center'}>{t('text-add')}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            info.resources.map((ele, index) => (
                                                <Tr key={index}>
                                                    <Td textAlign={'center'}>{ele.name}</Td>
                                                    {/* <Td textAlign={'center'}>{ele.category}</Td> */}
                                                    <Td textAlign={'center'}>{ele.availableQuantity}</Td>
                                                    <Td textAlign={'center'}>
                                                        <Input width={'100px'} color={'red'} htmlSize={5} bgColor={'white'} type={"number"} onChange={(e)=>handleQuantityInput(+e.target.value,ele.quantity)}/>
                                                    </Td>
                                                    <Td textAlign={'center'}>
                                                        <Select placeholder={t('text-status')} width={'150px'} onChange={e => setStatus(e.target.value)}>
                                                            <option value={t('text-salim')}>{t('text-salim')}</option>
                                                            <option value={t('text-consists')}>{t('text-consists')}</option>
                                                        </Select>
                                                    </Td>
                                                    <Td textAlign={'center'}>{ele.price}</Td>
                                                    <Td textAlign={'center'}>
                                                        <Button onClick={()=>handleAddBtn(ele)} leftIcon={<AddIcon />} colorScheme='gray' variant='solid'>
                                                            {t('text-add')}
                                                        </Button>
                                                    </Td>
                                                </Tr>
                                            ))
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        }
                        <TableContainer>
                            <Table variant='simple'>
                                <Thead bgColor={'#f0f0f0'}>
                                    <Tr>
                                        <Th textAlign={'center'}>{t('text-item-name')}</Th>
                                        <Th textAlign={'center'}>{t('text-category')}</Th>
                                        <Th textAlign={'center'}>{t('text-return-quantity')}</Th>
                                        <Th textAlign={'center'}>{t('text-status')}</Th>
                                        <Th textAlign={'center'}>{t('text-price')}</Th>
                                        <Th textAlign={'center'}>{t('text-remove')}</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        returnItem.map((ele, index) => (
                                            <Tr key={index}>
                                                <Td textAlign={'center'}>{ele.name}</Td>
                                                <Td textAlign={'center'}>{ele.category}</Td>
                                                <Td textAlign={'center'}>{ele.returnQuantity}</Td>
                                                <Td textAlign={'center'}>{ele.status}</Td>
                                                <Td textAlign={'center'}>{ele.price}</Td>
                                                <Td textAlign={'center'}>
                                                    <DeleteIcon w={8} h={8} color="red.500" cursor={'pointer'} onClick={()=>removeObject(ele.id)} />
                                                </Td>
                                            </Tr>
                                        ))
                                    }
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            {t('text-close')}
                        </Button>
                        <Button isLoading={isLoading} variant='ghost' onClick={handleSaveBtn}>{t('text-save')}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}