import {
  Event,
  IActivityItem,
  IBranch,
  IDepartment,
  IEntityGoal,
  IEvent,
  IGetActivityLogsOptions,
  IPaginatedResult,
} from '../../../types';
import { BASE_URL } from '../../../utils/constants';
 
import { getAuthToken } from '../../../utils/storage';
import { getUserFromSessionStorage } from '../../../utils/storage';
// const UserId = getUserFromSessionStorage()?.userId;
import api from '../http-client';

export const getAllBranches = async () => {
  const { data } = await api.get<IBranch[]>(BASE_URL+'/api/Projects/Select/All/Branchs', {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return data;
};

export const getAllDepartmentsInBranch = async (branchId?: number | string) => {
 
  const { data } = await api.get<IDepartment[]>(
    BASE_URL+`/api/Projects/Select/All/Department/${branchId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return data;
};

export const createEvent = async (event: Event) => {
  const res = await api.post<string>(
    BASE_URL+`/api/Users/Insert/Event/And/List/Users`,
    event
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data;
};

export const fetchEventsForUser = async (data: {
  userId: number;
  eventDateTime: Date;
}) => {
  const res = await api.post<IEvent[]>(
    BASE_URL+`/api/Users/Select/Event/For/User`,
    data
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data;
};

export const getDashboardStatistics = async () => {
  const { data } = await api.get<{
    completedProjectsCount: number;
    completedTaskCount: number;
    entityGoolCount: number;
    projectsCount: number;
    delayProjectsCount: number;
    taskCount: number;
    delayTaskCount: number;
    employeeCount: number;
    userCount: number;
    clientCount: number;
    clientsHaveProjectCount: number;
    clientsDontHaveProjectCount: number;
  }>(BASE_URL+`/api/Projects/Select/Dashpord/Count/Cards`
  , {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return data;
};

export const getEntityGoal = async () => {
  const { data } = await api.get<{ EntityGool: IEntityGoal[] }>(
    BASE_URL+`/api/Users/Select/Entity/Gools`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  let Gools = data.EntityGool?.[0].Gools;
  if (!Gools[0].goolId) Gools = [];

  return {
    ...data.EntityGool?.[0],
    Gools,
  };
};

export const editEntityGoal = async ({
  unsavedGoals,
  entityGoal,
}: {
  unsavedGoals: string[];
  entityGoal: {
    entityGoolId: number;
    sectionTitle: string;
    sectionParagraph: string;
    entityGool: string;
    journyParagraph: string;
  };
}) => {
  if (unsavedGoals.length) {
    const data = unsavedGoals.map((goolText) => {
      return {
        entityGoolId: 1,
        goolText,
      };
    });
    const res = await api.post<string>(BASE_URL+`/api/Users/Insert/Gool`, data  , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  }
  const res = await api.put<string>(
    BASE_URL+ `/api/Users/Update/EntityGool/${entityGoal.entityGoolId}`,
    entityGoal
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data;
};

export const deleteGoal = async (goalId: number | string) => {
  const res = await api.delete<string>(
    BASE_URL+`/api/Users/Entity/Gool/Deleted/${goalId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data;
};

export const createBranch = async (branchName: string) => {
  const res = await api.post<string>(BASE_URL+`/api/Projects/Insert/Branch`, {
    bramchName: branchName,
  }, {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return res.data;
};

export const getBranchesKPI = async () => {
  const { data } = await api.get<
    {
      branchName: string;
      branchProjectCount: number;
      branchCompletedProject: number;
    }[]
  >(BASE_URL+`/api/Projects/Project/KPI`, {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return data;
};

export const getActivityLogs = async ({
  limit,
  page,
  ...rest
}: IGetActivityLogsOptions) => {
  const { data } = await api.post<IPaginatedResult<IActivityItem>>(
    BASE_URL+`/api/Projects/Activity/Log/${page}/${limit}`,
    rest
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  return {
    logs: data.item2,
    numberOfPages: data.item1.pageCount,
    totalCount: data.item1.rawCount,
  };
};

export const deleteBranch = async (branchId?: number | string) => {
  const res = await api.delete<string>(
    BASE_URL+ `/api/Projects/Delete/Branch/${branchId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data;
};
