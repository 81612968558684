import { BoxProps, HStack, Image, Spacer, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { hexToCSSFilter } from 'hex-to-css-filter';
import { addIcon } from '../../assests/icons';
import { fadeInBottom } from '../../lib/framer-motion/fade-in-bottom';

interface TaskCategoryProps extends BoxProps {
  title: string;
  color: string;
}

const TaskCategory: React.FC<TaskCategoryProps> = ({ title, ...props }) => {
  return (
    <HStack
      as={motion.div}
      {...fadeInBottom()}
      w='100%'
      p={2}
      boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
      {...props}
    >
      <Text fontWeight={600}>{title}</Text>
      <Spacer />
      {/* <Image
        src={addIcon}
        boxSize={4}
        filter={hexToCSSFilter(props.color).filter}
      /> */}
    </HStack>
  );
};

export default TaskCategory;
