import { FormControl, FormLabel, Input, InputProps } from '@chakra-ui/react';

interface FlushedInput extends InputProps {
  label?: string;
}

const FlushedInput: React.FC<FlushedInput> = ({ label, maxW, ...props }) => {
  return (
    <FormControl maxW={maxW}>
      {label && (
        <FormLabel mb={0} fontWeight={600} fontSize='md'>
          {label}
        </FormLabel>
      )}
      <Input
        borderColor='#A6B2BE'
        color={'#B3B3B7'}
        variant='flushed'
        fontWeight={500}
        fontSize='md'
        _placeholder={{
          fontWeight: 500,
          fontSize: 'md',
          color: '#B3B3B7',
        }}
        {...props}
      />
    </FormControl>
  );
};

export default FlushedInput;
