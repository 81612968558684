import { IPaginatedResult } from '../types';

export const formatePaginatedResult = <TData>(
  data: IPaginatedResult<TData>
) => {
  return {
    numberOfPages: data.item1.pageCount,
    totalCount: data.item1.rawCount,
    items: data.item2,
  };
};
