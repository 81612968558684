import { useQuery } from '@tanstack/react-query';
import { IMyTasksOptions, Id } from '../types';
import client from './client';

export const useTask = (id: Id) => {
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ['tasks', +id],
    queryFn: () => client.tasks.get(id),
  });

  return {
    task: data,
    isLoading,
    isError,
    error,
  };
};

export const useMytasks = (options: IMyTasksOptions) => {
  const { isLoading, isError, error, data } = useQuery({
    queryKey: ['my-tasks', options],
    queryFn: ({ queryKey }) =>
      client.tasks.myTasks(queryKey[1] as IMyTasksOptions),
  });

  return {
    isLoading,
    isError,
    error,
    data: {
      upcomingTasks: data?.upcomingTask ?? [],
      ongoingTasks: data?.ongoningTask ?? [],
      completedTasks: data?.completedTask ?? [],
      uncompletedTasks: data?.unCompletedTask ?? [],
    },
  };
};


export const useMyCompletedtasks = (options: IMyTasksOptions) => {
  const { isLoading, isError, error, data } = useQuery({
    queryKey: ['my-tasks', options],
    queryFn: ({ queryKey }) =>
      client.tasks.myTasks(queryKey[1] as IMyTasksOptions),
  });

  return {
    isLoading,
    isError,
    error,
    data: {
      upcomingTasks: [],
      ongoingTasks: [],
      completedTasks: data?.completedTask ?? [],
      uncompletedTasks: data?.unCompletedTask ?? [],
    },
  };
};
