import axios, { AxiosRequestConfig } from 'axios';
import { BASE_URL } from '../../utils/constants';
import { getUserFromSessionStorage } from '../../utils/storage';
import { getToken } from 'firebase/messaging';
import { getAuthToken } from '../../utils/storage';
const api = axios.create({
  baseURL: BASE_URL,
});
// axios.interceptors.request.use(function (config) {
//   const token = getAuthToken();
//   config.headers.Authorization =  token;
   
//   return config;
// });
api.interceptors.request.use((config) => {
  config.headers.LogId = getUserFromSessionStorage()?.userId;
  const token = getAuthToken();
  config.headers.Authorization =  token;
  return config;
});

export default api;

export class HttpClient {
 
  static async get<T>(url: string, params?: unknown) {
  
    const response = await api.get<T>(url, { params });
    return response.data;
  }

  static async post<T>(
    url: string,
    data: unknown,
    config?: AxiosRequestConfig
  ) {
    const response = await api.post<T>(url, data, config);
    return response.data;
  }

  static async put<T>(url: string, data: unknown) {
    const response = await api.put<T>(url, data);
    return response.data;
  }

  static async patch<T>(
    url: string,
    data: unknown,
    config?: AxiosRequestConfig
  ) {
    const response = await api.patch<T>(url, data, config);
    return response.data;
  }

  static async delete<T>(url: string, config?: AxiosRequestConfig) {
    const response = await api.delete<T>(url, config);
    return response.data;
  }
}
