import { AddIcon, DeleteIcon, Search2Icon } from '@chakra-ui/icons'
import { Avatar, Button, FormHelperText, Heading, Input, InputGroup, InputLeftElement, InputRightAddon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslate } from '../../hooks/langs-hooks'
import { CovenantCategoryObj, CustodayObj, CustodayRequestObj, ElementObjResponse, ResourceRequest } from '../../types'
import { useAppContext } from '../../contexts/app-context'
import { AddCustoday, GetElementById, SearchProject } from '../../data/client/endpoints/Covenant/Covenant.endPoint'
import useNotification from '../../hooks/use-notification-hook'

interface AddOfficialVacationProps {
    onDataFromChild: () => void;
  }

export const CovenantRequest:React.FC<AddOfficialVacationProps> = ({onDataFromChild}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { user: me } = useAppContext();
    const t = useTranslate();
    const notify = useNotification();
    const [categories, setCategories] = useState<CovenantCategoryObj[]>([]);
    const [id, setId] = useState<number>(0);
    const [type, setType] = useState<string>(t('text-screen'));
    const [category, setCategory] = useState<string>(t('text-electronics'));
    const [description, setDescription] = useState<string>(t('text-electronic-device'));
    const [quantity, setQuantity] = useState<number>(0);
    const [date, setDate] = useState<Date>(new Date(0));
    const [prjNo, setPrjNo] = useState<number>(0);
    const [elementObjArr, setElementObjArr] = useState<ElementObjResponse[]>([]);
    const [elementObj, setElementObj] = useState<ElementObjResponse>();
    const [proName, setProName] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);



    const handleAddElementBtn = async (element: any) => {
        if (quantity > element.quantity) {
            notify.error("Please check your data")
        } else {
            var newElement: CovenantCategoryObj = {
                resourceId: element.resourceId,
                resourceName: element.resourceName,
                category: element.category,
                quantity: quantity,
                price: element.price,
                proudctNumber: element.proudctNumber
            }
            setCategories([...categories, newElement]);
            setQuantity(0);
        }
    }
    const handleElementIdInput = async (value: any) => {
        var data = await GetElementById(value);
        setElementObj(data.data)
    }
    const removeObject = (idToRemove: number) => {
        const filteredArray = categories.filter(element => element.resourceId !== idToRemove);
        setCategories(filteredArray);
    };
    const handleSubmitBtn = async () => {
        let resourceRequests: ResourceRequest[] = categories.map((obj) => ({
            id: obj.resourceId,
            quantity: obj.quantity,
        }));
        var requestObj: CustodayRequestObj = {
            typeId: 3,
            startDate: date,
            projectId: prjNo,
            resources: resourceRequests
        }
        try {
            setIsLoading(true)
            var data = await AddCustoday(requestObj);
            if (data.status === 200) {
                onClose();
                setIsLoading(false);
                onDataFromChild();
                notify.success("success operation")
            } else {
                setIsLoading(false)
                notify.error(data.data.response)
            }
        } catch (error) {
            setIsLoading(false)
            notify.error("server error")
        }
    }
    const handleSearchInput = async (e: any) => {
        if (e === "") {
            setPrjNo(0);
        } else {
            var data = await SearchProject(e);
            if (data.status === 200)
                setPrjNo(data.data.projectId);
            else
                setPrjNo(0);
        }
    }
    return (
        <>
            <Button onClick={onOpen} leftIcon={<AddIcon />} colorScheme='blue' variant='solid'>
                {t('text-request-custody')}
            </Button>
            <Modal size={'5xl'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader><Heading>{t('text-covenant-Cashing')}</Heading></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody p={7}>
                        <Stack display={'flex'} justifyContent={'space-around'} alignItems={'center'} flexDirection={'row'} mb={5}>
                            {/* <Stack>
                                <Text fontSize='lg'>{t('text-custody-exchange-number')}</Text>
                                <Input type='string' />
                            </Stack> */}
                            <Stack>
                                <Text fontSize='lg'>{t('text-applicant')}</Text>
                                <Stack display={'flex'} justifyContent={'space-around'} alignItems={'center'} flexDirection={'row'}>
                                    <Avatar borderRadius={4} name={me?.userName} src={me?.userImage ?? 'https://res.cloudinary.com/newbookify/image/upload/v1716961436/%D8%A7%D9%81%D8%AA%D8%A7%D8%B1%D9%A4_bi1szj.png'} />
                                    <Input type='string' disabled value={me?.userName} />
                                </Stack>
                            </Stack>
                            <Stack>
                                <Text fontSize='lg'>{t('text-exchange-date')}</Text>
                                <Input placeholder={t('text-exchange-date')} size='lg' type='date' width={'315px'} onChange={e => setDate(new Date(e.target.value))} />
                            </Stack>
                        </Stack>

                        <Stack display={'flex'} justifyContent={'space-around'} alignItems={'center'} flexDirection={'row'} mb={5}>
                            {/* <Stack>
                                <Text fontSize='lg'>{t('text-type-custody')}</Text>
                                <Select placeholder={t('text-type-custody')} width={'270px'}>
                                    <option value='option1'>{t('text-sustainable')}</option>
                                    <option value='option2'>{t('text-consumerism')}</option>
                                    <option value='option3'>{t('text-fragmented')}</option>
                                </Select>
                            </Stack> */}
                        </Stack>
                        <Stack display={'flex'} justifyContent={'space-around'} alignItems={'flex-start'} flexDirection={'row'} mb={5}>
                            <Stack>
                                <Text fontSize='lg'>{t('text-project-name')}</Text>
                                <InputGroup borderRadius={5} size="md">
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<Search2Icon color="gray.600" />}
                                    />
                                    <Input type="text" placeholder="Search..." border="1px solid #949494" onChange={(e) => handleSearchInput(e.target.value)} />
                                    <InputRightAddon
                                        p={0}
                                        border="none"
                                    >
                                    </InputRightAddon>
                                </InputGroup>
                                <Text color='green'>{t('text-spend-pledge-project-without-project')}</Text>
                            </Stack>
                            <Stack>
                                <Text fontSize='lg'>{t('text-prj-num')}</Text>
                                <Input type='string' width={"315px"} disabled value={prjNo} />
                            </Stack>
                        </Stack>
                        <Heading mb={3}>{t('text-Items')}</Heading>
                        <TableContainer>
                            <Table variant='striped' colorScheme='blue'>
                                <Thead>
                                    <Tr>
                                        <Th textAlign={'center'}>{t('text-item-number')}</Th>
                                        <Th textAlign={'center'}>{t('text-item-name')}</Th>
                                        {/* <Th textAlign={'center'}>{t('text-category')}</Th> */}
                                        <Th textAlign={'center'}>{t('text-available-quantity')}</Th>
                                        <Th textAlign={'center'}>{t('text-quantity')}</Th>
                                        {/* <Th textAlign={'center'}>{t('text-return-date')}</Th> */}
                                        <Th textAlign={'center'}>{t('text-add')}</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td textAlign={'center'}>
                                            <Input htmlSize={5} type={"number"} bgColor={'white'} onChange={e => handleElementIdInput(e.target.value)} />
                                        </Td>
                                        <Td textAlign={'center'}>{elementObj?.resourceName}</Td>
                                        {/* <Td textAlign={'center'}>{elementObj?.category}</Td> */}
                                        <Td textAlign={'center'}>{elementObj?.quantity}</Td>
                                        <Td textAlign={'center'}>
                                            <Input width={'100px'} color={'red'} htmlSize={5} bgColor={'white'} type={"number"} onChange={e => setQuantity(+e.target.value)} />
                                        </Td>
                                        {/* <Td textAlign={'center'}>25-12-2025</Td> */}
                                        <Td textAlign={'center'}>
                                            <Button rightIcon={<AddIcon />} colorScheme='gray' variant='solid' onClick={() => handleAddElementBtn(elementObj)}>
                                                {t('text-add')}
                                            </Button>
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                        {
                            categories.length > 0 &&
                            <TableContainer>
                                <Table variant='striped' colorScheme='blue'>
                                    <Thead>
                                        <Tr>
                                            <Th textAlign={'center'}>{t('text-item-number')}</Th>
                                            <Th textAlign={'center'}>{t('text-item-name')}</Th>
                                            {/* <Th textAlign={'center'}>{t('text-category')}</Th> */}
                                            {/* <Th textAlign={'center'}>{t('text-taskDescription')}</Th> */}
                                            <Th textAlign={'center'}>{t('text-quantity')}</Th>
                                            {/* <Th textAlign={'center'}>{t('text-price')}</Th> */}
                                            <Th textAlign={'center'}>{t('text-remove')}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            categories.map((ele, index) => (
                                                <Tr key={index}>
                                                    <Td textAlign={'center'}>
                                                        {ele.resourceId}
                                                        {/* <Input htmlSize={2} type={"number"} bgColor={'blue'} disabled value={ele.resourceId} color={'white'} /> */}
                                                    </Td>
                                                    <Td textAlign={'center'}>{ele.resourceName}</Td>
                                                    {/* <Td textAlign={'center'}>{ele.category}</Td> */}
                                                    <Td textAlign={'center'}>{ele.quantity}</Td>
                                                    {/* <Td textAlign={'center'}>{ele.price}</Td> */}
                                                    <Td textAlign={'center'}>
                                                        <DeleteIcon w={8} h={8} color="red.500" cursor={'pointer'} onClick={() => removeObject(ele.resourceId)} />
                                                    </Td>
                                                </Tr>
                                            ))
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button isLoading={isLoading} colorScheme='blue' mr={3} onClick={handleSubmitBtn}>
                            {t('text-request')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
