import { useQueryClient } from '@tanstack/react-query';
import { onMessage } from "firebase/messaging";
// import { onMessage } from 'firebase/messaging';
import { lazy, useEffect, useRef } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import CustomSuspense from './components/CustomSuspense';
import MainLayout from './components/MainLayout';
import {
  UserOrCompanyPrivateRoute,
  UserPrivateRoute,
} from './components/PrivateRoutes';
import ProtectedPage from './components/layout/protected-page';
import ModalsContainer from './components/modals/container';
import { useMe } from './contexts/app-context';
import { initNotifications, messaging } from './firebase';
import useNotification from './hooks/use-notification-hook';
import Home from './pages/Home';
import { ErrorBoundary } from './pages/error-boundary/ErrorBoundary';
import EmployeeTasks from './pages/my-tasks';
import './styles/main.scss';
import { isClient, isHr, isStaff, isSystemUser, isWarehouseManager } from './utils/priviliages';
import 'react-phone-input-2/lib/style.css';
// import jsPDF from 'jspdf';
// import htmlElementRef from './components/PDFFile';
// import html2canvas from 'html2canvas';
import { CovenantEmployee } from './pages/covenant/CovenantEmployee';
// import jsPDF from 'jspdf';
import { SplitCustody } from './components/covenant-components/SplitCustody';
import { Appropriations } from './pages/covenantAppropriations/Appropriations';
import { WarehouseDashboard } from './pages/warehouse/WarehouseDashboard';
const Resources = lazy(() => import('./pages/repo/Resources'));
const ReceivingLogs = lazy(() => import('./pages/repo/ReceivingLogs'));
const CustodyLogs = lazy(() => import('./pages/repo/custoday-logs'));
const ExchangeLogs = lazy(() => import('./pages/repo/exchange-logs'));
const Signin = lazy(() => import('./pages/auth/Signin'));
const Settings = lazy(() => import('./pages/settings/Settings'));
const EmpProfile = lazy(() => import('./pages/employees/profile/EmpProfile'));
const ProjectManager = lazy(() => import('./pages/employees/dashboard/ProjectManager'));
const CompletedTasks = lazy(() => import('./components/task/CompletedTasks'));


//hr
const HRDashboard = lazy(() => import('./pages/hr/HRDashboard'))
const HREmployee = lazy(() => import('./pages/hr/HREmployee'))

const StoreHouse = lazy(() => import('./components/storehouse-components/StoreHouses'))
const Suppliers = lazy(() => import('./components/storehouse-components/Suppliers'))
const ResourceServices = lazy(() => import('./components/storehouse-components/ResourcesServices'))
const PricingResources = lazy(() => import('./components/storehouse-components/pricingResources'))
const ExitResources = lazy(() => import('./components/storehouse-components/exitResource'))
const SupplingResources = lazy(() => import('./components/storehouse-components/SupplingResource'))
const InventoryResources = lazy(() => import('./components/storehouse-components/InventoryResource'))
const RecoveryResources = lazy(() => import('./components/storehouse-components/recoveryResource'))




const Departments = lazy(
  () => import('./pages/employees/dashboard/Departments')
);
const Projects = lazy(() => import('./pages/projects/Projects'));
const Agenda = lazy(() => import('./pages/Agenda'));
const Project = lazy(() => import('./pages/projects/project/Project'));
const HelpingCenter = lazy(
  () => import('./pages/helping-center/HelpingCenter')
);
const ForgetPassword = lazy(() => import('./pages/auth/ForgetPassword'));
const EntityGoals = lazy(() => import('./pages/entity-goals/EngityGoals'));
const Reports = lazy(() => import('./pages/reports'));
const StoreHouseReports = lazy(() => import('./pages/storehousereports'));

const Clients = lazy(() => import('./pages/Clients'));
const ClientSignin = lazy(() => import('./pages/auth/ClientSignin'));
const ActivityHistory = lazy(() => import('./pages/ActivityHistory'));
const ClientHome = lazy(() => import('./pages/client/ClientHome'));
const CompanySignin = lazy(() => import('./pages/auth/CompanySignin'));
const Task = lazy(() => import('./pages/Task'));

function App() {
  const notify = useNotification();
  const queryClient = useQueryClient();
  const me = useMe();

  useEffect(() => {
    initNotifications();
    onMessage(messaging, (payload: any) => {
      if (payload.notification) {
        const { title, body } = payload.notification;
        title && body && notify.info(title, body);
        queryClient.invalidateQueries(['notifications']);
        queryClient.invalidateQueries(['user-events']);
        queryClient.invalidateQueries(['projects']);
        queryClient.invalidateQueries(['project-tasks']);
        queryClient.invalidateQueries(['tasks']);
        queryClient.invalidateQueries(['employee-tasks']); // in profile
        queryClient.invalidateQueries(['my-tasks']);
      }
    });
  }, []);

  return (
    <ErrorBoundary >
      <Router>
        <Routes>
          <Route
            path='/signin'
            element={
              <CustomSuspense>
                <Signin />
              </CustomSuspense>
            }
          />

          <Route
            path='/forget-password'
            element={
              <CustomSuspense>
                <ForgetPassword />
              </CustomSuspense>
            }
          />

          {/* any loggedin user can view */}
          <Route
            element={
              <CustomSuspense>
                <MainLayout
                  PrivateRouteWrapper={UserOrCompanyPrivateRoute}
                  bg='#F1F1FF'
                />
              </CustomSuspense>
            }
          >
            <Route path='/' element={<Home />} />
          </Route>
          <Route
            element={
              <CustomSuspense>
                <MainLayout PrivateRouteWrapper={UserOrCompanyPrivateRoute} />
              </CustomSuspense>
            }
          >
            <Route path='/entity-goals' element={<EntityGoals />} />
            <Route path='/help-center' element={<HelpingCenter />} />
          </Route>

          <Route
            element={
              <MainLayout bg='#F8F8F8' PrivateRouteWrapper={UserPrivateRoute} />
            }
          >
            <Route
              path='/emp/:id/profile'
              element={
                <ProtectedPage condition={me?.privileges?.viewEmpKPI}>
                  <EmpProfile />
                </ProtectedPage>
              }
            />
            <Route
              path='/my-profile'
              element={
                <ProtectedPage condition={isSystemUser(me)}>
                  <EmpProfile employeeId={me?.userId} />
                </ProtectedPage>
              }
            />

            <Route
              path='/project-manager'
              element={
                <ProtectedPage condition={isSystemUser(me)}>
                  <ProjectManager employeeId={me?.userId} />
                </ProtectedPage>
              }
            />


            <Route
              path='/hr-dashboard'
              element={
                <ProtectedPage condition={isHr(me)}>
                  <HRDashboard employeeId={me?.userId} />
                </ProtectedPage>
              }
            />
            <Route
              path='/covenant-employee'
              element={
                <ProtectedPage condition={isSystemUser(me)}>
                  <CovenantEmployee employeeId={me?.userId} />
                </ProtectedPage>
              }
            />
            <Route
              path='/split-custody'
              element={
                <ProtectedPage condition={isSystemUser(me)}>
                  <SplitCustody employeeId={me?.userId} />
                </ProtectedPage>
              }
            />



            <Route
              path='/storehouses'
              element={
                <ProtectedPage condition={isSystemUser(me)}>
                  <StoreHouse />
                </ProtectedPage>
              }
            />
            <Route
              path='/suppliers'
              element={
                <ProtectedPage condition={isSystemUser(me)}>
                  <Suppliers />
                </ProtectedPage>
              }
            />
            <Route
              path='/resourceServices'
              element={
                <ProtectedPage condition={isSystemUser(me)}>
                  <ResourceServices />
                </ProtectedPage>
              }
            />
            <Route
              path='/pricingResources'
              element={
                <ProtectedPage condition={isSystemUser(me)}>
                  <PricingResources />
                </ProtectedPage>
              }
            />
            <Route
              path='/exitResource'
              element={
                <ProtectedPage condition={isSystemUser(me)}>
                  <ExitResources />
                </ProtectedPage>
              }
            />
            <Route
              path='/supplingResource'
              element={
                <ProtectedPage condition={isSystemUser(me)}>
                  <SupplingResources />
                </ProtectedPage>
              }
            />
            <Route
              path='/inventoryResource'
              element={
                <ProtectedPage condition={isSystemUser(me)}>
                  <InventoryResources />
                </ProtectedPage>
              }
            />
            <Route
              path='/recoveryResource'
              element={
                <ProtectedPage condition={isSystemUser(me)}>
                  <RecoveryResources />
                </ProtectedPage>
              }
            />

            <Route
              path='/hr-employee'
              element={
                <ProtectedPage condition={isSystemUser(me)}>
                  <HREmployee employeeId={me?.userId} />
                </ProtectedPage>
              }
            />

            <Route
              path='/Appropriations'
              element={
                <ProtectedPage condition={isWarehouseManager(me)}>
                  <Appropriations employeeId={me?.userId} />
                </ProtectedPage>
              }
            />

            <Route
              path='/WarehouseDashboard'
              element={
                <ProtectedPage condition={isWarehouseManager(me)}>
                  <WarehouseDashboard employeeId={me?.userId} />
                </ProtectedPage>
              }
            />

            <Route
              path='/client/:id/projects'
              element={
                <ProtectedPage
                  condition={isClient(me) || me?.privileges?.viewClientProjects}
                >
                  <ClientHome />
                </ProtectedPage>
              }
            />
            <Route
              path='/settings'
              element={
                <ProtectedPage condition={isSystemUser(me)}>
                  <Settings />
                </ProtectedPage>
              }
            />
            <Route
              path='/my-tasks'
              element={
                <ProtectedPage condition={isSystemUser(me)}>
                  <EmployeeTasks />
                </ProtectedPage>
              }
            />

            <Route
              path='/completed-tasks'
              element={
                <ProtectedPage condition={isSystemUser(me)}>
                  <CompletedTasks />
                </ProtectedPage>
              }
            />





            <Route
              path='/tasks/:id'
              element={
                <ProtectedPage condition={me?.privileges?.viewTask}>
                  <Task />
                </ProtectedPage>
              }
            />











            <Route
              path='/clients'
              element={
                <ProtectedPage condition={me?.privileges?.viewClients}>
                  <Clients />
                </ProtectedPage>
              }
            />
            <Route
              path='/departments'
              element={
                <ProtectedPage condition={isStaff(me)}>
                  <Departments />
                </ProtectedPage>
              }
            />
            <Route
              path='/projects'
              element={
                <ProtectedPage condition={me?.privileges?.viewProjects}>
                  <Projects />
                </ProtectedPage>
              }
            />
            <Route
              path='/projects/:id'
              element={
                <ProtectedPage
                  condition={me?.privileges?.viewProject || isClient(me)}
                >
                  <Project />
                </ProtectedPage>
              }
            />
            <Route
              path='/activity-history'
              element={
                <ProtectedPage condition={me?.privileges?.viewActivityHistroy}>
                  <ActivityHistory />
                </ProtectedPage>
              }
            />
            <Route
              path='/agenda'
              element={
                <ProtectedPage condition={isSystemUser(me)}>
                  <Agenda />
                </ProtectedPage>
              }
            />
            <Route
              path='/reports'
              element={
                <ProtectedPage condition={me?.privileges?.viewReports}>
                  <Reports />
                </ProtectedPage>
              }
            />
             <Route
              path='/storeHouseReports'
              element={
                <ProtectedPage condition={me?.privileges?.viewReports}>
                  <StoreHouseReports />
                </ProtectedPage>
              }
            />

            <Route
              path='/resources'
              element={
                <ProtectedPage condition={me?.privileges?.resources}>
                  <Resources />
                </ProtectedPage>
              }
            />
            <Route
              path='/receiving-logs'
              element={
                <ProtectedPage condition={me?.privileges?.viewReceivingLogs}>
                  <ReceivingLogs />
                </ProtectedPage>
              }
            />
            <Route
              path='/custody-logs'
              element={
                <ProtectedPage condition={me?.privileges?.custodays}>
                  <CustodyLogs />
                </ProtectedPage>
              }
            />
            <Route
              path='/exchange-logs'
              element={
                <ProtectedPage condition={me?.privileges?.viewExchangeLogs}>
                  <ExchangeLogs />
                </ProtectedPage>
              }
            />
          </Route>

          {/* client */}
          <Route
            path='/client/signin'
            element={
              <CustomSuspense>
                <ClientSignin />
              </CustomSuspense>
            }
          />

          {/* company */}
          <Route
            path='company/signin'
            element={
              <CustomSuspense>
                <CompanySignin />
              </CustomSuspense>
            }
          />
        </Routes>
        <ModalsContainer />
      </Router>
    </ErrorBoundary>
  );
}

export default App;
