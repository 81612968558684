import {
  IGetTasksReqportProps,
  IProjectsReqportsRequestOptions,
  Id,
} from '../../../types';
import { BASE_URL } from '../../../utils/constants';
import api from '../http-client';
import { getAuthToken } from '../../../utils/storage';
export const getTasksReport = async (props: IGetTasksReqportProps) => {
  const { data } = await api.post<{
    item1: {
      count: number;
    };
    item2: {
      taskId: number;
      taskName: string;
      taskDescription: string;
      taskStartTime: string;
      taskFinishTime: string;
      finishTime:string;
      taskStatus: boolean;
      userId: number;
      userName: string;
      departmentId: number;
      departmentName: string;
      
    }[];
  }>(BASE_URL+`/api/Projects/Task/Reports`, props, {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return {
    count: data.item1?.count,
    tasks: data.item2,
  };
};

export const getProjectsReport = async (
  props: IProjectsReqportsRequestOptions
) => {
  const { data } = await api.post<{
    item1: {
      count: number;
    };
    item2: {
      projectId: number;
      projectName: string;
      projectDescription: string;
      projectImage: string;
      projectType: string;
      tastsCount: number;
      taskCompletedCount: number;
      projectStartTime: string;
      projectFinishTime: string;
      projectFiles: {
        fileId: number;
        projectFlie: string;
      }[];
      projectFilesCount: number;
      projectHead: {
        userId: number;
        userName: string;
        userImage: string;
      }[];
      projectMembersCount: number;
      projectDepartmentCount: number;
      clientId: number;
      clientName: string;
      clientPhone: string;
    }[];
  }>(BASE_URL+`/api/Projects/Project/Reports`, props, {
    headers: {
    Authorization: getAuthToken(),
  }
});

  return {
    count: data.item1?.count,
    projects: data.item2,
  };
};

export const getDelayTasksReport = async (branchId: Id) => {
  const res = await api.get<
    {
      taskId: number;
      taskName: string;
      taskDescription: string | null;
      taskStartTime: string;
      taskFinishTime: string;
      finishTime:string;
      taskStatus: boolean;
      userId: number;
      userName: string;
      departmentId: number;
      departmentName: string;
     
    }[]
  >(BASE_URL+`/api/Projects/Select/Delay/Tasks/${branchId}`, {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return res.data;
};

export const getEmployeesReport = async (branchId: Id) => {
  const res = await api.get<
    {
      userId: number;
      userName: string;
      userAddress: string;
      userPhone: string;
      userNationalNumber: string;
      userEmploymentDate: string;
      userEmail: string;
      userImage: string | null;
      jobDescription: string;
      taskCount: number;
      copletedTaskCount: number;
    }[]
  >(BASE_URL+`/api/Projects/Select/Users/Reports/${branchId}`, {
    headers: {
    Authorization: getAuthToken(),
  }
});
  return res.data;
};
