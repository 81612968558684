import { SimpleGrid } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { home1Icon, home2Icon, home3Icon } from '../assests/icons';
import { getDashboardStatistics } from '../data/client/endpoints/shared.endpoints';
import { useTranslate } from '../hooks/langs-hooks';
import ServerError from './Error';
import StatisticCard from './cards/StatisticCard';
import Loader from './loader/Loader';

interface StatisticsCardsProps {}

const StatisticsCards: React.FC<StatisticsCardsProps> = ({}) => {
  const t = useTranslate();
  const statistcsQuery = useQuery({
    queryKey: ['statistcs'],
    queryFn: getDashboardStatistics,
    refetchOnMount: 'always',
  });

  return (
    <SimpleGrid columns={[1, 1, 2, 2, 3]} gap='3' mb={6}>
      {statistcsQuery.isLoading ? (
        <Loader size='xl' />
      ) : statistcsQuery.isError ? (
        <ServerError error={statistcsQuery.error} />
      ) : (
        <>
          <StatisticCard
            number={statistcsQuery.data!.completedProjectsCount}
            subtitle={t('text-completed-projects')}
            img={home1Icon}
            color='#A7F39B'
          />
          <StatisticCard
            number={statistcsQuery.data!.completedTaskCount}
            subtitle={t('text-completed-tasks')}
            img={home2Icon}
            color='#C0A0FF'
          />
          <StatisticCard
            number={statistcsQuery.data!.entityGoolCount}
            subtitle={t('text-entity-goals')}
            img={home3Icon}
            color='#B2C7FC'
          />
        </>
      )}
    </SimpleGrid>
  );
};

export default StatisticsCards;
