import { AddIcon, Search2Icon } from '@chakra-ui/icons'
import { Avatar, Button, Heading, Input, InputGroup, InputLeftElement, InputRightAddon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack, Table, TableCaption, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslate } from '../../hooks/langs-hooks'
import { AddElementsToTasks } from './AddElementsToTasks'
import { CustodayObj, TaskCustodayResponseObj } from '../../types'
import { GetCustodayById, GetUserTasks } from '../../data/client/endpoints/Covenant/Covenant.endPoint'
import { useAppContext } from '../../contexts/app-context'
import { NotFound } from '../hr-components/NotFound'
import useNotification from '../../hooks/use-notification-hook'
import { MatrixLoading } from '../loader/MatrixLoading'

export const SplitCustodyModal = () => {
    const t = useTranslate();
    const notify = useNotification();
    const { user: me } = useAppContext();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [custodayObj, setCustodayObj] = useState<CustodayObj>();
    const [searchValue, setSearchValue] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isTaskLoading, setIsTaskLoading] = useState<boolean>(false);
    const [isTaskNotFound, setIsTaskNotFound] = useState<boolean>(false);
    const [tasksArr, setTasksArr] = useState<TaskCustodayResponseObj[]>([]);


    var getSearchValue = async () => {
        if (searchValue === 0)
            return;
        try {
            setIsLoading(true);
            var data = await GetCustodayById(searchValue);
            if (data.status === 200) {
                setCustodayObj(data.data);
                setIsLoading(false);
                setSearchValue(0);
            } else {
                setIsLoading(false);
                notify.error(data.response.data);
            }
        } catch (error) {
            setIsLoading(false);
            notify.error("Server error");
        }
    }


    const handleSearchBtn = async () => {
        try {
            await getSearchValue();

        } catch (error) {
            setIsTaskLoading(false);
            setIsTaskNotFound(true);
        }
    }

    const handleGetTasks = async () => {
        console.log(custodayObj?.proNo)
        if (custodayObj?.proNo === undefined) {
            notify.error("please select custoday");
            return;
        } else {
            try {
                setIsTaskLoading(true);
                setIsTaskNotFound(false);
                var data = await GetUserTasks({ userId: me?.userId, prjId : custodayObj.proNo });
                if (data.status === 200) {
                    var newTasksArr = data.data.ongoningTask.filter((item: TaskCustodayResponseObj) => item.projectId === custodayObj?.proNo)
                    setTasksArr(newTasksArr);
                    setIsTaskLoading(false);
                    setIsTaskNotFound(false);
                } else {
                    setIsTaskLoading(false);
                    setIsTaskNotFound(true);
                }
            } catch (error) {
                setIsTaskLoading(false);
                setIsTaskNotFound(true);
            }
        }
    }
    // useEffect(() => {
    //     const tasks = async () => {
    //         try {
    //             setIsTaskLoading(true);
    //             setIsTaskNotFound(false);
    //             var data = await GetUserTasks({ userId: me?.userId });
    //             if (data.status === 200) {
    //                 setTasksArr(data.data.ongoningTask.filter((item: { projectId: number | undefined })=>item.projectId ===custodayObj?.proNo));
    //                 setIsTaskLoading(false);
    //                 if(tasksArr.length === 0)
    //                     setIsTaskNotFound(true);
    //                 console.log(tasksArr)
    //             } else {
    //                 setIsTaskLoading(false);
    //                 setIsTaskNotFound(true);
    //             }
    //         } catch (error) {
    //             setIsTaskLoading(false);
    //             setIsTaskNotFound(true);
    //         }
    //     }
    //     tasks();
    // }, [custodayObj])
    return (
        <>
            <Button onClick={onOpen} leftIcon={<AddIcon />} colorScheme='blue' variant='solid'>
                {t('text-split-custody')}
            </Button>
            <Modal size={'5xl'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader><Heading>{t('text-split-custody')}</Heading></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody p={7}>
                        <Stack width={'50%'} mb={5}>
                            <Stack>
                                <Heading as='h5' size='sm'>
                                    {t('text-custody-exchange-number')}
                                </Heading>
                                <InputGroup borderRadius={5} size="md">
                                    <InputLeftElement
                                        children={<Search2Icon color="gray.600" />}
                                        cursor={'pointer'}
                                        onClick={handleSearchBtn}
                                        backgroundColor={'#3182ce'}
                                    />
                                    <Input type="text"
                                        color="#949494"
                                        _placeholder={{ color: '#949494' }}
                                        placeholder={t('text-search-custoday-number')}
                                        border="2px solid #3182ce"
                                        pr={6}
                                        fontSize={20}
                                        onChange={(e) => setSearchValue(+e.target.value)} />
                                    <InputRightAddon
                                        p={0}
                                        border="none"
                                    >
                                    </InputRightAddon>
                                </InputGroup>
                            </Stack>
                        </Stack>

                        <Heading as='h5' size='sm' mb={2}>
                            {t('text-types-covenant')}
                        </Heading>
                        {
                            isLoading && <MatrixLoading />
                        }
                        {
                            custodayObj &&
                            <TableContainer mb={5}>
                                <Table variant='simple'>
                                    <Thead bgColor={'#f0f0f0'}>
                                        <Tr>
                                            <Th textAlign={'center'}>{t('text-item-no')}</Th>
                                            <Th textAlign={'center'}>{t('text-name')}</Th>
                                            <Th textAlign={'center'}>{t('text-quantity')}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            custodayObj.resources.length > 0 && custodayObj.resources.map((ele, index) => (
                                                <Tr key={index}>
                                                    <Td textAlign={'center'} bgColor={'#8f9fd8'}>{ele.id}</Td>
                                                    <Td textAlign={'center'}>{ele.name}</Td>
                                                    <Td textAlign={'center'}>{ele.quantity}</Td>
                                                </Tr>
                                            ))
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        }
                        {/* <Heading as='h5' size='sm' mb={2}>
                            {t('text-tasks')}
                        </Heading> */}
                        <Button colorScheme='blue' onClick={handleGetTasks} mb={4}>
                            {t('text-tasks')}
                        </Button>
                        {
                            isTaskLoading && <MatrixLoading />
                        }
                        {
                            isTaskNotFound && <NotFound />
                        }
                        {
                            tasksArr.length > 0 &&
                            <TableContainer mb={5}>
                                <Table variant='simple'>
                                    <Thead bgColor={'#f0f0f0'}>
                                        <Tr>
                                            <Th textAlign={'center'}>{t('text-task-number')}</Th>
                                            <Th textAlign={'center'}>{t('text-taskName')}</Th>
                                            <Th textAlign={'center'}>{t('text-prj-num')}</Th>
                                            <Th textAlign={'center'}>{t('text-add')}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            tasksArr.map((ele, index) => (
                                                <Tr key={index}>
                                                    <Td textAlign={'center'} bgColor={'#8f9fd8'}>{ele.taskId}</Td>
                                                    <Td textAlign={'center'}>{ele.taskName}</Td>
                                                    <Td textAlign={'center'}>{ele.projectId}</Td>
                                                    <Td textAlign={'center'}>
                                                        <AddElementsToTasks resources={custodayObj?.resources} taskId={ele.taskId} projectId={ele.projectId} custodayId={custodayObj?.id} />
                                                    </Td>
                                                </Tr>
                                            ))
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}