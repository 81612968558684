import { Box, Text, VStack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import Calender from 'react-calendar';
import ServerError from '../components/Error';
import EventCard from '../components/cards/EventCard';
import Loader from '../components/loader/Loader';
import { useMe } from '../contexts/app-context';
import { fetchEventsForUser } from '../data/client/endpoints/shared.endpoints';
import { useTranslate } from '../hooks/langs-hooks';

interface EventsCalendarProps {
  date: Date;
  setDate: (d: Date) => void;
}

const EventsCalendar: React.FC<EventsCalendarProps> = ({ date, setDate }) => {
  const t = useTranslate();
  const me = useMe();
  const userEventsQuery = useQuery({
    queryKey: ['user-events', date],
    queryFn: () =>
      fetchEventsForUser({ userId: me!.userId, eventDateTime: date }),
  });

  return (
    <Box
      bg='#fff'
      h='fit-content'
      w={{lg:'fit-content',xl:'fit-content'}}
      py={4}
      top={5}
      order={[0, 0, 0, 0, 1]}
      pos={[null, null, null, null, 'sticky']}
      flexGrow={1}
      maxH={'100vh'}
      overflowY='auto'
      zIndex={10000}
    >
      <Calender
        onChange={(date) => {
          setDate(date as Date);
        }}
      />
      <Text px={1} my={3} fontWeight={600} fontSize='lg'>
        {t('text-upcoming-events')}
      </Text>
      <VStack px={1} spacing={2}>
        {userEventsQuery.isLoading ? (
          <Loader size='xl' />
        ) : userEventsQuery.isError ? (
          <ServerError error={userEventsQuery.error} />
        ) : (
          userEventsQuery.data?.map((event) => (
            <EventCard key={event.eventId} event={event} />
          ))
        )}
      </VStack>
    </Box>
  );
};

export default EventsCalendar;
