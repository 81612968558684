import axios from 'axios';
import {
   
  IFilterState,

  IStoreHouseVM,

  IPaginatedResult,
  IGetTasksReqportProps,
  IStoreHouseReportFilter,

} from '../../../types';
import { formatePaginatedResult } from '../../../utils';
import { BASE_URL } from '../../../utils/constants';
import { getAuthToken } from '../../../utils/storage';
import api from '../http-client';
import { saveAs } from 'file-saver';
export const getAllStoreHouse = async ({ page, count,search,branchId }: IFilterState) => {
 
if(search=="")
  search =undefined;

  const { data } = await api.get<IPaginatedResult<IStoreHouseVM>>(
    BASE_URL+  `/api/StoreHouse/StoreHouseList/${page}/${count}/${search}/${branchId}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });

  return formatePaginatedResult(data);
};

export const addStoreHouse = async (data: IStoreHouseVM) => {

  const res = await api.post<string>(
    
    BASE_URL+ `/api/StoreHouse/AddStoreHouse`,
    data
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return res.data;
};

export const GetStoreHousePdf  =  async (props: IStoreHouseReportFilter) => {
  try {
      const response = await axios.post(BASE_URL + `/api/StoreHouseReports/ExportStoreHousePdf`,props, { headers: { Authorization: getAuthToken() } ,responseType: 'blob'});
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      saveAs(pdfBlob, `reportstorehouse.pdf`);
    
      return response;
  } catch (error: any) {
      return error;
      throw new Error('Error fetching user data: ' + error.message);
  }
}



export const editStoreHouse = async (type: IStoreHouseVM) => {
  const data = Object.keys(type).map((key) => {
    return {
      path: `/${key}`,
      operation: 'replace',
      value: type[key as 'storeHouseId'],
    };
  });
  const res = await api.patch<string>(
    BASE_URL+`/api/StoreHouse/UpdateStoreHouse/${type.storeHouseId}`,
    data
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return 'upt-success';
};

export const deleteStoreHouse = async (id: number | string) => {
  const res = await api.delete<string>(
    BASE_URL+  `/api/StoreHouse/DeleteStoreHouse/${id}`
    , {
      headers: {
      Authorization: getAuthToken(),
    }
  });
  return 'del-success';
};