import React from 'react'
import MainContainer from '../../components/MainContainer'
import { Stack, Text } from '@chakra-ui/react'
import { useTranslate } from '../../hooks/langs-hooks'
import { CovenantRequest } from '../../components/covenant-components/CovenantRequest'
import { CovenantTable } from '../../components/covenant-components/CovenantTable'
import { AppropriationsTable } from '../../components/covenant-Appropriations/AppropriationsTable'

interface HRProps {
    employeeId?: number;
  }


export const Appropriations:React.FC<HRProps> = () => {
    const t=useTranslate()
  return (
    <>
        <MainContainer>
            <Stack display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexDirection={'row'} bgColor={'#f3f5fa'} p={5}>
                <Text fontWeight={900} fontSize='2.25rem' mb={1} p={6}>
                    {t('text-accreditation-requests')}
                </Text>
            </Stack>
            <AppropriationsTable/>
        </MainContainer >
    </>
  )
}
