import { Box, Image, Text } from '@chakra-ui/react';
import { fadeInBottom } from '../../lib/framer-motion/fade-in-bottom';
import { motion } from 'framer-motion';

interface StatisticCardProps {
  number: number;
  subtitle: string;
  img: string;
  color: string;
}

const StatisticCard: React.FC<StatisticCardProps> = ({
  number,
  subtitle,
  img,
  color,
}) => {
  return (
    <Box
      bg='#fff'
      boxShadow='0px 12px 16px -4px rgba(0, 0, 0, 0.08), 0px 4px 6px -2px rgba(0, 0, 0, 0.03)'
      p={3}
      borderRadius='4px'
      as={motion.div}
      {...fadeInBottom()}
    >
      <Box bg={color} p={1} mb={1} borderRadius='6px' width='fit-content'>
        <Image src={img} boxSize='2rem' />
      </Box>
      <Text fontSize='2rem' fontWeight={700}>
        {number}+
      </Text>
      <Text color='#7C7B7B' fontSize='sm' fontWeight={500} textTransform={'capitalize'}>
        {subtitle}
      </Text>
    </Box>
  );
};

export default StatisticCard;
