import { createContext, useContext, useEffect, useState } from 'react';
import { isRTLLang } from '../hooks/langs-hooks';
import { IBranch, ICompanyData, ILang, IUserData } from '../types';
import {
  clearStorage,
  clearlocalStorage,
  getBranchIdFromStorage,
  getCompanyFromStoreage,
  getLangFromLocalStorage,
  getUserFromSessionStorage,
  setBranchIdToStorage,
  setLangToLocaleStorage,
  setUserToSessionStorage,
  storeCompanyInStoreage,
} from '../utils/storage';

interface AppContextProps {
  lang: ILang;
  changeLang: (lang: ILang) => void;

  user: IUserData | null;
  setUser: React.Dispatch<React.SetStateAction<IUserData | null>>;
  logout: () => void;

  branch: IBranch | null;
  setBranch: React.Dispatch<React.SetStateAction<IBranch | null>>;

  company: ICompanyData | null;
  setCompany: React.Dispatch<React.SetStateAction<ICompanyData | null>>;
}

export const appContext = createContext<AppContextProps>({} as AppContextProps);

interface AppContextProviderProps {
  children: React.ReactNode;
}

const AppContextProvider: React.FC<AppContextProviderProps> = ({
  children,
}) => {
  const langInLocalStorage = getLangFromLocalStorage();
  if (isRTLLang(langInLocalStorage.name)) {
    document.dir = 'rtl';
  }
  const [lang, setLang] = useState(langInLocalStorage);
  const [user, setUser] = useState<IUserData | null>(
    getUserFromSessionStorage()
  );
  const [branch, setBranch] = useState<IBranch | null>(null);

  const [company, setCompany] = useState<ICompanyData | null>(
    getCompanyFromStoreage()
  );

  useEffect(() => {
    if (user) {
      setUserToSessionStorage(user);
      const branchId = getBranchIdFromStorage() ?? user.branches?.[0]?.branchId;
      
      const branch = user.branches.find((b) => b.branchId === branchId) ?? null;
      setBranch(branch);
    }
  }, [user]);

  useEffect(() => {
    if (branch) {
      setBranchIdToStorage(branch.branchId);
    }
  }, [branch]);

  useEffect(() => {
    if (company) {
      storeCompanyInStoreage(company);
    }
  }, [company]);

  const logout = () => {
    setUser(null);
    setCompany(null);
   // localStorage.clear();
    clearlocalStorage();
    clearStorage();
   
  };

  const changeLang = (lang: ILang) => {
    if (isRTLLang(lang.name)) {
      document.dir = 'rtl';
    } else {
      document.dir = 'ltr';
    }
    setLang(lang);
    setLangToLocaleStorage(lang);
  };

  return (
    <appContext.Provider
      value={{
        lang,
        changeLang,
        user,
        setUser,
        logout,
        branch,
        setBranch,
        company,
        setCompany,
      }}
    >
      {children}
    </appContext.Provider>
  );
};

export const useAppContext = () => useContext(appContext);

export const useMe = () => {
  const { user: me } = useAppContext();
  return me;
};

export default AppContextProvider;
